
import {
  defineComponent,
  reactive,
  onMounted,
  ref,
  computed,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";

import { config } from "@/Config";

import { common } from "@/common/services/Common";
import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";

// import CommunicationCard from "@/components/cards/CommunicationCard.vue";
import MemberCard from "@/components/cards/MemberCard.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";


import Multiselect from 'vue-multiselect';
import DigitalSignature from "@/components/formComponents/DigitalSignature.vue";
import TitleComponent from "@/components/formComponents/TitleComponent.vue";
import TextBoxComponent from "@/components/formComponents/TextBoxComponent.vue";
// import TextAreaComponent from "@/components/formComponents/TextAreaComponent.vue";
import RadioButtonComponent from "@/components/formComponents/RadioButtonComponent.vue";
import SelectOption from "@/components/formComponents/SelectOption.vue";
import CheckBox from "@/components/formComponents/CheckBox.vue";
import TextArea from "@/components/formComponents/TextArea.vue";
import SignaturePad from "signature_pad";
// import WebViewer from '@/components/commonDesign/WebViewer.vue'
import VPagination from "vue3-pagination";
import Pusher from "pusher-js";
// import VueChatScroll from 'vue-chat-scroll'
import "vue3-pagination/dist/vue3-pagination.css";
// import Multiselect from "@vueform/multiselect";
import MyDriveFilesUpload from "@/components/MyDriveFilesUpload.vue";

// import VueCoreVideoPlayer from 'vue-core-video-player';
// import VideoBackground from 'vue-responsive-video-background-player'
import introJs from "intro.js";
import { useStore } from "vuex";
// import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import Mention from '@ckeditor/ckeditor5-mention/src/mention';
// import Editor from '@/components/commonDesign/Editor.vue'
import Editor from "@/components/mention/Editor.vue";
import state from "pusher-js/types/src/core/http/state";
// import InfiniteLoading from "v3-infinite-loading";
// import "v3-infinite-loading/lib/style.css";
import VueEasyLightbox, { useEasyLightbox } from 'vue-easy-lightbox';

// import AppTour from "@/components/modal/AppTour.vue";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import DocusignDocumentListModel from "@/components/commonDesign/AppDocusignModel.vue";
import showShareDocusignDocumentModel from "@/components/modal/ShowDocusignShareDocumentModel.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import UpgradeSubscriptionModal from '@/common/UpgradeSubscriptionModal.vue'
import ChannelChatWelcomeMessage from "./ChannelChatWelcomeMessage.vue";


export default defineComponent({
  name: "ChannelChatApp",
  components: {
    UpgradeSubscriptionModal,
    // VueCoreVideoPlayer,
    // CommunicationCard,
    // 'video-background': VideoBackground,
    SuccessfulModalCustom,
    MemberCard,
    ErrorList,
    TitleComponent,
    TextBoxComponent,
    Multiselect,
    RadioButtonComponent,
    SelectOption,
    CheckBox,
    TextArea,
    DigitalSignature,
    VPagination,
    MyDriveFilesUpload,
    Editor,
    DocusignDocumentListModel,
    VueEasyLightbox,
    showShareDocusignDocumentModel,
    ChannelChatWelcomeMessage
    // Carousel,
    // Slide,
    // Pagination,
    // Navigation,
    // VueperSlides,
    // VueperSlide
    // InfiniteLoading,
    // v-chat-scroll : VueChatScroll,
    // WebViewer,
    // Multiselect,
    // Editor,
  },

  setup() {
    const showFullText = ref(false);
    const selectedUsers = ref({}) as any;
    const allUsers = ref([]) as any;
    const {
      // methods
      show, onHide, changeIndex,
      // refs
      visibleRef = ref(false), indexRef = ref(0), imgsRef = ref([])
    } = useEasyLightbox({
      // src / src[]
      imgs: [],
      // initial index
      initIndex: 0
    });

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const moment = require("moment");
    const store = useStore();
    const {
      params: { channelId },
    } = useRoute();
    const paramsChannelId = useRoute();
    // console.log("Channel iDs >>> ", paramsChannelId);
    const router = useRouter();
    const routerQuery = useRoute();
    // console.log("Router Check >>>", router)
    const tempImageList = ref([]);
    const enableEnterKey = ref(false);
    const isRecording = ref(false);
    const isReplyRecording = ref(false);
    const transcription = ref('');
    let recognition = '' as any;
    const state = reactive({
      isFirefox: false,
      inputError: false,
      duplicate: false,
      isMute: false,
      allSliderImage: [] as any,
      sendChannelId: [] as any,
      canvas: {} as any,
      signaturePad: {} as any,
      activeUserIndex: 0,
      selectedUser: {},
      active: false,
      currentSendUser: false,
      userFormData: [] as any,
      messages: [] as any,
      messageStatus: false,
      scrollerCheck: "" as any,
      firstScroll: false as boolean,
      messageCreated: false as boolean,
      fileLoadTime: 30,
      isReplyMessage: false,
      isPublicUser: false,
      isFile: false,
      isZoomed: false,
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      slides: [] as any,
      replyImage: '' as any,
      isNext: false,
      isPreV: false,
      currentIndex: 0,
      inviteAsPublic: [] as any,
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: 'start',
        },
      },
      data: {
        subscriptionDetails: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          appointment_numbers: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          archive_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_file_size: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_message_history: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          estimation_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          export_channel_pdf: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          files_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          folder_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          guests_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          import_option: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          invoice_payment: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          member_in_smart_contract: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          member_per_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          members_per_appointment: 0,
          name: "Silver",
          // eslint-disable-next-line @typescript-eslint/camelcase
          pdf_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          phase_per_smart_contract: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          private_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sync_option: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          task_per_phase: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          template_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          total_channel_member: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          total_estimation: 0,
        } as any,
        channelUuid: channelId,
        successFulMessage: "",
        docuSignSignUrl: "",
        docuSignSignOpenUrl: "",
        inviteAsPublicUser: false,
        showSuccesCustomModal: false,
        showDocusignDocumentListModel: false,
        showShareDocusignDocumentModel: false,
        forwardMessage: {} as any,
        docusignShareDocumentDetail: {} as any,
        docusignShareDocumentData: {} as any,
        forwardWriteMessage: "" as any,
        clearBtn: false,
        userType: localStorage.getItem("current_user_role"),
        uploadMaxSize: (25 * 1024 * 1024) as any,
        search: "" as any,
        showReplyCommunication: false,
        approvalDiscussionInvitationId: "" as any,
        pageNumber: "" as any,
        isCommunicationFile: "" as any,
        isCommunicationReplyFile: "" as any,
        scrollTop: false,
        scrollBottom: false,
        currentPage: 1,
        page: 0,
        scrolledPages: [] as any,
        exportPdfs: [] as any,
        isExport: false,
        lastPage: 0,
        selectedChatIndex: 0,
        typeEditDisabled: false,
        typeDisabled: false,
        active: false,
        // editor: ClassicEditor,
        editorData: "<p>Content of the editor.</p>",
        editorConfig: {
          link: {
            addTargetToExternalLinks: true,
          },
          // The configuration of the editor.
          toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "blockQuote",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "link",
            "|",
            "undo",
            "redo",
          ],
        },
        disabledFileTagList: [] as any,
        showAddFileTag: false,
        tags: {
          mode: "tags",
          value: [] as any,
          options: [] as any,
          searchable: true,
          createTag: true,
        },
        projectMember: {} as any,
        inviteAsGuest: false,
        sharedFiles: {
          files: [] as any,
          attachments: [] as any,
        },
        selectedForRename: {} as any,
        selectedPendingMember: {},
        userTypingStatus: false,
        userTyping: "",
        userUUID: "",
        userFullName: "",
        user: false,
        typing: false,
        invitationVerifyMsg: "",
        invitationVerify: {
          showStatus: false,
          newMemberList: [] as any,
          registeredMemberList: [] as any,
          pendingMemberList: [] as any,
        },
        title: "edoc",
        heading: "No digitized doc created.",
        selectedReplyUser: {} as any,
        msgByUser: {} as any,
        selectedFormData: {} as any,
        formData: {} as any,
        clientDocumentRetrieveWorkflowLink: {} as any,
        clientDocumentRetrieve: {} as any,
        convertedDate: "",
        replyMessage: "",
        additionalMessage: "",
        imageBaseUrl: "",
        replyClassStatus: false,
        startValidation: false,
        clientDocuments: [] as any,
        userList: [] as any,
        privacyList: [
          {
            name: "Private to this channel",
            code: "channel",
            value: "private to this channel",
          },
          {
            name: "Share within this project",
            code: "project",
            value: "share within this project",
          },
          { name: "Share to Public", code: "public", value: "share to public" },
        ],
        privacy: {
          name: "Private to this channel",
          value: "private to this channel",
          code: "channel",
        },
      },
      selectedResponseDelete: {} as any,
      selectedResponseAttachment: {} as any,
      selectedForSharedDelete: {} as any,
      selectedForDelete: {} as any,
      selectedForEdit: {} as any,
      selectedAttachment: {} as any,
      selectedPdf: "",
      email: "" as any,
      filter: {
        type: "all",
        projectId: 0,
        channelId: 0,
        sortBy: -1,
        title: 1,
        selected: { name: "Newest First", id: -1 } as any,
      },
      type: "",
      selected: "",
      typePosition: "bottom",
      errorList: [] as any,
      sharedFormList: {} as any,
      formList: {} as any,
      isErrorStatus: false,
      projectId: 0,
      pendingMembersCount: 0,
      approvedMembersCount: 0,
      isCreateProjectModalShow: false,
      isEditCreatenewFormFromChannel: false,
      postLoading: false,
      communicationList: [] as any,
      totalSearchResult: false,
      totalSearchResultCount: 0,
      channel: {} as any,
      userRole: {} as any,
      selectedElement: {
        element: {} as any,
        type: "",
        index: "",
        title: "",
        description: "",
      },
      memberList: [],
      pendingList: [],
      imageList: [] as any[],
      threadImageList: [] as any,
      searchBy: "",
      message: "",
      image: "",
      title: "communications",
      heading: "Get started by creating communication in your chat room.",
      titleMember: "members",
      headingMember: "Get started by adding members in your chat room.",
      existingMember: {} as any,
      inviteMember: "",
      invitationMessage: "",
      userList: [] as any,
      formData: {} as any,
      edocPdfPath: "",
      owner: {} as any,
      ownerId: "" as any,
      item: {
        selectedUserFormDetails: {} as any,
        searchUser: "",
        formId: "",
        paging: {
          getChannelFormList: {
            perPage: 10,
            totalDocs: 0,
            totalPages: 0,
            currentPage: 1,
          },
        },
        error: {
          hasError: false,
          videoError: false,
          erorList: [] as any,
          renameSelectedAttachment: [] as any,
          validationList: [] as any,
          erorListNewForm: [] as any,
          deleteCommunication: [] as any,
          forwardCommunication: [] as any,
          erorListSendForm: [] as any,
          erorListSaveCustomSelectedForm: [] as any,
        },
        hasError: false,
      },
      privacyList: [
        {
          name: "Private to this channel",
          code: "channel",
          value: "private to this channel",
        },
        {
          name: "Share within this project",
          code: "project",
          value: "share within this project",
        },
        { name: "Share to Public", code: "public", value: "share to public" },
      ],
      newFormData: {
        title: "",
        description: "",
        elements: [] as any,
        privacy: {
          name: "Private to this channel",
          value: "private to this channel",
          code: "channel",
        },
      },
      selectedFormForEdit: {
        id: "",
        title: "",
        description: "",
        elements: [] as any,
      } as any,
      invite: {
        users: [] as any,
        invites: [] as any[],
        filteredUsers: [] as any[],
        filteredInvites: [] as any[],
      },
      form: {
        textField: {
          title: "",
          description: "",
        },
        textArea: {
          title: "",
          text: "",
        },
        textbox: {
          title: "",
        },
        radioButton: {
          type: "radioButton",
          title: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          select_values: [] as any,
        },
        selectOption: {
          type: "single_select",
          title: "",
          selectedOption: {},
          // eslint-disable-next-line @typescript-eslint/camelcase
          select_values: [] as any,
        },
        checkBox: {
          type: "checkBox",
          title: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          select_values: [] as any,
        },
      },
      ui: {
        isActive: {
          upgradeSubscription: false,
        },
        errors: {
          hasError: false,
          errorList: {
            resendInvitation: [] as any,
          },
        },
        template: {
          msg: "",
          response: {} as any,
        },
        isLoading: {
          docuSignIframe: false,
          docuSignIframeOpen: false,
          activateScroll: false,
          communicationListAPICall: false,
          getSharedFiles: false,
          communicationList: false,
          clientDocumentRetrieveWorkflow: false,
          clientDocumentList: false,
          getAccountFormList: false,
          accountFormResponseByEmailAndId: false,
          getChannelFormList: false,
        },
        isPostLoading: {
          enableSelectedFileTag: false,
          selectedDocumentToDelete: false,
          renameSelectedAttachment: false,
          resendInvitation: false,
          deletePendingInvitation: false,
          deleteCommunication: false,
          forwardCommunication: false,
          channelCommunicationTyping: false,
          channelRecentlyVisited: false,
          verifyInvitation: false,
          closeChannel: false,
          downloadProjectFiles: false,
          getCommunicationList: false,
          sendDigitizedForm: false,
          getUserList: false,
          clientDocumentRetrieve: false,
          removeSelectedCommunicationImage: false,
          sendEditedCommunication: false,
          createFormTemplate: false,
          getAccountFormRetrieveById: false,
          replyMessage: false,
          createNewForm: false,
          selectedUserAccountFormResponse: false,
          generateEdocStatement: false,
        },
      },
      isLoading: {
        getUserListByChannel: false,
        getUserList: false,
        inviteAllUsers: false,
      },
    });
    const loadData = () => {
      //calling the api
      alert("AAPI CALL");
    };
    const toggleActive = () => {
      state.data.active = !state.data.active;
    };
    if (localStorage.getItem("current_user_info")) {
      const userData: any = localStorage.getItem("current_user_info");
      state.data.userUUID = JSON.parse(userData).uuid;
      state.data.userFullName = JSON.parse(userData).full_name;

      // console.log(JSON.parse(userData).uuid)
    }
    function resetActive() {
      state.communicationList.forEach((item: any) => {
        item.active = false;
      });
    }
    function enterSetting(data: boolean) {
      // console.log("enter settings");
      if (data === true) {
        enableEnterKey.value = true;
      } else {
        enableEnterKey.value = false;
      }
      console.log("enter settings", enableEnterKey.value);
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const pageQuery = computed(() => {
      if (routerQuery.query.page) {
        const pageNum: any = routerQuery.query.page;
        const currentNumber = parseInt(pageNum);
        // state.data.page = currentNumber;
        return currentNumber;
      } else return 0;
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const enableSendMsg = computed(() => {
      return enableEnterKey.value;
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const clearEditor = computed(() => {
      if (state.message.startsWith("<p>&nbsp;</p>")) {
        // console.log("<<Removed >>");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.message = "";
      }
      if (state.message.startsWith("<p></p>")) {
        // console.log("<<Removed >>");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.message = "";
      }
      if (state.message.startsWith("<p><br></p>")) {
        // console.log("<<Removed >>");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.message = "";
      }
      if (state.message) {
        return true;
      } else if (state.imageList && state.imageList.length) {
        return true;
      } else {
        return false;
      }
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const relySelectedMessage = computed(() => {
      if (state.data.replyMessage.startsWith("<p>&nbsp;</p>")) {
        // console.log("<<Removed >>");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.data.replyMessage = "";
      }
      if (state.data.replyMessage.startsWith("<p></p>")) {
        // console.log("<<Removed >>");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.data.replyMessage = "";
      }
      if (state.data.replyMessage.startsWith("<p><br></p>")) {
        // console.log("<<Removed >>");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.data.replyMessage = "";
      }

      return state.data.replyMessage;
    });

    function toggleTourGuide() {
      if (store.state.showTourGuide) {
        introJs()
          .setOptions({
            steps: [
              {
                title: "Welcome",
                intro:
                  "Welcome to Chat Channel! 👋 <iframe width='426' height='240' src='https://www.youtube.com/embed/G8vkPCn2bLs' title='Slice Text in Photoshop' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
              },
              {
                title: "Project Title",
                element: document.getElementById(
                  "projectTitle"
                ) as HTMLInputElement,
                intro: "This channel related to it's project.",
                position: "right",
              },
              {
                title: "Channel Name",
                element: document.getElementById(
                  "channelToProject"
                ) as HTMLInputElement,
                intro: "Channel Title and its created data.",
                position: "right",
              },
              {
                title: "Channel Members",
                element: document.getElementById(
                  "channelMemberList"
                ) as HTMLInputElement,
                intro: "Members in this Channel with status",
                position: "left",
              },
              {
                title: "More...",
                element: document.getElementById(
                  "menu-toggle"
                ) as HTMLInputElement,
                intro:
                  "Add Members to channel, see shared attachments, documents, forms etc, close channel.",
                position: "left",
              },
              {
                title: "Chat Communication",
                element: document.getElementById(
                  "chat-body-content"
                ) as HTMLInputElement,
                intro: "Chat Conversations, attachments, forms shared.",
                position: "left",
              },
              {
                title: "Mesasge",
                element: document.getElementById("sendMsg") as HTMLInputElement,
                intro: "Type Message here...",
                position: "bottom",
              },
              {
                title: "Digitized Templates",
                element: document.getElementById(
                  "showDigitizedTemplate"
                ) as HTMLInputElement,
                intro: "See the Digitized Documents",
                position: "bottom",
              },
              {
                title: "Send Templates",
                element: document.getElementById(
                  "showSendTemplate"
                ) as HTMLInputElement,
                intro: "See the templates and send.",
                position: "bottom",
              },
              {
                title: "Attachment",
                element: document.getElementById(
                  "sendAttachment"
                ) as HTMLInputElement,
                intro: "Upload Files...",
                position: "bottom",
              },
              {
                title: "Send",
                element: document.getElementById(
                  "sendChatMessage"
                ) as HTMLInputElement,
                intro: "Send Message and Attachments...",
                position: "bottom",
              },
            ],
          })
          .start();
      }
    }
    watch(
      () => state.data.inviteAsGuest,
      () => {
        if (!state.data.inviteAsGuest) return false;
        console.log("Guest Invitation Selected");
        state.existingMember = "";
        state.invite.users = [];
      }
    );

    watch(
      // () => watchTour,
      () => store.state.takAtour,
      (newvalue, oldvalue) => {
        if (newvalue === true) {
          // console.log("check >>>", newvalue)
          toggleTourGuide();
        }
      }
    );
    function masterTagListForValidation(data: boolean) {
      // alert("Validate");
      state.item.error.erorList = [];
      state.item.hasError = false;
      const payLoad = {
        type: data ? "enabled" : "disabled",
      };
      ethitransService
        .masterFileTagList(payLoad)
        .then((res: any) => {
          console.log("masterTagList Check:>>> ", res.data.data);
          // res.data.data.forEach((item: any) => {
          //   item.label = item.name;
          //   item.value = item.code;
          // })
          // state.data.tagListForValidation = res.data.data ? res.data.data : [];
          let disabledTagList: any = [];
          state.imageList.forEach((item: any) => {
            if (item.tag && item.tag.length) {
              item.tag.forEach((tag: any) => {
                // console.log("CHECK Tag >>",tag);
                res.data.data.forEach((data: any) => {
                  // console.log("CHECK THE API TAG >>>", data);

                  if (tag.toLowerCase() === data.name.toLowerCase()) {
                    data.inProgress = false;
                    // console.log("tag values >>> ",item )
                    // console.log("tagListForValidation  values >>> ", data.name)
                    disabledTagList.push(data);
                  }
                });
              });
            } else {
              disabledTagList = [];
            }

            //
          });
          console.log("LIST>>> ", disabledTagList);
          const uniqueSet = Array.from(new Set(disabledTagList));
          // const tag: any = []
          state.data.disabledFileTagList = uniqueSet ? uniqueSet : [];

          if (
            state.data.disabledFileTagList &&
            !state.data.disabledFileTagList.length
          ) {
            state.data.showAddFileTag = true;
          }
          // state.data.disabledFileTagList.forEach((item: any)=> {
          // state.data.showAddFileTag = true

          // })
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    function addTag(tag: any) {
      state.item.error.erorList = [];
      state.item.hasError = false;
      // console.log("Check the New Tag >>>", tag);
      const payLoad = {
        name: tag,
      };
      // state.postLoading = true;
      ethitransService
        .createMasterFileTag(payLoad)
        .then((res: any) => {
          console.log("createMasterTag:>>> ", res.data.data);
          if (res.data.data && res.data.data.is_active === 1) {
            state.data.tags.options.push(res.data.data);
          } else {
            state.data.tags.options.push({ name: tag });
            //  masterTagListForValidation(false)
          }
          //
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const masterTags = computed(() => {
      const array: any = [];
      state.data.tags.options.forEach((item: any) => {
        array.push(item.name);
      });

      return array;
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const channelStatus = computed(() => {
      let status = false;
      // console.log("check Project Ids", state.channel)
      if (state.channel && state.channel && state.channel.status) {
        if (
          state.channel.status.code === "active" &&
          common.daysEvalutor(state.data.projectMember.validity_date) === true
        ) {
          status = true;
        }
      }

      return status;
    });

    // eslint-disable-next-line vue/return-in-computed-property
    const readyToUploadImage = computed(() => {
      // if (localStorage.getItem("chat-msg")) {
      // }
      console.log("IMAGE LIST CHECK >>> ");
      return state.imageList;
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const communicationList = computed(() => {
      // if (localStorage.getItem("chat-msg")) {
      // }

      return state.messages;
    });
    function onEditorReady(editor: any) {
      editor.model.change((writer: any) => {
        writer.setSelection(editor.model.document.getRoot(), "end");
      });
      editor.editing.view.focus();
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const templateList = computed(() => {
      if (state.formList.docs && state.formList.docs.length) {
        return state.formList.docs.filter((form: any) => {
          // if (form.title) {
          //   return form.title.toLowerCase().match(state.searchBy.toLowerCase());
          return form;
          // }
        });
      } else {
        return [];
      }
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const selectedForm = computed(() => {
      if (
        state.selectedFormForEdit.elements &&
        state.selectedFormForEdit.elements.length
      ) {
        return state.selectedFormForEdit.elements.filter((form: any) => {
          // console.log("Check SelectForm >>>", form);
          return form;
        });
      }
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const formPrivacyMessage = computed(() => {
      if (state.newFormData.privacy.value === "private to this channel") {
        return "This form will remain private to this channel. Form will not be visible to other channels of this project.";
      } else if (
        state.newFormData.privacy.value === "share within this project"
      ) {
        return " This form will be shared in this project so that other channels of this project can utilize this form, but will not be shared with other projects.";
      } else {
        return "This form will be shared to public.";
      }
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const isUserStatus = computed(() => {
      if (state.userRole) {
        if (state.userRole.level === 1 || state.userRole.level === 2) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });

    //  watch(channelId, (channelId, prevChannelId)=> {
    //    console.log( "Channel ID check", channelId);
    //    console.log( "Channel previous ID check", prevChannelId);
    //   connect()
    // })

    // methods
    // communication card code here
    function hideDeleteSelectedImage() {
      state.selectedAttachment = {};
      state.selectedForDelete = {};
      $("#deleteSelectedAttachment").modal("hide");
    }
    function selectedDocumentToDelete(
      communication: any,
      sharedDocument: any,
      document: any
    ) {
      state.selectedAttachment = document;
      state.selectedForSharedDelete = sharedDocument;
      state.selectedForDelete = communication;
      $("#deleteSelectedAttachment").modal("show");
      // console.log("CHECK SHAREDDOC >>",state.selectedAttachment )
      // console.log("Communication Index >> ", state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)])
      // console.log("Shared Selected >>> ", state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history[common.findIndex(state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history, state.selectedForSharedDelete)])
      // console.log("Shared Index >>>", common.findIndex(state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history, sharedDocument))
      // state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history[common.findIndex(common.findIndex(state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history, sharedDocument))].documents
      // state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history[common.findIndex(state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history, state.selectedForSharedDelete)].documents

      // state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history[common.findIndex(state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history, state.selectedForSharedDelete)].documents = state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history[common.findIndex(state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history, state.selectedForSharedDelete)].documents.filter(
      //         (item: any, index: any) => item != state.selectedAttachment
      //       );
    }
    function deleteSharedDocument() {
      // console.log("Selected Communication >>>", communication)
      // console.log("Selected SharedDocument >>>", sharedDocument)
      // console.log("Selected Document for Delete >>>", document)
      // console.log("Selected Document for Delete ID>>>", document.file_id)

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        shared_file_id: state.selectedAttachment.id,
      };
      state.ui.isPostLoading.selectedDocumentToDelete = true;
      ethitransService
        .selectedSharedFileToDelete(payLoad)
        .then((res: any) => {
          console.log(
            "selectedSharedFileToDelete Response Check:>>> ",
            res.data.data
          );
          state.communicationList[
            common.findIndex(state.communicationList, state.selectedForDelete)
          ].share_history[
            common.findIndex(
              state.communicationList[
                common.findIndex(
                  state.communicationList,
                  state.selectedForDelete
                )
              ].share_history,
              state.selectedForSharedDelete
            )
          ].documents[
            common.findIndex(
              state.communicationList[
                common.findIndex(
                  state.communicationList,
                  state.selectedForDelete
                )
              ].share_history[
                common.findIndex(
                  state.communicationList[
                    common.findIndex(
                      state.communicationList,
                      state.selectedForDelete
                    )
                  ].share_history,
                  state.selectedForSharedDelete
                )
              ].documents,
              state.selectedAttachment
            )
          ] = res.data.data;
          // ].documents.filter(
          //   (item: any, index: any) => item != state.selectedAttachment
          // );
          // state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)]
          hideDeleteSelectedImage();
          state.selectedForSharedDelete = {};
          // state.data.formData = res.data ? res.data : {};
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.selectedDocumentToDelete = false;
        });
    }
    function dragOverHandler(ev: any) {
      // console.log('File(s) in drop zone', ev);
      // Prevent default behavior (Prevent file from being opened)
      // ev.preventDefault();
    }
    function backInvitation() {
      state.data.invitationVerify.showStatus =
        !state.data.invitationVerify.showStatus;
    }
    function openPdf(pdfLink: any) {
      // alert(pdfLink)
      window.open(pdfLink);
    }
    function showHideCalender(item: any) {
      item.show = !item.show;
    }
    function scrollToLastThread() {
      $("#thread-chat-body-content").animate(
        { scrollTop: $("#thread-chat-body-content")[0].scrollHeight },
        1000
      );
      // if(state.data.isCommunicationReplyFile){
      //  const replyId: any = $(".chat mw-100").attr("id");
      //  if (replyId != null) {
      //     const element: any = document.getElementById(replyId);
      //     element.scrollIntoView();
      //   }
      // }else{
      //  $("#thread-chat-body-content").animate(
      //   { scrollTop: $("#thread-chat-body-content")[0].scrollHeight },
      //   1000
      // );
      // }
    }

    async function uploadReplyAttachmentByChannel(selectedChannelId: number,
      responseId: any,
      allFile: any
    ) {
      const fileList = [] as any;
      allFile.forEach((item: any) => {
        console.log("CHECK IMAGE LIST", item.tag);
        const tagIds = [] as any;
        const fileData = {} as any;
        if (item.isvalidToUpload) {
          if (item.tag && item.tag.length) {
            state.data.tags.options.forEach((tagItem: any) => {
              item.tag.forEach((dataItem: any) => {
                if (tagItem.name.toLowerCase() === dataItem.toLowerCase()) {
                  tagIds.push(tagItem.id);
                }
              });
            });
          }
          // isUploadedFile = true;
          console.log("im form if")
          fileData["fileName"] = item.fileName;
          fileData["base64"] = item.base64;
          fileData["extension"] = item.extension;
          fileData["item"] = item.item;
          fileData["master_file_tag_id"] = tagIds;
          fileList.push(fileData);
        } else {
          console.log("im form else")
          state.postLoading = false;
          state.threadImageList = state.threadImageList.filter((e: any) => e != item);
        }
      });

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_id: selectedChannelId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        response_id: responseId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        files: fileList
      };
      // console.log("PAYLOAD CHECK >>", state.imageList[isuplodedIndex]);
      state.postLoading = true;

      // state.imageList[isuplodedIndex].isuploded = true;
      return await new Promise((resolve, reject) => {
        ethitransService
          .uploadReplyAttachment(payLoad)
          .then((res: any) => {
            console.log("<<<IMAGE upload API Check:>>> ", res.data.data);
            if (
              res.data.data.messages.data &&
              res.data.data.messages.data.length
            ) {
              // state.communicationList = [];
              res.data.data.messages.data.forEach((data: any) => {
                data.allowEdit = false;
                if (data.attachments && data.attachments.length) {
                  data.attachments.forEach((attachment: any) => {
                    attachment.convertedFileSize = common.formatBytes(
                      parseInt(
                        attachment.file_size
                        // parseInt(data.file_size.split(" ")[0])
                      )
                    );
                  });
                }
              });
            }
            if (
              res.data.data.messages.data &&
              res.data.data.messages.data.length
            ) {
              res.data.data.messages.data.forEach((item: any) => {
                if (item.user && item.user.email) {
                  if (
                    item.user.email ===
                    localStorage.getItem("current_user_email")
                  ) {
                    // console.log("email", item.user.email);
                    // console.log("active email", state.data.activeUserEmail);
                    item.editStatus = true;
                  } else {
                    item.editStatus = false;
                  }
                }
              });
            }
            res.data.data.messages.data.forEach((data: any) => {
              if (data.attachments) {
                data.attachments.forEach((video: any) => {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  if (video.file_type === "mov" || video.file_type === "MOV") {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    video.file_type = "mp4";
                  }
                });

                // console.log("Data Check >>> ", data);
              }
            });

            // state.communicationList = res.data.data.messages.data
            //   ? res.data.data.messages.data
            //   : [];
            // scrollToLast();
            // state.message = "";
            // state.postLoading = false;
            if (state.threadImageList && state.threadImageList.length) {
              // state.imageList[isuplodedIndex].isuploded = false;
            }
            // state.imageList = state.imageList.filter(
            //   // (e: any) => e.base64 != attachment.base64
            // );
            resolve(true);
          })
          .catch((error: any) => {
            console.log(error);
            reject(false);
          })
          .finally(() => {
            if (state.threadImageList && !state.threadImageList.length) {
              state.postLoading = false;
            }
            // state.postLoading = false;
            //  state.imageList[isuplodedIndex].isuploded = false;
            state.threadImageList = [];
          });
      });
    }

    function replyMessage() {
      // // sendReplyMsg
      const fileList = [] as any;
      state.threadImageList.forEach((item: any) => {
        const tagIds = [] as any;
        const fileData = {} as any;
        if (item.isvalidToUpload) {
          if (item.tag && item.tag.length) {
            state.data.tags.options.forEach((tagItem: any) => {
              item.tag.forEach((dataItem: any) => {
                if (tagItem.name.toLowerCase() === dataItem.toLowerCase()) {
                  tagIds.push(tagItem.id);
                }
              });
            });
          }
          fileData["fileName"] = item.fileName;
          fileData["base64"] = item.base64;
          fileData["extension"] = item.extension;
          fileData["item"] = item.item;
          fileData["master_file_tag_id"] = tagIds;
          fileList.push(fileData);
        } else {
          console.log("im form else")
          state.postLoading = false;
          state.threadImageList = state.threadImageList.filter((e: any) => e != item);
        }
      });
      if (!state.data.replyMessage && !state.threadImageList.length) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_id: state.data.selectedReplyUser.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        response_type: "text",
        message: state.data.replyMessage.replace(/\r?\n/g, " <br>"),
        // eslint-disable-next-line @typescript-eslint/camelcase
        files: fileList,

      };
      state.ui.isPostLoading.replyMessage = true;
      state.data.replyMessage = "";
      state.threadImageList = [];
      ethitransService
        .createResponseChannelCommunication(payLoad)
        .then((res: any) => {
          res.data.data.createdLatest = common.localDateAndTime(
            res.data.data.created_at
          );

          res.data.data.forEach((data: any) => {
            // eslint-disable-next-line @typescript-eslint/camelcase
            data.sentDate = common.localDateAndTime(data.sent_date);
          });

          state.data.selectedReplyUser.responses = res.data.data;
          state.data.replyMessage = "";
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.selectedReplyUser.responses_count =
            state.data.selectedReplyUser.responses.length;
          // state.data.formData = res.data ? res.data : {};
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.replyMessage = false;
          scrollToLastThread();
          state.isReplyMessage = true;
          state.threadImageList = [];
        });
      // }
    }
    function viewFormRetrieveByIdForSend(formId: any) {
      state.data.formData = {};
      if (state.ui.isPostLoading.getAccountFormRetrieveById) return false;
      // console.log("Get Account form Response:>>>", formId);
      state.ui.isPostLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          console.log("FORM VIEW");
          // console.log(
          //   "getAccountFormRetrieveById Response Check:>>> ",
          //   res.data.elements
          // );
          state.data.formData = res.data ? res.data : {};
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getAccountFormRetrieveById = false;
        });
    }

    function hideViewTemplateModal() {
      $("#showFormViewModal").modal("hide");
    }
    function viewSelectSharedDocuments(document: any) {
      console.log("check the doc >> ", document);
      if (document.file_url) {
        window.open(document.file_url, "_blank");
      }
      if (document.file_link) {
        window.open(document.file_link, "_blank");
      }
    }
    function viewSelectForm(form: any) {
      // if(!channelStatus) return false
      $("#showFormViewModal").modal("show");
      console.log("Selected Form:>>", form);
      viewFormRetrieveByIdForSend(form._id);
    }

    function removeSelectedCommunicationImageAttached() {
      // function removeSelectedCommunicationImage(imagetoDelete: any) {
      console.log("Selected Image >>>> ", state.selectedAttachment);
      const selectedRowIndex = common.findIndex(
        state.communicationList,
        state.selectedForDelete
      );
      const selectedIndex = common.findIndex(
        state.communicationList[selectedRowIndex].attachments,
        state.selectedAttachment
      );
      console.log("Selected Row INDEX >>>", selectedRowIndex);
      // console.log("Image >>>", state.communicationList[selectedRowIndex].attachments)
      console.log("Image INDEX >>>", selectedIndex);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_attachment_id: state.selectedAttachment.id,
        // communication_attachment_id: state.selectedAttachment.communication_id
      };
      state.ui.isPostLoading.removeSelectedCommunicationImage = true;
      ethitransService
        .deleteAttachment(payLoad)
        .then((res: any) => {
          console.log("deleteAttachment Check:>>> ", res.data.data);
          state.communicationList[selectedRowIndex].attachments[selectedIndex] =
            res.data.data;
          // state.communication.attachments = state.communication.attachments.filter(
          //   (item: any) => item != imagetoDelete
          // );
          // state.projectList = res.data.data.projects
          //   ? res.data.data.projects
          //   : [];
          // state.selectedFormForEdit = res.data ? res.data : {};
          // hideDeleteSelectedImage()
          hideDeleteSelectedImage();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.removeSelectedCommunicationImage = false;
        });
    }

    function deleteReplyImage(response: any, document: any) {
      state.selectedResponseAttachment = response;
      state.selectedResponseDelete = document;
      $("#deleteReplySelectedAttachment").modal("show");

    }

    function hideDeleteReplySelectedImage() {
      state.selectedResponseAttachment = {};
      state.selectedResponseDelete = {};
      $("#deleteReplySelectedAttachment").modal("hide");

    }
    function removeSelectedReplyCommunicationImageAttached() {

      const selectReponse = common.findIndex(state.data.selectedReplyUser.responses, state.selectedResponseAttachment);
      console.log(selectReponse, 'om')
      const selectedIndex = common.findIndex(state.data.selectedReplyUser.responses[selectReponse].attachments, state.selectedResponseDelete);
      console.log(selectedIndex, 'om2')

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        id: state.selectedResponseDelete.id,
        // communication_attachment_id: state.selectedAttachment.communication_id
      };
      state.ui.isPostLoading.removeSelectedCommunicationImage = true;
      ethitransService
        .deleteReplyAttachementCommunication(payLoad)
        .then((res: any) => {
          const deleteDate = new Date();
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.selectedReplyUser.responses[selectReponse].attachments[selectedIndex].deleted_at = common.localDateAndTime(deleteDate.toISOString());

        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.removeSelectedCommunicationImage = false;
          hideDeleteReplySelectedImage();
        });
    }

    function removeSelectedCommunicationImage() {
      if (state.selectedAttachment.file_name) {
        removeSelectedCommunicationImageAttached();
      } else {
        deleteSharedDocument();
      }
    }
    function viewPdf(pdfLink: any) {
      state.selectedPdf = pdfLink;
      window.open(pdfLink);
    }
    function downloadFile(pdfLink: any) {
      // state.selectedPdf = pdfLink;

      window.open(pdfLink, "_blank");
    }

    function selctedCommunication(selected: any) {
      state.data.typeEditDisabled = false;
      state.data.selectedChatIndex = 0;
      state.selectedForEdit = {};
      state.data.selectedChatIndex = common.findIndex(
        state.communicationList,
        selected
      );
      console.log(
        "Selected Communication  INDEX>>> ",
        state.data.selectedChatIndex
      );
      // eslint-disable-next-line @typescript-eslint/camelcase
      // selected.is_edited = !selected.is_edited;
      selected.allowEdit = true;
      state.selectedForEdit = JSON.parse(JSON.stringify(selected));
    }
    function selctedReplyCommunication(selected: any) {
      console.log("edit", selected);
      state.data.typeEditDisabled = false;
      state.data.selectedChatIndex = 0;
      state.selectedForEdit = {};
      state.data.selectedChatIndex = state.communicationList.forEach((message: any) => {
        message.responses.forEach((resp: any, respIndex: any) => {
          console.log("match", resp.id, selected.id)
          if (resp.id === selected.id) {
            return respIndex;
          }
        });
      })
      selected.allowEdit = true;
      console.log("edit", state.data.selectedChatIndex);
      state.selectedForEdit = JSON.parse(JSON.stringify(selected));
    }
    function hideDeleteCommunicationModal() {
      state.item.hasError = false;
      state.item.error.deleteCommunication = [];
      $("#removecommunication").modal("hide");
    }
    function selctedCommunicationForDelete(selected: any) {
      state.item.hasError = false;
      state.item.error.deleteCommunication = [];
      state.selectedForEdit = {};
      $("#removecommunication").modal("show");
      // console.log("Selected Communication  WOWOWO>>> ", selected);
      // eslint-disable-next-line @typescript-eslint/camelcase
      // selected.is_edited = !selected.is_edited;
      selected.allowDelete = true;
      state.selectedForEdit = JSON.parse(JSON.stringify(selected));
    }

    //remove reply message
    function hideDeleteReplyMessage() {
      state.item.hasError = false;
      state.item.error.deleteCommunication = [];
      $("#removeReplyConverstion").modal("hide");
    }
    function deleteReplyMessage(selected: any) {
      state.item.hasError = false;
      state.item.error.deleteCommunication = [];
      state.selectedForEdit = {};
      $("#removeReplyConverstion").modal("show");
      selected.allowDelete = true;
      state.selectedForEdit = JSON.parse(JSON.stringify(selected));
    }

    function communicationDetails(selcted: any, image: any) {
      state.item.hasError = false;
      ethitransService
        .communicationDetails(selcted.id)
        .then((res: any) => {
          if (!image) {
            state.data.forwardMessage = res.data.data.communcitation;
          } else {
            state.data.forwardMessage = res.data.data.communcitation;
            state.data.forwardMessage.message = "";
            state.data.forwardMessage.attachments = res.data.data.communcitation.attachments.filter((e: any) => e.id == image.id);

          }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.deleteCommunication)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.forwardCommunication.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.deleteCommunication = false;
        });
    }

    function hideForwardMessage() {
      state.item.hasError = false;
      state.item.error.forwardCommunication = [];
      state.data.forwardWriteMessage = "";
      $("#forwardCommunication").modal("hide");
    }

    function forwardMessage(selected: any, image: any) {
      communicationDetails(selected, image);
      state.item.hasError = false;
      state.item.error.forwardCommunication = [];
      state.selectedForEdit = {};
      $("#forwardCommunication").modal("show");
      selected.allowDelete = true;
      state.selectedForEdit = JSON.parse(JSON.stringify(selected));
    }

    function forWardCommunication() {
      state.item.hasError = false;
      state.item.error.forwardCommunication = [];
      const file = [] as any;
      if (state.data.forwardMessage.attachments.length) {
        state.data.forwardMessage.attachments.forEach((image: any) => {
          file.push(image.id)
        });
      }

      if (state.ui.isPostLoading.forwardCommunication) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_id: state.data.forwardMessage.id,
        message: state.data.forwardWriteMessage,
        files: file,
      };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.forwardCommunication = true;
      ethitransService
        .forwardCommunication(payLoad)
        .then((res: any) => {
          hideForwardMessage();
          state.selectedForEdit = {};
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.deleteCommunication)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.forwardCommunication.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.forwardCommunication = false;
        });
    }

    function deleteCommunication() {
      console.log("CHECK TEH DATA", state.selectedForEdit);
      state.item.hasError = false;
      state.item.error.deleteCommunication = [];
      if (state.ui.isPostLoading.deleteCommunication) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_id: state.selectedForEdit.id,
      };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.deleteCommunication = true;
      ethitransService
        .deleteCommunication(payLoad)
        .then((res: any) => {
          // console.log("deleteCommunication Check:>>> ", res.data.data);id
          let indexSelected = 0;
          state.communicationList.filter((item: any, index: number) => {
            if (item.id === state.selectedForEdit.id) {
              indexSelected = index;
            }
          });
          // console.log("deleteCommunication Index:>>> ", indexSelected);
          state.communicationList[indexSelected] = res.data.data;
          state.communicationList[indexSelected].deletedLocalTime =
            common.localDateAndTime(res.data.data.deleted_at);
          state.communicationList[indexSelected] = res.data.data;
          state.communicationList[indexSelected].allowDelete = false;
          hideDeleteCommunicationModal();
          // state.userList = res.data.data ? res.data.data : [];
          state.selectedForEdit = {};
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.deleteCommunication)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.deleteCommunication.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.deleteCommunication = false;
        });
    }

    function deleteReplyCommunication() {
      state.item.hasError = false;
      state.item.error.deleteCommunication = [];
      if (state.ui.isPostLoading.deleteCommunication) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        response_id: state.selectedForEdit.id,
      };
      state.ui.isPostLoading.deleteCommunication = true;
      ethitransService
        .deleteReplyCommunication(payLoad)
        .then((res: any) => {
          // console.log("deleteCommunication Check:>>> ", res.data.data);id
          // let indexSelected = 0;
          // state.communicationList.filter((item: any, index: number) => {
          //   item.responses.filter((item2: any, index: number) => {
          //   if (item2.id === state.selectedForEdit.id) {
          //     indexSelected = index;
          //   }
          // })
          // });
          // state.communicationList.responses[indexSelected] = res.data.data;
          // state.communicationList.responses[indexSelected].deletedLocalTime =
          //   common.localDateAndTime(res.data.data.deleted_at);
          // state.communicationList.responses[indexSelected] = res.data.data;
          // state.communicationList.responses[indexSelected].allowDelete = false;
          hideDeleteReplyMessage();
          // state.userList = res.data.data ? res.data.data : [];
          state.selectedForEdit = {};
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.deleteCommunication)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.deleteCommunication.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.deleteCommunication = false;
        });
    }

    function selectedDocumentToEdit(
      communication: any,
      sharedDocument: any,
      document: any
    ) {
      state.item.error.renameSelectedAttachment = [];
      state.item.hasError = false;

      state.selectedAttachment = document;
      // console.log("Document Selected>>> ",state.selectedAttachment)
      state.selectedForSharedDelete = sharedDocument;
      state.selectedForDelete = communication;
      state.data.selectedForRename = JSON.parse(JSON.stringify(document));
      const extension = document.filename.substring(
        document.filename.lastIndexOf(".") + 1
      );
      state.data.selectedForRename.extension = extension;
      state.data.selectedForRename.newName = document.filename.replaceAll(
        `.${extension}`,
        ""
      );
      $("#renameModal").modal("show");
    }
    function renameDoc(communication: any, image: any) {
      // alert("HELLO");
      state.item.error.renameSelectedAttachment = [];
      state.item.hasError = false;
      // console.log("rename doc Index>>> ", common.findIndex(state.communicationList, communication))
      // console.log("Image details>>> ", image.communication_id)

      // console.log("Image details INDEX>>> ", common.findIndex(state.communicationList[common.findIndex(state.communicationList, communication)].attachments, image))
      state.data.selectedForRename = JSON.parse(JSON.stringify(image));
      const extension = image.file_name.substring(
        image.file_name.lastIndexOf(".") + 1
      );
      state.data.selectedForRename.communicationIndex = common.findIndex(
        state.communicationList,
        communication
      );
      state.data.selectedForRename.attachmentIndex = common.findIndex(
        state.communicationList[
          common.findIndex(state.communicationList, communication)
        ].attachments,
        image
      );
      state.data.selectedForRename.extension = extension;
      state.data.selectedForRename.newName = image.file_name.replaceAll(
        `.${extension}`,
        ""
      );
      $("#renameModal").modal("show");
    }
    function showImageModel(images: any, image: any) {
      state.slides = [];
      const index = images.attachments.indexOf(image);
      if (index != -1) {
        images.attachments.splice(index, 1); // remove 1 element from index 
        images.attachments.unshift(image);
      }

      state.slides = images.attachments;
      state.slides.length > 1 ? state.isNext = true : state.isNext = false;
      $("#viewImageModal").modal("show");
    }
    function showImageModelHide() {
      $("#viewImageModal").modal("hide");
      state.currentIndex = 0;
    }

    function showReplyImageModel(image: any) {
      state.replyImage = '';
      state.replyImage = image;
      $("#viewReplyImageModal").modal("show");
    }
    function showReplyImageModelHide() {
      $("#viewReplyImageModal").modal("hide");
      state.currentIndex = 0;
    }


    function hideRenameAttachmentModal() {
      $("#renameModal").modal("hide");
    }
    function renameAttachment() {
      state.item.hasError = false;
      state.item.error.renameSelectedAttachment = [];
      if (state.ui.isPostLoading.renameSelectedAttachment) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_attachment_id: state.data.selectedForRename.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        file_name:
          state.data.selectedForRename.newName +
          "." +
          state.data.selectedForRename.extension,
      };
      state.ui.isPostLoading.renameSelectedAttachment = true;
      ethitransService
        .renameCommunicationAttachment(payLoad)
        .then((res: any) => {
          // console.log("renameSelectedAttachment Check:>>> ", res.data.data);
          console.log(
            "renameSelectedAttachment Check:>>> ",
            res.data.data.file_name
          );
          state.communicationList[
            state.data.selectedForRename.communicationIndex
          ].attachments[state.data.selectedForRename.attachmentIndex] =
            res.data.data;
          state.communicationList[
            state.data.selectedForRename.communicationIndex
          ].attachments[
            state.data.selectedForRename.attachmentIndex
          ].convertedFileSize = common.formatBytes(
            parseInt(
              res.data.data.file_size
              // parseInt(data.file_size.split(" ")[0])
            )
          );
          hideRenameAttachmentModal();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.renameSelectedAttachment)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.renameSelectedAttachment.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.renameSelectedAttachment = false;
        });
    }
    function removeMemberFromMemberList(data: object) {
      state.memberList = state.memberList.filter((e: any) => e != data);
    }

    function removeActiveMemberCount() {
      state.approvedMembersCount = state.approvedMembersCount - Number(1);
    }

    function renameSelectedSharedAttachment() {
      state.item.hasError = false;
      state.item.error.renameSelectedAttachment = [];
      if (state.ui.isPostLoading.renameSelectedAttachment) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        shared_file_id: state.selectedAttachment.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        filename:
          state.data.selectedForRename.newName +
          "." +
          state.data.selectedForRename.extension,
      };
      state.ui.isPostLoading.renameSelectedAttachment = true;
      ethitransService
        .selectedFileRename(payLoad)
        .then((res: any) => {
          // console.log("renameSelectedAttachment Check:>>> ", res.data.data);
          state.communicationList[
            common.findIndex(state.communicationList, state.selectedForDelete)
          ].share_history[
            common.findIndex(
              state.communicationList[
                common.findIndex(
                  state.communicationList,
                  state.selectedForDelete
                )
              ].share_history,
              state.selectedForSharedDelete
            )
          ].documents.forEach((doc: any) => {
            if (doc.id === res.data.data.id) {
              console.log("DOC", doc);
              doc.filename = res.data.data.filename;
              // eslint-disable-next-line @typescript-eslint/camelcase
              doc.updated_at = res.data.data.updated_at;
              //  console.log("Selected EDIT >>> ", doc)
            }
          });
          hideRenameAttachmentModal();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.renameSelectedAttachment)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.renameSelectedAttachment.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.renameSelectedAttachment = false;
        });
    }
    function renameSelectedAttachment() {
      if (state.data.selectedForRename.file_name) {
        renameAttachment();
      } else {
        renameSelectedSharedAttachment();
      }
    }
    function removeForwardFile(image: any) {
      state.data.forwardMessage.attachments = state.data.forwardMessage.attachments.filter((e: any) => e != image);

    }
    function selectedCommunicationImageToDelete(
      communication: any,
      image: any
    ) {
      state.selectedAttachment = image;
      // state.selectedAttachment = JSON.parse(JSON.stringify(image));
      state.selectedForDelete = communication;
      $("#deleteSelectedAttachment").modal("show");
      console.log("DELETE>>> ", state.selectedAttachment);
      // removeSelectedCommunicationImage(image);
    }

    function cancelSelectedEdit(selected: any) {
      state.data.selectedChatIndex = 0;
      // console.log("Reset Selecte Communication >>>", state.selectedForEdit)
      // emmit the data
      // selected = state.selectedForEdit
      // selected.allowEdit = false
      selected.allowEdit = false;
      selected.message = state.selectedForEdit.message;
      //  emit("edited-communication", state.selectedForEdit);
    }

    function sendEditedCommunication(selectedCommunication: any) {
      selectedCommunication.allowEdit = false;
      const selectedRowIndex = common.findIndex(
        state.communicationList,
        selectedCommunication
      );

      console.log("INDEX CHECK ", selectedRowIndex);
      if (selectedCommunication && selectedCommunication.message) {
        const payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          communication_id: selectedCommunication.id,
          message: selectedCommunication.message,
        };
        state.ui.isPostLoading.sendEditedCommunication = true;
        ethitransService
          .editCommunication(payLoad)
          .then((res: any) => {
            // res.data.data.communication.allowEdit = false;
            console.log(
              "editCommunication Check:>>> ",
              res.data.data.communication.updated_at
            );
            res.data.data.communication.editedUpdated = common.localDateAndTime(res.data.data.communication.updated_at
            );
            state.communicationList[selectedRowIndex] = res.data.data.communication;
            if (state.communicationList[selectedRowIndex].forward && state.communicationList[selectedRowIndex].forward.communication) {
              state.communicationList[selectedRowIndex].forward.communication.editedUpdated = common.localDateAndTime(
                state.communicationList[selectedRowIndex].forward.communication.updated_at
              );
            }
            state.communicationList.forEach((item: any) => {
              if (item.forward && item.forward.communication.id == res.data.data.communication.id) {
                item.forward.communication.message = res.data.data.communication.message;
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.forward.communication.is_edited = 1;
                item.forward.communication.editedUpdated = common.localDateAndTime(
                  res.data.data.communication.updated_at
                );
              }
            });
            state.communicationList[selectedRowIndex].allowEdit = false;
            state.communicationList[selectedRowIndex].editStatus = true;
            state.data.typeEditDisabled = false;
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            state.ui.isPostLoading.sendEditedCommunication = false;
          });
      }
    }

    function sendEditedReplyCommunication(selectedCommunication: any) {
      selectedCommunication.allowEdit = false;
      const selectedRowIndex = common.findIndex(
        state.communicationList,
        selectedCommunication
      );

      console.log("INDEX CHECK ", selectedRowIndex);
      if (selectedCommunication && selectedCommunication.message) {
        const payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          response_id: selectedCommunication.id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          response_type: "text",
          message: selectedCommunication.message,

        };
        state.ui.isPostLoading.sendEditedCommunication = true;
        ethitransService
          .editReplyCommunication(payLoad)
          .then((res: any) => {
            // res.data.data.communication.allowEdit = false;
            console.log(
              "editCommunication Check:>>> ",
              res.data.data.communication.updated_at
            );
            res.data.data.communication.editedUpdated = common.localDateAndTime(res.data.data.communication.updated_at
            );
            state.communicationList[selectedRowIndex] = res.data.data.communication;
            if (state.communicationList[selectedRowIndex].forward && state.communicationList[selectedRowIndex].forward.communication) {
              state.communicationList[selectedRowIndex].forward.communication.editedUpdated = common.localDateAndTime(
                state.communicationList[selectedRowIndex].forward.communication.updated_at
              );
            }
            state.communicationList.forEach((item: any) => {
              item.responses.forEach((res: any) => {
                if (res.id == selectedCommunication.id) {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  res.is_edited = 1;
                  res.editedUpdated = common.localDateAndTime(res.data.data.communication.updated_at);
                }
              })
              if (item.forward && item.forward.communication.id == res.data.data.communication.id) {
                item.forward.communication.message = res.data.data.communication.message;
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.forward.communication.is_edited = 1;
                item.forward.communication.editedUpdated = common.localDateAndTime(
                  res.data.data.communication.updated_at
                );
              }
            });
            state.communicationList[selectedRowIndex].allowEdit = false;
            state.communicationList[selectedRowIndex].editStatus = true;
            state.data.typeEditDisabled = false;
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            state.ui.isPostLoading.sendEditedCommunication = false;
          });
      }
    }
    function editChatCommunicationEnterSetting(data: boolean) {
      // const userInfo: any = localStorage.getItem("current_user_info");
      // if (JSON.parse(userInfo).enter_key === 0) return false;
      if (
        state.communicationList[
          state.data.selectedChatIndex
        ].message.startsWith("<p></p>")
      ) {
        console.log("<<Removed >>");
        state.communicationList[state.data.selectedChatIndex].message = "";
      }
      if (
        state.communicationList[
          state.data.selectedChatIndex
        ].message.startsWith("<p><br></p>")
      ) {
        // console.log("<<Removed >>");
        state.communicationList[state.data.selectedChatIndex].message = "";
      }
      // if (!state.message) return false;
      // if (data === true) {
      //   enableEnterKey.value = true;
      // } else {
      //   enableEnterKey.value = false;
      // }
      if (!state.communicationList[state.data.selectedChatIndex].message)
        return false;
      const userInfo: any = localStorage.getItem("current_user_info");
      // console.log("##### API ENTER KEY Local setting #### ", userInfo)
      console.log(
        "##### API ENTER KEY Local setting #### ",
        typeof JSON.parse(userInfo).enter_key
      );
      //  if (JSON.parse(userInfo).enter_key === 1) return false;
      // addEventListener("keyup", function (event: any) {
      //   if (event.keyCode === 13 && !event.shiftKey) {
      sendEditedCommunication(
        state.communicationList[state.data.selectedChatIndex]
      );
      //   }
      // });
    }
    function replyChatCommunicationEnterSetting(data: boolean) {
      //  if (common.onlySpaces(state.data.replyMessage)) {
      //   console.log("<<Removed >>");
      //   state.data.replyMessage = "";
      // }
      if (state.data.replyMessage.startsWith("<p></p>")) {
        console.log("<<Removed >>");
        state.data.replyMessage = "";
      }
      if (state.data.replyMessage.startsWith("<p><br></p>")) {
        console.log("<<Removed >>");
        state.data.replyMessage = "";
      }
      if (!state.data.replyMessage) return false;
      replyMessage();
    }

    // communicatin ends here
    function radioChange(element: any, option: any) {
      console.log("Check Element >>>", element);
      console.log("Check Form Data >>>", option);
      // state.userFormData = state.userFormData.filter(
      //   (item: any) => item.text != removeText
      // );
      // if (element.type === "radioButton") {
      //   state.newFormData.elements.forEach((item: any) => {
      //     if (
      //       item.title === element.title &&
      //       item.type === "radioButton" &&
      //       element.type === "radioButton"
      //     ) {
      //       if (item.type === "radioButton") {
      //         item.select_values.filter((data: any) => {
      //           if (option.text != data.text && option.value != data.value) {
      //             data.selected = "";
      //           }
      //         });
      //       }
      //       // console.log("Form Data elements >>>> ", item);
      //       // console.log("Check selected Element >>>", element);
      //       // console.log("Check selected Element's Option >>>", option);
      //     }
      //   });
      // }
    }
    function redirectToDynamicForm() {
      // console.log("<<< Redirecting to Dynamic Form >>>", channelId);
      // with query, resulting in /channel?channelId=private
      router.push({
        name: "admin-channel-create-form",
        query: { projectId: state.projectId, channelId: channelId },
      });
      $(".modal-backdrop").remove();
      // router.push({ path: 'channel', query: { channelId: 'private' } })
    }

    function selectToChangeUsers(value: any) {
      const existingIndex = state.invite.users.some((obj: any) => obj.id === value.id);
      if (value.id == "convo101" && allUsers.value.length >= 2) {
        const filteredArray = allUsers.value.filter((item: any) => item.id !== "convo101");
        state.invite.users = state.invite.users.concat(filteredArray);
        allUsers.value = [
          {
            'id': 'convo101',
            'label': 'Select All users',
            'email': 'Select All users',
          }
        ];
      }
      else if (!existingIndex && value.id != "convo101") {
        state.invite.users.push(value);
        const filteredArray = allUsers.value.filter((item: any) => item.id !== value.id);
        allUsers.value = filteredArray;
      }
      selectedUsers.value = {};
    }
    function getUserList() {
      // console.log("USERLIST check for channel ID", channelId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.projectId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
      };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.isLoading.getUserList = true;
      ethitransService
        .projectMemberListByChannel(payLoad)
        .then((res: any) => {
          // console.log("UserList Check:>>> ", res.data.data);
          state.userList = res.data.data ? res.data.data : [];
          allUsers.value = [
            {
              'id': 'convo101',
              'label': 'Select All user',
              'email': 'Select All user',
              'full_name': 'Select All user'
            }
          ];
          res.data.data.forEach((user: any) => {
            allUsers.value.push({
              'id': user.uuid,
              'label': user.email,
              'email': user.email,
              'full_name': user.full_name,
            })
          })
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.isLoading.getUserList = false;
        });
    }
    function addOptions() {
      console.log("CHECK  ADD");
      state.form.radioButton.select_values.push({
        text: "",
        value: "",
        selected: "",
      });
    }
    function addSelectOptions() {
      state.form.selectOption.select_values.push({
        text: "",
        value: "",
        selectedOption: {},
        selected: false,
      });
    }
    function addCheckBox() {
      state.form.checkBox.select_values.push({
        text: "",
        value: "",
        selected: false,
      });
    }
    function removeCheckBox(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.checkBox.select_values =
        state.form.checkBox.select_values.filter(
          (item: any) => item.text != removeText
        );
    }
    function removeSelectOption(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.selectOption.select_values =
        state.form.selectOption.select_values.filter(
          (item: any) => item.text != removeText
        );
    }
    function removeOptions(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.radioButton.select_values =
        state.form.radioButton.select_values.filter(
          (item: any) => item.text != removeText
        );
    }
    function showCreateNewForm() {
      // state.formList = []
      state.item.error.erorListNewForm = [];
      state.newFormData.elements = [];
      state.newFormData.title = "";
      state.newFormData.description = "";
      state.newFormData.privacy.code = "channel";
      state.newFormData.privacy.value = "private to this channel";
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#innerchannelModal").modal("show");
    }
    function showTextFieldAdd(type: string, heading: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.checkBox.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.radioButton.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.selectOption.select_values = [];
      state.typePosition = "bottom";
      // console.log("CHECK THE TYPE:>>>>", type);
      // console.log("CHECK THE Heading:>>>>", heading);
      state.selected = heading;
      state.type = type;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.radioButton.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.selectOption.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.checkBox.select_values = [];
      if (type === "radioButton") {
        addOptions();
      } else if (type === "single_select") {
        addSelectOptions();
      } else if (type === "checkBox") {
        addCheckBox();
      }
      state.form.textField.title = "";
      state.form.textField.description = "";
      state.form.textbox.title = "";
      state.form.textArea.title = "";
      state.form.radioButton.title = "";
      state.form.selectOption.title = "";
      state.form.checkBox.title = "";
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#addfieldModal").modal("show");
    }
    function addOptionsinCustom() {
      state.form.radioButton.select_values.push({
        text: "",
        value: "",
        selected: "",
      });
    }
    function addOptionsinCustomEdit() {
      if (
        state.selectedElement.element.select_values &&
        state.selectedElement.element.select_values.length
      ) {
        if (
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].text &&
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].value
        ) {
          // alert()
          state.selectedElement.element.select_values.push({
            text: "",
            value: "",
            selected: false,
          });
          //     state.form.radioButton.select_values.push({
          //   text: "",
          //   value: "",
          //   selected: false,
          // });
        }
      }
    }
    function addSelectOptionsinCustomEdit() {
      if (
        state.selectedElement.element.select_values &&
        state.selectedElement.element.select_values.length
      ) {
        if (
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].text &&
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].value
        ) {
          // alert();
          state.selectedElement.element.select_values.push({
            text: "",
            value: "",
            selectedOption: {},
            selected: false,
          });
        }
      }
    }
    function addSelectOptionsinCustom() {
      state.form.selectOption.select_values.push({
        text: "",
        value: "",
        selectedOption: {},
        selected: false,
      });
    }
    function addCheckBoxinCustom() {
      state.form.checkBox.select_values.push({
        text: "",
        value: "",
        selected: false,
      });
    }
    function addCheckBoxinCustomEdit() {
      if (
        state.selectedElement.element.select_values &&
        state.selectedElement.element.select_values.length
      ) {
        if (
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].text &&
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].value
        ) {
          state.selectedElement.element.select_values.push({
            text: "",
            value: "",
            selected: false,
          });
        }
      }
    }
    function showCustomEdit(type: string, heading: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.checkBox.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.radioButton.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.selectOption.select_values = [];
      state.typePosition = "bottom";
      state.selected = heading;
      // console.log("CHECK THE TYPE:>>>>", type);
      // console.log("CHECK THE Heading:>>>>", heading);
      state.type = type;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.radioButton.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.selectOption.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.checkBox.select_values = [];
      if (type === "radioButton") {
        addOptionsinCustom();
      } else if (type === "single_select") {
        addSelectOptionsinCustom();
      } else if (type === "checkBox") {
        // console.log("Hello CheckBox");
        addCheckBoxinCustom();
      }
      state.form.textbox.title = "";
      state.form.textArea.title = "";
      state.form.radioButton.title = "";
      state.form.selectOption.title = "";
      state.form.checkBox.title = "";
      state.form.textField.description = "";
      state.form.textField.title = "";
      state.item.error.erorList = [];
      $("#addfieldModal1").modal("show");
    }

    function removeCheckBoxinCustomEdit(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.selectedElement.element.select_values =
        state.selectedElement.element.select_values.filter(
          (item: any) => item.text != removeText
        );
    }
    function removeSelectOptioninCustomEdit(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.selectedElement.element.select_values =
        state.selectedElement.element.select_values.filter(
          (item: any) => item.text != removeText
        );
    }
    function removeOptionsinCustomEdit(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.selectedElement.element.select_values =
        state.selectedElement.element.select_values.filter(
          (item: any) => item.text != removeText
        );
    }
    function titleSectionValiation() {
      state.item.error.erorList = [];
      state.item.hasError = false;

      if (!state.form.textField.title) {
        state.item.error.erorList.push("Project name is required.");
      }

      if (!state.item.error.erorList && !state.item.error.erorList.length) {
        state.item.hasError = false;
      } else if (state.item.error.erorList.length != 0) {
        state.item.hasError = true;
      }
    }
    function editElementsCustomValiation(type: string) {
      console.log("WOWOW", type);
      state.item.error.erorList = [];
      state.item.hasError = false;

      if (type === "section") {
        if (!state.form.textField.title) {
          console.log("Alert SSS");
          state.item.error.erorList.push("Section title is required.");
        }
      }
      if (type === "textbox") {
        console.log("Alert TB");
        if (!state.form.textbox.title) {
          state.item.error.erorList.push("Title is required.");
        }
      }
      if (type === "textArea") {
        if (!state.form.textArea.title) {
          state.item.error.erorList.push("Title is required.");
        }
      }
      if (type === "radioButton") {
        console.log("Radio Values Check", state.form.radioButton.select_values);
        if (!state.form.radioButton.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.radioButton.select_values &&
          state.form.radioButton.select_values.length
        ) {
          state.form.radioButton.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }
      if (type === "single_select") {
        console.log(
          "Radio Values Check",
          state.form.selectOption.select_values
        );
        if (!state.form.selectOption.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.selectOption.select_values &&
          state.form.selectOption.select_values.length
        ) {
          state.form.selectOption.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }
      if (type === "checkBox") {
        console.log("Radio Values Check", state.form.checkBox.select_values);
        if (!state.form.checkBox.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.checkBox.select_values &&
          state.form.checkBox.select_values.length
        ) {
          state.form.checkBox.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }

      if (!state.item.error.erorList && !state.item.error.erorList.length) {
        state.item.hasError = false;
      } else if (state.item.error.erorList.length != 0) {
        state.item.hasError = true;
      }
    }

    function addTextFieldInCustomEdit() {
      if (state.type === "section") {
        editElementsCustomValiation("section");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        if (state.typePosition === "top") {
          state.selectedFormForEdit.elements.unshift({
            type: "section",
            title: state.form.textField.title,
            description: state.form.textField.description,
          });
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.push({
              type: "section",
              title: state.form.textField.title,
              description: state.form.textField.description,
            });
          } else {
            state.selectedFormForEdit.elements =
              state.selectedFormForEdit.elements.concat([
                {
                  type: "section",
                  title: state.form.textField.title,
                  description: state.form.textField.description,
                },
              ]);
          }
        }
      }
      if (state.type === "textbox") {
        editElementsCustomValiation("textbox");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        console.log("FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          state.selectedFormForEdit.elements.unshift({
            type: "textbox",
            title: state.form.textbox.title,
            answer: "",
            tooltip: "Please write your full name",
            placeholder: "Write your name here",
            validation: [
              {
                type: "required",
                // eslint-disable-next-line @typescript-eslint/camelcase
                error_message: "Your name is required",
              },
            ],
          });
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.unshift({
              type: "textbox",
              title: state.form.textbox.title,
              answer: "",
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.selectedFormForEdit.elements =
              state.selectedFormForEdit.elements.concat([
                {
                  type: "textbox",
                  title: state.form.textbox.title,
                  answer: "",
                  tooltip: "Please write your full name",
                  placeholder: "Write your name here",
                  validation: [
                    {
                      type: "required",
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      error_message: "Your name is required",
                    },
                  ],
                },
              ]);
          }
        }
      }
      if (state.type === "title") {
        editElementsCustomValiation("title");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        console.log("FORM POSITION:>>", state.typePosition);
        titleSectionValiation();
        if (
          state.item.error.erorList.length != 0 ||
          state.item.hasError ||
          !state.typePosition
        )
          return false;
        if (state.typePosition === "top") {
          state.selectedFormForEdit.elements.unshift({
            type: "title",
            title: state.form.textField.title,
            description: state.form.textField.description,
          });
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.unshift({
              type: "title",
              title: state.form.textField.title,
              description: state.form.textField.description,
            });
          } else {
            state.selectedFormForEdit.elements =
              state.selectedFormForEdit.elements.concat([
                {
                  type: "title",
                  title: state.form.textField.title,
                  description: state.form.textField.description,
                },
              ]);
          }
        }
      }
      if (state.type === "textArea") {
        editElementsCustomValiation("textArea");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        if (state.typePosition === "top") {
          if (
            state.selectedFormForEdit &&
            state.selectedFormForEdit.elements.length
          ) {
            state.selectedFormForEdit.elements.unshift({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.selectedFormForEdit.elements.push({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.push({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.selectedFormForEdit.elements =
              state.selectedFormForEdit.elements.concat([
                {
                  type: "textArea",
                  title: state.form.textArea.title,
                  answer: state.form.textArea.text,
                  tooltip: "Please write your full name",
                  placeholder: "Write your name here",
                  validation: [
                    {
                      type: "required",
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      error_message: "Your name is required",
                    },
                  ],
                },
              ]);
          }
        }
      }
      if (state.type === "radioButton") {
        editElementsCustomValiation("radioButton");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        state.form.radioButton.select_values.forEach((item: any) => {
          if (item.text) {
            // console.log("CHECK FOR WHITE SPACE", hasWhiteSpace(item.text.toLowerCase()));
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        if (state.typePosition === "top") {
          if (state.form.radioButton) {
            state.selectedFormForEdit.elements.unshift({
              type: "radioButton",
              title: state.form.radioButton.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.radioButton.select_values,
            });
            // state.selectedFormForEdit
            // .elements.unshift( { state.form.radioButton
            // });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.unshift({
              type: "radioButton",
              title: state.form.radioButton.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.radioButton.select_values,
            });
          } else {
            state.selectedFormForEdit.elements =
              state.selectedFormForEdit.elements.concat([
                {
                  type: "radioButton",
                  title: state.form.radioButton.title,
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  select_values: state.form.radioButton.select_values,
                },
              ]);
          }
        }
      }
      if (state.type === "single_select") {
        editElementsCustomValiation("single_select");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        state.form.selectOption.select_values.forEach((item: any) => {
          if (item.text) {
            // console.log("CHECK FOR WHITE SPACE", hasWhiteSpace(item.text.toLowerCase()));
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        if (state.typePosition === "top") {
          if (state.form.selectOption) {
            state.selectedFormForEdit.elements.unshift({
              type: "single_select",
              selectedOption: {},
              title: state.form.selectOption.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.selectOption.select_values,
            });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.unshift({
              type: "single_select",
              selectedOption: {},
              title: state.form.selectOption.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.selectOption.select_values,
            });
          } else {
            state.selectedFormForEdit.elements =
              state.selectedFormForEdit.elements.concat([
                {
                  type: "single_select",
                  selectedOption: {},
                  title: state.form.selectOption.title,
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  select_values: state.form.selectOption.select_values,
                },
              ]);
          }
        }
      }
      if (state.type === "checkBox") {
        editElementsCustomValiation("checkBox");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        state.form.checkBox.select_values.forEach((item: any) => {
          if (item.text) {
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        if (state.typePosition === "top") {
          if (state.form.selectOption) {
            state.selectedFormForEdit.elements.unshift({
              type: "checkBox",
              title: state.form.checkBox.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.checkBox.select_values,
            });
            // state..unshift( { state.form.checkBox
            // });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.unshift({
              type: "checkBox",
              title: state.form.checkBox.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.checkBox.select_values,
            });
          } else {
            state.selectedFormForEdit.elements =
              state.selectedFormForEdit.elements.concat([
                {
                  type: "checkBox",
                  title: state.form.checkBox.title,
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  select_values: state.form.checkBox.select_values,
                },
              ]);
          }
        }
      }

      $("#addfieldModal1").modal("hide");
      // console.log("CHECK CUstom Edit Data", state.selectedFormForEdit);
    }
    function addElementsCreateNewValiation(type: string) {
      state.item.error.erorList = [];
      state.item.hasError = false;

      if (type === "section") {
        if (!state.form.textField.title) {
          state.item.error.erorList.push("Section title is required.");
        }
      }
      if (type === "textbox") {
        if (!state.form.textbox.title) {
          state.item.error.erorList.push("Title is required.");
        }
      }
      if (type === "textArea") {
        if (!state.form.textArea.title) {
          state.item.error.erorList.push("Title is required.");
        }
      }
      if (type === "radioButton") {
        console.log("Radio Values Check", state.form.radioButton.select_values);
        if (!state.form.radioButton.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.radioButton.select_values &&
          state.form.radioButton.select_values.length
        ) {
          state.form.radioButton.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }
      if (type === "single_select") {
        console.log(
          "Radio Values Check",
          state.form.selectOption.select_values
        );
        if (!state.form.selectOption.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.selectOption.select_values &&
          state.form.selectOption.select_values.length
        ) {
          state.form.selectOption.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }
      if (type === "checkBox") {
        console.log("Radio Values Check", state.form.checkBox.select_values);
        if (!state.form.checkBox.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.checkBox.select_values &&
          state.form.checkBox.select_values.length
        ) {
          state.form.checkBox.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }

      if (!state.item.error.erorList && !state.item.error.erorList.length) {
        state.item.hasError = false;
      } else if (state.item.error.erorList.length != 0) {
        state.item.hasError = true;
      }
    }
    function addTextField() {
      if (state.type === "section") {
        addElementsCreateNewValiation("section");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        console.log("Section FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          state.newFormData.elements.unshift({
            type: "section",
            title: state.form.textField.title,
            description: state.form.textField.description,
          });
        }
        if (state.typePosition === "bottom") {
          if (state.newFormData.elements.length === 0) {
            state.newFormData.elements.push({
              type: "section",
              title: state.form.textField.title,
              description: state.form.textField.description,
            });
          } else {
            state.newFormData.elements = state.newFormData.elements.concat([
              {
                type: "section",
                title: state.form.textField.title,
                description: state.form.textField.description,
              },
            ]);
          }
        }
      }
      if (state.type === "textbox") {
        addElementsCreateNewValiation("textbox");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        console.log("FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          state.newFormData.elements.unshift({
            type: "textbox",
            title: state.form.textbox.title,
            answer: "",
            tooltip: "Please write your full name",
            placeholder: "Write your name here",
            validation: [
              {
                type: "required",
                // eslint-disable-next-line @typescript-eslint/camelcase
                error_message: "Your name is required",
              },
            ],
          });
        }
        if (state.typePosition === "bottom") {
          console.log(
            "Check Array length >>>",
            state.newFormData.elements.length
          );
          if (state.newFormData.elements.length === 0) {
            state.newFormData.elements.push({
              type: "textbox",
              title: state.form.textbox.title,
              answer: "",
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.newFormData.elements = state.newFormData.elements.concat([
              {
                type: "textbox",
                title: state.form.textbox.title,
                answer: "",
                tooltip: "Please write your full name",
                placeholder: "Write your name here",
                validation: [
                  {
                    type: "required",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    error_message: "Your name is required",
                  },
                ],
              },
            ]);
          }
        }
      }
      if (state.type === "textArea") {
        addElementsCreateNewValiation("textArea");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        if (state.typePosition === "top") {
          if (state.newFormData && state.newFormData.elements.length) {
            state.newFormData.elements.unshift({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.newFormData.elements.push({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          }
        }
        if (state.typePosition === "bottom") {
          console.log(
            "Check Array length >>>",
            state.newFormData.elements.length
          );
          if (state.newFormData.elements.length === 0) {
            state.newFormData.elements.unshift({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.newFormData.elements = state.newFormData.elements.concat([
              {
                type: "textArea",
                title: state.form.textArea.title,
                answer: state.form.textArea.text,
                tooltip: "Please write your full name",
                placeholder: "Write your name here",
                validation: [
                  {
                    type: "required",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    error_message: "Your name is required",
                  },
                ],
              },
            ]);
          }
        }
      }
      if (state.type === "radioButton") {
        addElementsCreateNewValiation("radioButton");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // console.log("FORM POSITION:>>", state.typePosition);
        // console.log("Check Radio Button:>>", state.form.radioButton);
        state.form.radioButton.select_values.forEach((item: any) => {
          if (item.text) {
            // console.log("CHECK FOR WHITE SPACE", hasWhiteSpace(item.text.toLowerCase()));
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        if (state.typePosition === "top") {
          if (state.form.radioButton) {
            state.newFormData.elements.unshift({
              type: "radioButton",
              title: state.form.radioButton.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.radioButton.select_values,
            });
            // state.newFormData.elements.unshift( { state.form.radioButton
            // });
          }
        }
        if (state.typePosition === "bottom") {
          console.log(
            "Check Array length >>>",
            state.newFormData.elements.length
          );
          if (state.newFormData.elements.length === 0) {
            if (state.form.radioButton) {
              state.newFormData.elements.unshift({
                type: "radioButton",
                title: state.form.radioButton.title,
                // eslint-disable-next-line @typescript-eslint/camelcase
                select_values: state.form.radioButton.select_values,
              });
              // state.newFormData.elements.unshift( { state.form.radioButton
              // });
            }
          } else {
            state.newFormData.elements = state.newFormData.elements.concat([
              {
                type: "radioButton",
                title: state.form.radioButton.title,
                // eslint-disable-next-line @typescript-eslint/camelcase
                select_values: state.form.radioButton.select_values,
              },
            ]);
          }
        }
        console.log(
          "Check the radio data",
          state.form.radioButton.select_values
        );
      }
      if (state.type === "single_select") {
        console.log("SELECT OPTION >>>", state.form.selectOption.select_values);
        addElementsCreateNewValiation("single_select");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        state.form.selectOption.select_values.forEach((item: any) => {
          if (item.text) {
            // console.log("CHECK FOR WHITE SPACE", hasWhiteSpace(item.text.toLowerCase()));
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        console.log("FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          if (state.form.selectOption) {
            state.newFormData.elements.unshift({
              type: "single_select",
              selectedOption: {},
              title: state.form.selectOption.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.selectOption.select_values,
            });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.newFormData.elements.length === 0) {
            state.newFormData.elements.unshift({
              type: "single_select",
              selectedOption: {},
              title: state.form.selectOption.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.selectOption.select_values,
            });
          } else {
            state.newFormData.elements = state.newFormData.elements.concat([
              {
                type: "single_select",
                selectedOption: {},
                title: state.form.selectOption.title,
                // eslint-disable-next-line @typescript-eslint/camelcase
                select_values: state.form.selectOption.select_values,
              },
            ]);
          }
        }
      }
      if (state.type === "checkBox") {
        addElementsCreateNewValiation("checkBox");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        state.form.checkBox.select_values.forEach((item: any) => {
          if (item.text) {
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        console.log("FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          if (state.form.selectOption) {
            state.newFormData.elements.unshift({
              type: "checkBox",
              title: state.form.checkBox.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.checkBox.select_values,
            });
            // state..unshift( { state.form.checkBox
            // });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.newFormData.elements.length === 0) {
            if (state.form.selectOption) {
              state.newFormData.elements.unshift({
                type: "checkBox",
                title: state.form.checkBox.title,
                // eslint-disable-next-line @typescript-eslint/camelcase
                select_values: state.form.checkBox.select_values,
              });
              // state..unshift( { state.form.checkBox
              // });
            }
          } else {
            state.newFormData.elements = state.newFormData.elements.concat({
              type: "checkBox",
              title: state.form.checkBox.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.checkBox.select_values,
            });
          }
        }
      }

      $("#addfieldModal").modal("hide");
      console.log("CHECK", state.newFormData);
    }

    function scrollAtButton() {
      // alert("hello");
      //   var myDiv = document.getElementById("myDiv");
      // myDiv.scrollTop = myDiv.scrollHeight;
      // chat-body-content
      // location.reload();
      // window.onload = function () {
      const objDiv: any = document.getElementById("chat-body-content");
      objDiv.scrollTop = objDiv.scrollHeight - 3;
      // };
      // return false;
    }

    function scrollToTop() {
      // alert("hello om");
      // if(routerQuery.query.page && routerQuery.query.communicationId){
      //  router.push({
      //   name: "admin-channel",
      //   params: { channelId: channelId },
      // });
      // state.data.page++;
      // }

      $("#chat-body-content").animate(
        { scrollTop: $("#chat-body-content")[0].scrollHeight / 15 },
        10
      );
      state.firstScroll = false;
    }

    function cancelEditSelectedForm() {
      // state.selectedFormForEdit.title = state.selectedFormForEdit.title
      //   state.selectedFormForEdit.description = state.selectedFormForEdit.description
      $("#editSelectedElement").modal("hide");
    }
    function updateEditFormElementsValidation(type: string) {
      state.item.error.erorList = [];
      state.item.hasError = false;

      if (type === "title") {
        if (!state.selectedElement.title) {
          state.item.error.erorList.push("Form title is required.");
        }
      }
      if (type === "section" || type === "textbox" || type === "textArea") {
        if (!state.selectedElement.title) {
          state.item.error.erorList.push("Title is required.");
        }
      }
      if (
        type === "radioButton" ||
        type === "single_select" ||
        type === "checkBox"
      ) {
        console.log("Radio Values Check", state.form.radioButton.select_values);
        if (!state.selectedElement.element.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.selectedElement.element.select_values &&
          state.selectedElement.element.select_values.length
        ) {
          state.selectedElement.element.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }
      if (!state.item.error.erorList && !state.item.error.erorList.length) {
        state.item.hasError = false;
      } else if (state.item.error.erorList.length != 0) {
        state.item.hasError = true;
      }
    }
    function editSelectedForm() {
      if (state.selectedElement.type === "title") {
        updateEditFormElementsValidation("title");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // console.log("<<< Title Change >>>");
        state.selectedFormForEdit.title = state.selectedElement.title;
        state.selectedFormForEdit.description =
          state.selectedElement.description;
      } else if (
        state.selectedElement.type === "textbox" ||
        state.selectedElement.type === "section"
      ) {
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // state.selectedFormForEdit.title = state.selectedElement.title;
        state.selectedFormForEdit.elements[state.selectedElement.index].title =
          state.selectedElement.title;
        state.selectedFormForEdit.elements[
          state.selectedElement.index
        ].description = state.selectedElement.description;
        // console.log("<<< Title Change >>>");
      } else if (state.selectedElement.type === "textArea") {
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // state.selectedFormForEdit.title = state.selectedElement.title;
        // console.log("<<< Title Change >>>");
        state.selectedFormForEdit.elements[state.selectedElement.index].title =
          state.selectedElement.title;
      } else if (
        state.selectedElement.type === "radioButton" ||
        state.selectedElement.type === "single_select" ||
        state.selectedElement.type === "checkBox"
      ) {
        // console.log("<<< Title Change >>>");
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;

        state.selectedFormForEdit.elements[state.selectedElement.index] =
          state.selectedElement.element;
      }
      // state.selectedElement.index

      // state.selectedFormForEdit.elements.splice(
      //   state.selectedElement.index,
      //   1,
      //   state.selectedElement.element
      // );
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#editSelectedElement").modal("hide");
    }
    function showEditFormName(data: any) {
      state.selectedElement.type = data;
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#editSelectedElement").modal("show");
      console.log("Title Data Check: >>", state.selectedElement.type);
      console.log("edit Title:>>", state.selectedFormForEdit.title);
      console.log("edit Description:>>", state.selectedFormForEdit.description);
      state.selectedElement.title = state.selectedFormForEdit.title;
      state.selectedElement.description = state.selectedFormForEdit.description;
    }
    function showDeleteElement(form: any, elementType: string) {
      // addTextField;
      console.log("Element Selected for Delete: >>> ", form);
      console.log("Element Type: >>> ", elementType);
      const formIndex = common.findIndex(
        state.selectedFormForEdit.elements,
        form
      );
      console.log("Selected Element Index For Delete", formIndex);
      state.selectedElement.element = form;
      $("#deleteSelectedElement").modal("show");
    }
    function cancelDeleteSelectedForm() {
      state.selectedElement.element = {};
      $("#deleteSelectedElement").modal("hide");
    }
    function confirmDeleteElement() {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.selectedFormForEdit.elements =
        state.selectedFormForEdit.elements.filter(
          (item: any) => item != state.selectedElement.element
        );
      $("#deleteSelectedElement").modal("hide");
    }
    function showEditElement(form: any, elementType: string) {
      // addTextField;
      console.log("Edit Element Selected: >>> ", form);
      console.log("Element Type: >>> ", elementType);
      const formIndex = common.findIndex(
        state.selectedFormForEdit.elements,
        form
      );
      console.log("Selected Form Index", formIndex);
      $("#editSelectedElement").modal("show");
      state.selectedElement.element = form;
      state.selectedElement.type = elementType;
      state.selectedElement.index = common.findIndex(
        state.selectedFormForEdit.elements,
        form
      );
      state.selectedElement.title = form.title;
      state.selectedElement.description = form.description;
    }
    function selectedElement(form: any) {
      console.log("Selected element>>>:", form);
    }
    function handleClick() {
      console.log("EMIT Check");
    }
    function getAccountFormRetrieveById(formId: any) {
      state.selectedFormForEdit = {};
      // console.log("Get Account form Response:>>>", formId);
      state.ui.isPostLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          console.log(
            "getAccountFormRetrieveById Response Check:>>> ",
            res.data.elements
          );
          // state.formData = res.data;
          state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getAccountFormRetrieveById = false;
        });
    }
    function getAccountFormRetrieveByIdForSend(formId: any) {
      state.formData = {};
      // console.log("Get Account form Response:>>>", formId);
      state.ui.isPostLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          console.log(
            "getAccountFormRetrieveById Response Check:>>> ",
            res.data.elements
          );
          state.formData = res.data ? res.data : {};
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getAccountFormRetrieveById = false;
        });
    }
    function showMentionUser() {
      getUserList();
      state.invite.users = [];
      state.ui.template.msg = "";
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;
      $("#mentionuser").modal("show");
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const sentToUsers = computed(() => {
      if (state.formData.sentToUsers && state.formData.sentToUsers.length) {
        return state.formData.sentToUsers.filter((form: any) => {
          if (form.email) {
            return form.email
              .toLowerCase()
              .match(state.item.searchUser.toLowerCase());
          }
        });
      } else {
        return [];
      }
    });
    // function hidecalender() {
    //   state.data.isCalenderHide = false;
    // }

    function hidecalender() {
      state.userFormData.forEach((item: any) => {
        if (item.type === "datePicker") {
          item.show = false;
        }
      });
    }

    watch(
      () => state.data.convertedDate,
      () => {
        setTimeout(hidecalender, 100);
      }
    );

    const userFormData = computed(() => {
      state.userFormData.forEach((item: any) => {
        if (item.type === "datePicker") {
          const day = item.date ? item.date : new Date();
          // console.log("DATE PICKER CHECK >>>", item.date);
          // console.log("DATE PICKER CHECK fINAL >>>", day);
          const dayWrapper = moment(day);
          const dayString = dayWrapper.format("L");
          // return dayString;
          item.convertedDate = dayString;
          // if(item.type === 'digitalSignature') {
          //     console.log("Digital Signature Data", item)
          //     item.canvas = document.getElementById(item.canvasName);
          //     if (item.canvas) {
          //       item.signaturePad = new SignaturePad(item.canvas, {
          //       backgroundColor: "rgb(255, 255, 255)",
          //     });
          //     // window.onresize = resizeCanvas;
          //     // resizeCanvas()
          //   }
          // setTimeout({item.show = false}, 100);
          state.data.convertedDate = item.convertedDate;
        }
      });
      if (state.data.startValidation) {
        state.userFormData.forEach((data: any) => {
          // console.log("CHECK ::: >>> ", data)
          if (data.required) {
            if (data.type === "textbox" || data.type === "textArea") {
              if (!data.answer) {
                data.hasError = data.title;
                // state.item.error.validationList.push(`${data.title} is required.`);
              } else {
                data.hasError = "";
              }
            } else if (
              data.type === "checkBox" ||
              data.type === "radioButton"
            ) {
              if (data.options && data.options.length) {
                // console.log("USER's DATA FILL CHECK >>> ", data)
                const optionList: any = [];
                data.options.forEach((item: any) => {
                  // if(item.selected) return false;
                  if (item.selected) {
                    optionList.push(item);
                  }
                });
                // console.log("CHECK CHECK >>", optionList)
                if (optionList && !optionList.length) {
                  data.hasError = data.title;
                  // state.item.error.validationList.push(`${data.title} is required.`);
                } else {
                  data.hasError = "";
                }
              }
            } else if (data.type === "single_select") {
              // alert()
              if (data.options && data.options.length) {
                // console.log("USER's DATA FILL CHECK >>> ", data)
                if (!data.selected) {
                  data.hasError = data.title;
                  // state.item.error.validationList.push(`${data.title} is required.`);
                } else {
                  data.hasError = "";
                }
              }
            } else if (data.type === "datePicker") {
              // alert()
              // if(data.options && data.options.length) {
              // console.log("USER's DATA FILL CHECK >>> ", data)
              if (!data.date || !data.convertedDate) {
                data.hasError = data.title;
                // state.item.error.validationList.push(`${data.title} is required.`);
              } else {
                data.hasError = "";
              }
              // }
            }
          }
        });
      }

      return state.userFormData;
    });

    function setCanvas(elementRow: any) {
      // console.log("Canvas Data Check", elementRow.canvasName)
      elementRow.canvas = document.getElementById(`${elementRow.canvasName}`);
      // elementRow.canvas = document.getElementById("myCanvas0");
      if (elementRow.canvas) {
        elementRow.signaturePad = new SignaturePad(elementRow.canvas, {
          backgroundColor: "rgb(255, 255, 255)",
        });
      }
    }
    function canvasSet() {
      state.userFormData.forEach((element: any) => {
        if (element.type === "digitalSignature") {
          // console.log("CHECK", element)
          setCanvas(element);
        }
      });
      // state.canvas = document.getElementById("myCanvas");
      // // console.log("Canvas Data Check", state.canvas)
      // if (state.canvas) {
      //   state.signaturePad = new SignaturePad(state.canvas, {
      //     backgroundColor: "rgb(255, 255, 255)",
      //   });
      //   // console.log("Canvassignature", state.signaturePad)
      //   // window.onresize = resizeCanvas;
      //   // resizeCanvas()
      // }
    }

    function userFormClick() {
      state.userFormData = JSON.parse(JSON.stringify(state.formData.elements));
      $("#responseModal").modal("show");
      setTimeout(canvasSet, 100);
      // canvas Active
    }
    function resetheight() {
      document.querySelectorAll("textarea").forEach((element) => {
        element.style.height = "10px";
      });
    }
    function mideHeight() {
      // const element = document.getElementById("chat-body-content");
      //  element.scrollIntoView();
    }
    function scrollToLast() {
      if (state.data.isCommunicationFile) {
        const domId: any = $(".chat-block.active").attr("id");
        if (domId != null) {
          const element: any = document.getElementById(domId);
          element.scrollIntoView();
        }
      } else {
        if (state.messageStatus) {
          // alert("file me");
          setTimeout(() => {
            $("#chat-body-content").animate(
              {
                scrollTop: $("#chat-body-content")[0].scrollHeight + 900,
              },
              1
            );

            setTimeout(() => {
              state.messageStatus = false;
            }, 500);
            state.fileLoadTime = 30;
          }, state.fileLoadTime);
        } else {
          $("#chat-body-content").animate(
            {
              scrollTop: $("#chat-body-content")[0].scrollHeight,
            },
            1000
          );
        }
      }
    }

    function setMentionMembers(channelMember: any) {
      store.dispatch("saveActiveMembers", channelMember);
    }

    function replyCommunication(data: any) {
      // if(pageQuery.value){
      //   router.push({
      //   name: "admin-channel",
      //   params: { channelId: channelId },
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   query: { communication_id: data.id, page: pageQuery.value},
      // });
      // }else
      // {
      //   router.push({
      //   name: "admin-channel",
      //   params: { channelId: channelId },
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   query: { communication_id: data.id },
      // });
      // }

      state.data.replyMessage = "";
      state.isReplyMessage = true;
      if (state.data.showReplyCommunication) {
        state.data.replyClassStatus = true;
      } else {
        state.data.replyClassStatus = !state.data.replyClassStatus;
      }
      console.log("DATAHEARE", data);

      data.responses.forEach((data: any) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        data.sentDate = common.localDateAndTime(data.sent_date);
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (data.is_edited === 1) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          data.editedUpdated = common.localDateAndTime(
            data.updated_at
          );
        }
      });
      // state.data.selectedReplyUser.responses = data;

      state.data.selectedReplyUser = data;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.selectedReplyUser.sentDate = common.localDateAndTime(
        data.sent_date
      );
      // scrollToLastThread()
      setTimeout(scrollToLastThread, 500);
      console.log("here reply status", state.isReplyMessage);
    }

    //get instance message
    function getInstanceMessageList(messageId: any) {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_id: messageId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
      };
      ethitransService
        .getInstanceMessage(payLoad)
        .then((res: any) => {
          console.log("instancemessage", res.data.data);

          if (
            state.communicationList.filter(
              (element: any) => element.id == messageId
            ).length > 0
          ) {
            state.communicationList.forEach((element: any, index: any) => {
              if (element.id == messageId) {
                res.data.data.messages.responses.forEach((response: any) => {
                  response.createdLatest = common.localDateAndTime(
                    response.created_at
                  );

                  response.editedUpdated = common.localDateAndTime(response.updated_at);
                  response.sentDate = common.localDateAndTime(
                    response.sent_date
                  );
                });


                if (
                  res.data.data.messages.attachments &&
                  res.data.data.messages.attachments.length
                ) {
                  res.data.data.messages.attachments.forEach(
                    (attachment: any) => {
                      attachment.convertedFileSize = common.formatBytes(
                        parseInt(
                          attachment.file_size
                          // parseInt(data.file_size.split(" ")[0])
                        )
                      );
                    }
                  );
                }

                if (
                  res.data.data.messages.forward &&
                  res.data.data.messages.forward.attachments.length
                ) {
                  res.data.data.messages.forward.attachments.forEach((attachment: any) => {
                    attachment.convertedFileSize = common.formatBytes(
                      parseInt(
                        attachment.file_size
                        // parseInt(data.file_size.split(" ")[0])
                      )
                    );
                  });
                }

                res.data.data.messages.currentTime = common.localDateAndTime(
                  res.data.data.messages.sent_date
                );
                res.data.data.messages.editedUpdated = common.localDateAndTime(
                  res.data.data.messages.updated_at
                );
                const result: any = [];
                const map = new Map();
                res.data.data.messages.responses.forEach((item: any) => {
                  if (!map.has(item.response_by_id)) {
                    map.set(item.response_by_id, true); // set any value to Map
                    result.push(item);
                  }
                });
                res.data.data.messages.uniqueResponse = result;
                // res.data.data.messages.editStatus = true;
                if (res.data.data.messages.user.email == localStorage.getItem("current_user_email")) {
                  res.data.data.messages.editStatus = true;
                } else {
                  res.data.data.messages.editStatus = false;
                }
                state.communicationList[index] = res.data.data.messages;

                // eslint-disable-next-line @typescript-eslint/camelcase
                if (
                  state.data.selectedReplyUser.id ==
                  state.communicationList[index].id
                ) {
                  state.data.selectedReplyUser = state.communicationList[index];
                  setTimeout(() => {
                    replyMessage();
                  });
                }
              }

            });
          } else {
            if (
              res.data.data.messages.attachments &&
              res.data.data.messages.attachments.length
            ) {
              res.data.data.messages.attachments.forEach((attachment: any) => {
                attachment.convertedFileSize = common.formatBytes(
                  parseInt(
                    attachment.file_size
                    // parseInt(data.file_size.split(" ")[0])
                  )
                );
              });
            }

            if (
              res.data.data.messages.forward &&
              res.data.data.messages.forward.attachments.length
            ) {
              res.data.data.messages.forward.attachments.forEach((attachment: any) => {
                attachment.convertedFileSize = common.formatBytes(
                  parseInt(
                    attachment.file_size
                    // parseInt(data.file_size.split(" ")[0])
                  )
                );
              });
            }

            res.data.data.messages.currentTime = common.localDateAndTime(
              res.data.data.messages.sent_date
            );

            if (res.data.data.messages.user.email == localStorage.getItem("current_user_email")) {
              res.data.data.messages.editStatus = true;
            } else {
              res.data.data.messages.editStatus = false;
            }
            const result: any = [];
            const map = new Map();
            res.data.data.messages.responses.forEach((item: any) => {
              if (!map.has(item.response_by_id)) {
                map.set(item.response_by_id, true); // set any value to Map
                result.push(item);
              }
            });
            res.data.data.messages.uniqueResponse = result;
            res.data.data.messages.responses.forEach((response: any) => {
              res.data.data.messages.response.createdLatest =
                common.localDateAndTime(response.created_at);
              response.sentDate = common.localDateAndTime(response.sent_date);
            });
            state.communicationList.push(res.data.data.messages);

            // eslint-disable-next-line @typescript-eslint/camelcase
            if (state.data.selectedReplyUser.id == res.data.data.messages.id) {
              state.data.selectedReplyUser = res.data.data.messages;
              setTimeout(() => {
                replyMessage();
              });
            }
          }


          state.communicationList.forEach((element: any, index: any) => {
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (element.forward && element.forward.communication && element.forward.original_id == messageId) {
              element.forward.communication = res.data.data.messages;
              element.forward.communication.message = res.data.data.messages.message;
              // eslint-disable-next-line @typescript-eslint/camelcase
              element.forward.communication.responses_count = res.data.data.messages.responses_count;
              if (element.forward.communication.responses.length) {
                element.forward.communication.responses.forEach((response: any) => {
                  element.forward.communication.response.createdLatest = common.localDateAndTime(response.created_at);
                  response.sentDate = common.localDateAndTime(response.sent_date);

                });
              }

            }

          });


          // setTimeout(()=>{
          // },20)
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isLoading.getChannelFormList = false;
        });
    }


    function replyCounter(replies: any, regex: any) {
      if (replies && replies.length > 0) {
        replies.forEach((item: any) => {
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (item.message || item.file_name) {
            console.log("replymessage", item.message)
            // eslint-disable-next-line @typescript-eslint/camelcase
            const result = (item.message && item.message.match(regex)) || (item.file_name && item.file_name.match(regex));
            if (result) {
              state.totalSearchResultCount = state.totalSearchResultCount + result.length;
              replies.matchCount = state.totalSearchResultCount;
            }
          }
        })
      }

    }

    function shareFileCounter(shareFiles: any, regex: any) {
      console.log("imomsss", shareFiles);
      if (shareFiles && shareFiles.length > 0) {
        console.log("imom", shareFiles);
        shareFiles.forEach((item: any) => {
          item.documents.forEach((file: any) => {
            if (file.filename) {
              const result = file.filename.match(regex);
              if (result) {
                state.totalSearchResultCount = state.totalSearchResultCount + result.length;

              }
              file.filename = file.filename.replace(regex, '<mark class="highlight">$&</mark>');
            }
          })
        })
      }

    }

    function getCommunicationList(channelID: any) {
      //  if(state.data.lastPage<state.data.page) return false;
      state.item.error.erorList = [];
      state.item.hasError = false;
      if (state.postLoading) return false;
      if (state.ui.isPostLoading.getCommunicationList) return false;

      if (state.data.page > state.data.lastPage) return false;

      if (state.data.page === 0 || !state.data.page) {
        state.data.page = 1;
      }
      if (routerQuery.query.page) {
        const pageNum: any = routerQuery.query.page;
        const currentPage = parseInt(pageNum);
        state.data.scrolledPages = [];
        state.data.scrolledPages.push(currentPage);
      }

      //  state.data.page++;
      // if(routerQuery.query.page){
      // const pageNum: any= routerQuery.query.page;
      // state.data.page = parseInt(pageNum);
      // // payLoad.page = parseInt(pageNum);
      // }
      let page: any = 0;
      if (pageQuery.value != 0) {
        page = pageQuery.value;
      } else {
        page = state.data.page;
      }

      if (state.messageStatus) {
        page = 1;
        state.data.page = 1;
        state.data.scrolledPages = [];
        state.data.scrolledPages.push(page);
      }

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelID,
        page: page,
        search: state.data.search,
        user: localStorage.getItem("current_user_role"),
        // eslint-disable-next-line @typescript-eslint/camelcase
        // communication_id: routerQuery.query.communicationId
        //   ? routerQuery.query.communicationId
        //   : "",
      };
      state.ui.isPostLoading.getCommunicationList = true;
      ethitransService
        .createCommunicationList(payLoad)
        .then(async (res: any) => {
          // alert('hi there' );
          state.data.subscriptionDetails = res.data.data.package_features;
          state.data.currentPage = res.data.data.messages.current_page;
          state.data.lastPage = res.data.data.messages.last_page;
          state.isPublicUser = res.data.data.is_public_user;
          console.log("before", res.data.data.messages.data);

          res.data.data.messages.data =
            await res.data.data.messages.data.reverse();
          console.log("After", res.data.data.messages.data);

          const comID: any = routerQuery.query.communication_id;
          const communicationIdInQuery: any = routerQuery.query.communicationId;

          res.data.data.messages.data.forEach((item: any) => {

            // eslint-disable-next-line @typescript-eslint/camelcase
            if (routerQuery.query.communicationId) {
              state.data.isCommunicationFile =
                routerQuery.query.communicationId;
              // state.data.scrolledPages.push(state.data.page);

              //  document.getElementById(state.data.isCommunicationFile).scrollIntoView();
              //  const element: any= document.getElementById(state.data.isCommunicationFile);
              //  element.scrollIntoView();
            }

            if (parseInt(comID) === item.id) {
              state.data.showReplyCommunication = true;
              replyCommunication(item);

              console.log("message in in communicationList", item);
            }

            if (
              parseInt(communicationIdInQuery) === item.id &&
              routerQuery.query.reply
            ) {
              state.data.showReplyCommunication = true;
              state.data.isCommunicationReplyFile = routerQuery.query.reply;
              replyCommunication(item);

              // console.log("message in in communicationList", item);
            }
            //      if(parseInt(comFIleID) === item.id){
            //     state.data.isCommunicationFile=true;
            //  }
            item.responses.forEach((response: any) => {
              response.createdLatest = common.localDateAndTime(
                response.created_at
              );
            });
            if (item.forward && item.forward.communication && item.forward.communication.responses) {
              item.forward.communication.responses.forEach((response: any) => {
                response.createdLatest = common.localDateAndTime(
                  response.created_at
                );
              });
            }

            item.editedUpdated = common.localDateAndTime(item.updated_at);
            if (item.forward && item.forward.communication) {
              item.forward.communication.editedUpdated = common.localDateAndTime(item.forward.communication.updated_at);
            }
            item.allowEdit = false;
            item.allowDelete = false;
            if (item.share_history && item.share_history.length) {
              item.share_history.forEach((sharedHistory: any) => {
                sharedHistory.documents.forEach((doc: any) => {
                  if (doc.filetype === "mov" || doc.filetype === "MOV") {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    doc.filetype = "mp4";
                  }
                  doc.file.convertedFileSize = common.formatBytes(
                    parseInt(doc.file.filesize)
                  );
                });
              });
            }
          });
          res.data.data.messages.data.forEach((data: any) => {
            // console.log("DATE >>> ", data.sent_date);
            data.currentTime = common.localDateAndTime(data.sent_date);
            data.deletedLocalTime = common.localDateAndTime(data.deleted_at);
            if (data.attachments) {
              data.attachments.forEach((video: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                if (video.file_type === "mov" || video.file_type === "MOV") {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  video.file_type = "mp4";
                }
              });

              // console.log("Data Check >>> ", data)
            }
          });
          console.log("messagecheckbyom >>> ", state.communicationList);

          // res.data.data.messages.data.forEach((data: any) => {
          //   state.communicationList.push(data);
          // });
          // state.data.scrollTop = false;
          //        if(routerQuery.query.page){
          //          res.data.data.messages.data = res.data.data.messages.data.reverse();
          //  state.communicationList = res.data.data.messages.data
          //           ? res.data.data.messages.data
          //           : [];
          //   } else {

          // state.communicationList = state.communicationList.filter((elem, index)=> state.communicationList.findIndex(obj => obj.value === elem.value) === index);
          // if(state.communicationList && state.communicationList.length){
          //   let array: any = [];
          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            //  state.data.scrollTop = false;
          }
          //   state.communicationList = state.communicationList.concat(array);
          // } else {

          //   state.communicationList = res.data.data.messages.data
          //     ? res.data.data.messages.data
          //     : [];
          // }
          // scrollToLast();
          // console.log("CHECK THE RES >>> ", res.data.data)
          // console.log("CHECK THE ROLE >>> ", res.data.data.project_role)
          let admin: any = {};

          if (
            res.data.data.project_role.level === 1 &&
            res.data.data.project_role.code === "owner"
          ) {
            admin = res.data.data.project_role;
          } else {
            if (
              res.data.data.project_role.level === 2 &&
              res.data.data.project_role.code === "co-owner"
            ) {
              admin = res.data.data.project_role;
            }
          }
          const filteredItems = res.data.data.channel.members.filter((mem: any) => mem.user.email === state.email && mem.is_mute == 1);
          console.log('fimt0: ', filteredItems)
          state.isMute = filteredItems.length > 0 ? true : false;

          const channelMember: any = [];
          res.data.data.channel.members.forEach((member: any) => {
            channelMember.push(member.user.full_name);
            member.isChangeRole = false;
            if (admin && admin.level && admin.code) {
              if (member.user && member.user.email) {
                if (member.user.email !== state.email) {
                  member.isChangeRole = true;
                  // console.log("CHECK MEMBER >>> ", member.user);
                }
              }
            }
          });
          // console.log("FINAL MEMBERLIST CHECK", channelMember);
          setMentionMembers(channelMember);
          // setTimeout(store.dispatch("saveActiveMembers", channelMember), 100);

          // saveActiveMembers
          // console.log("Members", res.data.data.channel.members);
          // if (admin && admin.level && admin.code) {
          //   res.data.data.channel.members.forEach((member: any) => {
          //     console.log("Check Member >>> ", member)
          //     console.log("Check LOGIN USER >>> ", admin)
          //     if (admin.level != member.project_role.level) {
          //       if (member.project_role.level > admin.level) {
          //         // console.log("INDIDE ADMIN", admin.level);
          //         // console.log("INDIDE MEMBER", member.project_role.level);
          //         member.isChangeRole = true;
          //       }
          //     }
          //   });
          // }
          state.memberList = res.data.data.channel.members
            ? res.data.data.channel.members
            : [];

          // state.messages = res.data.data
          state.data.projectMember = res.data.data.project_member
            ? res.data.data.project_member
            : {};
          // console.log("Date Validity Check >>> ", state.data.projectMember.validity_date)
          state.channel = res.data.data.channel ? res.data.data.channel : {};
          state.pendingMembersCount = res.data.data.pending_members_count
            ? res.data.data.pending_members_count
            : 0;
          state.approvedMembersCount = res.data.data.approved_members_count
            ? res.data.data.approved_members_count
            : 0;
          state.pendingList = res.data.data.pending_members
            ? res.data.data.pending_members
            : [];
          state.userRole = res.data.data.project_role
            ? res.data.data.project_role
            : {};
          state.projectId = res.data.data.channel.project.uuid
            ? res.data.data.channel.project.uuid
            : "";
          // state.projectId = res.data.data.channel.project_id
          //   ? res.data.data.channel.project_id
          //   : 0;
          res.data.data.messages.data.forEach((item: any) => {
            if (item.form_data != null) {
              // console.log("check msg :>>", item.form_data);
              if (item.form_data && item.form_data.sent_to_users) {
                // console.log( "Type check", item.form_data.sent_to_users)
                if (typeof item.form_data.sent_to_users === "string") {
                  // alert('String')
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  item.form_data.sent_to_users = JSON.parse(
                    item.form_data.sent_to_users
                  );
                }
                // console.log(
                //   "Final :>>",
                //   JSON.parse(item.form_data.sent_to_users)
                // );
                // eslint-disable-next-line @typescript-eslint/camelcase
              }
            }
          });

          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((data: any) => {
              if (data.forward && data.forward.attachments.length) {
                data.forward.attachments.forEach((attachment: any) => {
                  attachment.convertedFileSize = common.formatBytes(
                    parseInt(
                      attachment.file_size
                    )
                  );
                });
              }
            });
          }

          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((data: any) => {
              data.allowEdit = false;
              if (data.attachments && data.attachments.length) {
                data.attachments.forEach((attachment: any) => {
                  attachment.convertedFileSize = common.formatBytes(
                    parseInt(
                      attachment.file_size
                      // parseInt(data.file_size.split(" ")[0])
                    )
                  );
                });
              }
              const result: any = [];
              const map = new Map();
              data.responses.forEach((item: any) => {
                if (!map.has(item.response_by_id)) {
                  map.set(item.response_by_id, true); // set any value to Map
                  result.push(item);
                }
              });
              data.editStatus = result;

              // console.log("FINAL CHECK", result)
              // for (const item of array) {

              // }
              //             data.uniqueResponse = data.responses.map((item: any) => {item.response_by_id})
              // .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
              // data.responses.forEach((item: any) => {
              //   item.uniqueUsers = [];
              //   item.response_by

              // })
            });
          }
          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((item: any) => {
              if (item.user && item.user.email) {
                if (
                  item.user.email === localStorage.getItem("current_user_email")
                ) {
                  // console.log("email", item.user.email);
                  // console.log("active email", state.data.activeUserEmail);
                  item.editStatus = true;
                } else {
                  item.editStatus = false;
                }
              }
            });
          }

          state.totalSearchResultCount = 0
          res.data.data.messages.data.forEach(async (item: any) => {
            const searchText = state.data.search;
            const regex = new RegExp(searchText, 'gi');
            if (state.data.search && item.attachments.length && item.message) {

              item.attachments.forEach((item2: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                const result0 = item2.file_name.match(regex);
                console.log("this is result 0", result0);
                if (result0) {
                  state.totalSearchResultCount = state.totalSearchResultCount + result0.length;

                }
                // eslint-disable-next-line @typescript-eslint/camelcase
                item2.file_name = item2.file_name.replace(regex, '<mark class="highlight">$&</mark>');
                if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                  item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
                }
              })
              const result1 = item.message.match(regex);
              if (result1) {
                state.totalSearchResultCount = state.totalSearchResultCount + result1.length;
              }
              item.message = item.message.replace(regex, '<mark class="highlight">$&</mark>');
              if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
              }
              console.log("fow", item.forward.communication.message)
              await replyCounter(item.responses, regex);
              // eslint-disable-next-line @typescript-eslint/camelcase
              await shareFileCounter(item.share_history, regex);

            }

            if (state.data.search && item.attachments.length) {
              item.attachments.forEach((item2: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                const result2 = item2.file_name.match(regex);
                if (result2) {
                  state.totalSearchResultCount = state.totalSearchResultCount + result2.length;
                }
                // eslint-disable-next-line @typescript-eslint/camelcase
                item2.file_name = item2.file_name.replace(regex, '<mark class="highlight">$&</mark>');
                if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                  item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
                }
              })

              await replyCounter(item.responses, regex);
              // eslint-disable-next-line @typescript-eslint/camelcase
              await shareFileCounter(item.share_history, regex);

            }

            if (state.data.search && !item.attachments.length) {
              const result3 = item.message && item.message.match(regex) || item.forward.communication.message.match(regex);
              if (result3) {
                state.totalSearchResultCount = state.totalSearchResultCount + result3.length;
                // item.message = item.message.replace(/(<mark class="highlight">|<\/mark>)/gim, '');
                item.message = item.message.replace(regex, '<mark class="highlight">$&</mark>');
                if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                  item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
                }

              }

              await replyCounter(item.responses, regex);
              // eslint-disable-next-line @typescript-eslint/camelcase
              await shareFileCounter(item.share_history, regex);

            }

          });
          console.log("Message match count >>>", state.totalSearchResultCount);

          // res.data.data.messages.data.forEach((item: any) => {
          //   const searchText = state.data.search;
          //   const regex = new RegExp(searchText, 'gi');
          //    if(state.data.search && item.responses.length){
          //     item.responses.forEach((item2: any) => {
          //       // eslint-disable-next-line @typescript-eslint/camelcase
          //       const result77 = item2.message.match(regex);
          //       console.log("respose0", result77.length);
          //       if(result77){
          //         state.totalSearchResultCount = state.totalSearchResultCount + result77.length;

          //       }
          //       // eslint-disable-next-line @typescript-eslint/camelcase
          //       // item2.file_name = item2.file_name.replace(regex, '<mark class="highlight">$&</mark>');
          //     })
          //   }

          // })

          // state.communicationList = res.data.data.messages
          //   ? res.data.data.messages
          //   : [];
          state.ownerId = res.data.data.owner.user_id;
          state.owner = res.data.data.channel;
          // state.ui.isPostLoading.getCommunicationList = false;
          // state.communicationList = res.data.data.messages;

          // console.log("CHAT COMM >>>", state.communicationList);
          // if (state.communicationList) {
          //   state.communicationList.forEach((item: any, index: any) => {
          //     if (state.communicationList.length - 1 === index) {
          //       // scrollToLast();
          //       //        const container: any = $('chat-body-content').get(0);
          //       // container.scrollTop = (container.scrollHeight + container.offsetHeight);
          //       // scrollAtButton();
          //     }
          //   });
          // }

          // $("#chat-body-content").animate(
          //   { scrollTop: $("#chat-body-content")[0].scrollHeight },
          //   500
          // );
          // scrollToLast();
          // getAccountFormList();
          // if(state.data.scrolledPages.includes(state.data.page)) return false;
          //  state.communicationList = [];
          console.log(
            "state.data.scrollTop && !state.data.scrollBottom",
            state.data.scrollTop,
            state.data.scrollBottom
          );
          if (pageQuery.value === 0) {


            if (!state.data.scrollTop && !state.data.scrollBottom) {
              console.log("image6 ");

              state.communicationList = res.data.data.messages.data
                ? res.data.data.messages.data
                : [];
            } else {
              if (state.data.scrollTop) {
                console.log("image5 ");
                // state.data.scrollTop = false;
                state.data.scrollBottom = false;
                // res.data.data.messages.data = await res.data.data.messages.data.reverse();
                state.communicationList =
                  await res.data.data.messages.data.concat(
                    state.communicationList
                  );
                state.communicationList.sort(
                  (aMessages: any, bMessage: any) => aMessages.id - bMessage.id
                );

                // res.data.data.messages.data.forEach(async (data: any) => {
                //   await state.communicationList.unshift(data);
                // });
              }

              if (state.data.scrollBottom && !state.isReplyMessage) {
                console.log("image4 ");
                state.data.scrollTop = false;
                setTimeout(() => {
                  res.data.data.messages.data.forEach(async (data: any) => {
                    await state.communicationList.push(data);
                  });
                }, 20);
              }
            }
            if ((state.isReplyMessage && state.communicationList.length == 0) || (state.data.search.length == 0 && state.communicationList.length == 0)) {
              state.communicationList = res.data.data.messages.data
                ? res.data.data.messages.data
                : [];  //add to serach reply data
            }
          } else if (
            pageQuery.value != 0 &&
            !state.data.scrollTop &&
            !state.data.scrollBottom
          ) {
            console.log("image3 ");

            // res.data.data.messages.data = res.data.data.messages.data.reverse();
            state.communicationList = res.data.data.messages.data
              ? res.data.data.messages.data
              : [];
            // res.data.data.messages.data.forEach((data: any) => {
            //   state.communicationList.unshift(data);
            // });
          } else {
            if (state.data.scrollTop) {
              console.log("image2 ");

              // state.data.scrollTop = false;
              state.communicationList = [];
              // res.data.data.messages.data = res.data.data.messages.data.reverse();
              res.data.data.messages.data.forEach((data: any) => {
                state.communicationList.unshift(data);
              });
            }
            if (state.data.scrollBottom) {
              console.log("image1 ");
              // state.data.scrollTop = false;
              res.data.data.messages.data =
                res.data.data.messages.data.reverse();
              res.data.data.messages.data.forEach((data: any) => {
                state.communicationList.push(data);
              });
            }
          }

          // }
          state.communicationList = state.communicationList.filter(
            (value: any, index: any, self: any) =>
              self.findIndex((v: any) => v.id === value.id) === index
          );
          state.data.approvalDiscussionInvitationId = res.data.data.payer_request_id ? res.data.data.payer_request_id : '';
          getUserList();
          state.ui.isLoading.communicationListAPICall = true;
          // setTimeout(toggleTourGuide, 1000);
          state.data.scrollTop = false;
          state.data.scrollBottom = false;
          // console.log("FINAL CHECK COLLECTION", arrayUniqueByKey.length)
        })
        .catch((error: any) => {
          state.data.scrollTop = false;
          console.log("message", error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // state.data.scrollTop = false;
          // alert("LAST")
          state.ui.isPostLoading.getCommunicationList = false;
          // eslint-disable-next-line @typescript-eslint/camelcase
          //  if(state.isReplyMessage) return ;
          console.log("state.messageStatus", state.messageStatus);
          console.log("messge.reply ", state.communicationList);
          if (!state.firstScroll) {
            scrollToLast();
          } else {
            // alert("RESET")
            state.data.scrollTop = false;
            if (!state.messageStatus) {
              scrollToTop();
            } else {
              scrollToLast();
            }
          }
        });
    }

    function searchAllMessage() {
      if (routerQuery.query.communication_id) {
        if (state.data.search.length == 0) {
          state.totalSearchResult = false
          state.communicationList = [];
          getCommunicationList(channelId);
        } else {
          state.totalSearchResult = true
          state.communicationList = [];
          getCommunicationList(channelId);
        }

      } else {
        state.communicationList = [];
        getCommunicationList(channelId);
        if (state.data.search.length == 0) {
          state.totalSearchResult = false
        } else {
          state.totalSearchResult = true
        }
      }


    }
    function searchClearMessage() {
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (routerQuery.query.communication_id) {
        state.data.search = "";
        state.communicationList = [];
        getCommunicationList(channelId);
        state.data.clearBtn = false;
        if (state.data.search.length == 0) {
          state.totalSearchResult = false
        } else {
          state.totalSearchResult = true
        }
      } else {
        console.log("im form outthred");
        state.data.search = "";
        state.data.clearBtn = false;
        if (state.data.search.length == 0) {
          state.totalSearchResult = false
        } else {
          state.totalSearchResult = true
        }
        // state.isReplyMessage = false;
        state.communicationList = [];
        getCommunicationList(channelId);
      }

    }
    // function setRouteQueryPage(){
    //   if(routerQuery.query.page){
    //     const pageNum: any = routerQuery.query.page;
    //   console.log("HELLO", pageNum)
    //   // state.data.page = parseInt(pageNum);
    //   }
    // }
    // Pusher.logToConsole = true;
    function addMember(member: any) {
      const p = $("<a />", {
        text: member.info.id,
        id: "member_" + member.id,
      });
      $("#members").append(p);
    }
    function removeMember(member: any) {
      $("#member_" + member.id).remove();
    }

    const pusher = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, {
      cluster: "ap2",
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const pusherConnectionCheck = computed(() => {
      return pusher.connection.state;
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const scrollCheckChat = computed(() => {
      // document.getElementById("chat-body-content").addEventListener("scroll", alert("HELLO"));
      return false;
    });
    const token = localStorage.getItem("current_user_token");
    let newToken = "";
    if (token) {
      newToken = token.replace(/"/gi, "");
    }
    const privatePusher = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, {
      cluster: "ap2",
      authEndpoint: `${config.privateChannel}broadcasting/auth`,
      auth: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + newToken,
        },
      },
    });
    // console.log("CHECK >>>", pusher.connection.state)
    const channel = privatePusher.subscribe(`private-channelCommunication.${channelId}`);
    // console.log("Channel Communication>>> ", channel);

    channel.bind("channelCommunication.created", function (data: any) {
      if (JSON.stringify(data)) {
        // console.log("PUSHER CHECK CHANNEL", data);
        // console.log('CHANNEL', data.chat);
        // state.data.page = 0;
        getInstanceMessageList(data.id);
        console.log("p7", data);
        //comment by sundar and om
        // getCommunicationList(channelId);
      }
    });
    // channel.bind("pusher:member_added", (member: any) => {
    //   // For example
    //   add_member(member.id, member.info);

    // });

    // const userInfo: any = localStorage.getItem("current_user_info")
    // const userDetails: any = JSON.parse(userInfo)
    // const pusherPresence = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, { authEndpoint: "/pusher_auth.php",
    //   cluster: "ap2",
    // });
    const presenseChannel = pusher.subscribe(
      `presence-userOnline.${channelId}`
    );
    // const presenseChannel = pusher.subscribe(`private-channelCommunication.${channelId}`);
    // const presenseChannel = pusher.subscribe(`presence-user-online.${channelId}`);
    // console.log("CHECK FOR THE PRESENSE >>>", presenseChannel);
    // const presenseChannel: any = pusher.subscribe(`presence-channelCommunication.${channelId}`);
    // const count = presenseChannel.members.count;
    // console.log("Check Count>> ", count)
    //   presenseChannel.bind("pusher:subscription_succeeded", (members: any) => {
    //     // console.log("MAIN MEMBER >>>", members)
    //     // For example
    //     // update_member_count(members.count);

    //     members.each((member: any) => {
    //       console.log("Pusher Member Check >>> ", member)
    //        addMember(member);
    //       // For example
    //       // add_member(member.id, member.info);
    //     });
    //   });
    // presenseChannel.bind("pusher:member_added", function(member: any){
    //   addMember(member);
    // });

    // presenseChannel.bind("pusher:member_removed", function(member: any){
    //   removeMember(member)
    // });

    const userTyping = pusher.subscribe(`userTyping.${channelId}`);
    userTyping.bind("userTyping", function (data: any) {
      console.log("dataset", data);
      if (state.data.userFullName !== data.username) {
        state.data.userTyping = data.username;
      }
      setTimeout(function () {
        //clear user is typing message
        state.data.userTyping = "";
      }, 1000);
      // console.log('PUSHER CHECK TYPING...', data.username);
      // if(state.data.userFullName != data.username){
      // state.data.userTypingStatus = true;

      // state.data.userTyping = ''
      // console.log('PUSHER CHECK TYPING...', state.data.userTyping);
      // state.data.userTypingStatus = false;
      // }

      // if (JSON.stringify(data)) {
      //   // console.log('CHANNEL', data.chat);
      //   getCommunicationList();
      // }
    });
    // channel.bind(userIsTypingEvent, function(data: any) {
    // }

    // const channelCommunicationAttachment = pusher.subscribe(`channelCommunicationAttachment.${attachmentId}`);
    // channelCommunicationAttachment.bind("channelCommunicationAttahcment.created", function(data: any) {
    //   // console.log('PUSHER CHECK', data);

    //   if (JSON.stringify(data)) {
    //     // console.log('PUSHER CHECK 1', data.chat);
    //     getCommunicationList();
    //   }
    // });

    function communicationTyping() {
      state.ui.isPostLoading.channelCommunicationTyping = false;
    }

    function channelCommunicationTyping() {
      if (
        !state.data.userUUID ||
        !channelId ||
        state.ui.isPostLoading.channelCommunicationTyping
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_id: state.data.userUUID,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
      };
      state.ui.isPostLoading.channelCommunicationTyping = true;
      ethitransService
        .channelCommunicationTyping(payLoad)
        .then((res: any) => {
          // console.log("HELLOOO", res.data.data);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          setTimeout(communicationTyping, 300);
          // state.ui.isPostLoading.channelCommunicationTyping = false;
        });
    }
    watch(
      () => state.message,
      () => {
        if (!state.message) return false;
        console.log("Typing");
        channelCommunicationTyping();
        // state.existingMember = "";
        // state.invite.users = [];
      }
    );
    function clientDocumentRetrieveWorkflow(
      clientDocumentId: any,
      selectedChannelId: any
    ) {
      if (!clientDocumentId || !selectedChannelId) return false;
      const payLoad = {
        id: clientDocumentId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        account_form_id: selectedChannelId,
      };
      state.ui.isLoading.clientDocumentRetrieveWorkflow = true;
      ethitransService
        .clientDocumentRetrieveWorkflowLink(payLoad)
        .then((res: any) => {
          console.log("HELLOOO");
          // console.log("clientDocumentRetrieveWorkflow :>>> ", res.data.data.docId);
          // accountFormRetrieve(res.data.data.docId);
          state.data.clientDocumentRetrieveWorkflowLink = res.data.data
            ? res.data.data
            : {};
          // $('.nav-tabs a[href="#profile"]').tab('show')
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.clientDocumentRetrieveWorkflow = false;
        });
    }
    function getAccountFormResponseByEmailAndId(user: any, index: any) {
      state.item.selectedUserFormDetails = {};
      state.selectedUser = user;
      console.log("Index", state.activeUserIndex);
      state.activeUserIndex = index;
      const email = user.email;
      console.log("Index", index);
      if (!email || !state.item.formId) return false;

      // console.log("CHECK the Form ID", state.item.formId);
      // console.log("CHECK Email Id", email);
      state.ui.isLoading.accountFormResponseByEmailAndId = true;
      ethitransService
        .accountFormResponseByEmailAndId(email, state.item.formId)
        .then((res: any) => {
          console.log(
            "getAccountFormResponseByEmailAndId Response Check:>>> ",
            res.data
          );
          // if(state.data.selectedFormData && !res.data){
          clientDocumentRetrieveWorkflow(
            state.data.selectedFormData.client_document_id,
            state.formData._id
          );
          // }
          state.item.selectedUserFormDetails = res.data ? res.data : {};
          state.currentSendUser = true;
          if (state.formData.edoc_id) {
            console.log("p8");
            getCommunicationList(channelId);
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.accountFormResponseByEmailAndId = false;
        });
    }

    function selectedUserAccountFormResponse() {
      let payLoad = {};
      state.userFormData.forEach((item: any) => {
        if (item.type === "digitalSignature") {
          item.signatureBase64 = item.signaturePad.toDataURL();
        }
      });
      const dt = new Date();
      if (state.formData.edoc_id) {
        payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          account_form_id: state.formData._id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          edoc_id: state.formData.edoc_id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          edoc_pdf_path: state.edocPdfPath,
          // eslint-disable-next-line @typescript-eslint/camelcase
          filled_by: {
            name: localStorage.getItem("current_user_email"),
            email: state.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_date:
            dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate(),
          elements: state.userFormData,
        };
      } else {
        payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          account_form_id: state.formData._id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          filled_by: {
            name: localStorage.getItem("current_user_email"),
            email: state.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_date:
            dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate(),
          elements: state.userFormData,
        };
      }
      // console.log(
      //   "<<< FINAL FINAL >>>selectedUserAccountFormResponse PAYLOAD CHECK ",
      //   payLoad
      // );
      state.ui.isPostLoading.selectedUserAccountFormResponse = true;
      ethitransService
        .accountformResponseSave(payLoad)
        .then((res: any) => {
          console.log(
            "selectedUserAccountFormResponse Response Check:>>> ",
            res.data
          );
          // state.ui.template.response = res.data;
          $("#responseModal").modal("hide");
          getAccountFormResponseByEmailAndId(
            state.selectedUser,
            state.activeUserIndex
          );
          // $("#formview").modal("hide");
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.selectedUserAccountFormResponse = false;
        });
    }
    function generateEdocStatement() {
      const fileName = common.removeWhiteSpace(state.formData.title);
      let metaData = "";
      const metaDataMapped = [] as any;
      state.userFormData.forEach((element: any) => {
        const optionsCollection: any = [];
        let obj = {} as any;
        if (element.type === "textbox" || element.type === "textArea") {
          // console.log("Elements check text box", element)
          obj = {};
          obj[element.metaData] = element.answer;
          metaDataMapped.push(obj);
        } else if (element.type === "single_select") {
          if (element.options && element.options.length) {
            // console.log("Elements options", element.options)
            element.options.forEach((meta: any) => {
              console.log("meta", meta);
              if (meta.selected) {
                obj = {};
                obj[meta.metaData] = `${meta.selected}`;
                metaDataMapped.push(obj);
              }
            });
          }
        } else if (element.type === "radioButton") {
          if (element.options && element.options.length) {
            // console.log("Elements options", element.options)
            element.options.forEach((meta: any) => {
              if (meta.selected) {
                console.log("meta", meta);
                obj = {};
                obj[meta.metaData] = `${meta.selected.toLowerCase()}`;
                metaDataMapped.push(obj);
              }
            });
          }
        } else if (element.type === "digitalSignature") {
          // if (element.options && element.options.length) {
          console.log("Elements Digital Signature", element);
          obj = {};
          obj[element.metaData] = element.signaturePad.toDataURL();
          console.log("CHECK SIGNATURE", element.signaturePad.toDataURL());
          metaDataMapped.push(obj);
          // }
        } else if (element.type === "datePicker") {
          // if (element.options && element.options.length) {
          console.log("Elements Digital Signature", element);
          obj = {};
          obj[element.metaData] = element.convertedDate;
          metaDataMapped.push(obj);
          // }
        }
        // else {
        //   if (element.options && element.options.length) {
        //     metaData = element.options[0].metaData;
        //     element.options.forEach((meta: any, index: number) => {
        //       // console.log("Elements Meta", meta);
        //       if (meta.selected === true) {
        //         const object  = {
        //           option: '',
        //           selected: false,
        //         };
        //         object.option = meta.option;
        //         object.selected = meta.selected;

        //         optionsCollection.push(object)

        //       }
        //     });

        //           obj = {};
        //           obj[metaData] = '';
        //           metaDataMapped.push(obj);
        //     // console.log("Collection CHECK>>>", optionsCollection)
        //   }
        //   // obj[element.metaData] = "";
        //   // metaDataMapped.push(obj);
        // }
      });
      // console.log("FINAL >>>", metaDataMapped)
      const map = JSON.stringify(metaDataMapped);
      // eslint-disable-next-line no-useless-escape
      const objFromMap = map.replace(/[\[\]']+/g, "");
      // // eslint-disable-next-line no-useless-escape
      const obje = `{${objFromMap.replace(/[{}]/g, "")}}`;
      // console.log("FINAL CHECK OBJECT >>>", obje)
      // console.log("JSON COLLECTION >>>", [JSON.parse(obje)])
      const formDataJson = [JSON.parse(obje)];
      state.userFormData.forEach((element: any) => {
        const optionsCollection: any = [];
        let obj = {} as any;
        if (element.type === "checkBox") {
          if (element.options && element.options.length) {
            metaData = element.options[0].metaData;
            element.options.forEach((meta: any, index: number) => {
              // console.log("Elements Meta", meta);
              if (meta.selected === true) {
                const object = {
                  option: "",
                  selected: false,
                };
                object.option = `${meta.option.toLowerCase()}`;
                // object.option = meta.option ;
                object.selected = meta.selected;

                optionsCollection.push(object);
              }
            });

            obj = {};
            formDataJson[0][`${metaData}`] = optionsCollection;
            // metaDataMapped.push(obj);
            // console.log("Collection CHECK>>>", optionsCollection)
          }
        }
      });
      // console.log("PAYLOAD CHECK : generateEdocStatement", JSON.parse(obje));
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        document_id: state.formData.edoc_id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        document_title: state.formData.title,
        // formdata: [JSON.parse(obje)],
        formdata: formDataJson,
        saveIndividualFileAs: `${fileName + "-" + common.generateUUID()}.pdf`,
      };
      // console.log("PAYLOAD CHECK : generateEdocStatement", payLoad);
      state.ui.isPostLoading.selectedUserAccountFormResponse = true;
      // state.ui.isPostLoading.generateEdocStatement = true;
      ethitransService
        .generateEdocStatement(payLoad)
        .then((res: any) => {
          if (res.data.data) {
            // console.log(
            //   "generateEdocStatement Response Check:>>> ",
            //   res.data.data
            // );
            state.edocPdfPath = res.data.data;
            selectedUserAccountFormResponse();
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.selectedUserAccountFormResponse = false;
          // state.ui.isPostLoading.generateEdocStatement = false;
        });
    }
    function userformInputValidationCheck() {
      state.item.error.validationList = [];
      state.item.hasError = false;
      state.userFormData.forEach((data: any) => {
        // console.log("CHECK ::: >>> ", data)
        if (data.required) {
          if (data.type === "textbox" || data.type === "textArea") {
            if (!data.answer) {
              data.hasError = data.title;
              state.item.error.validationList.push(
                `${data.title} is required.`
              );
            } else {
              data.hasError = "";
            }
          } else if (data.type === "checkBox" || data.type === "radioButton") {
            if (data.options && data.options.length) {
              // console.log("USER's DATA FILL CHECK >>> ", data)
              const optionList: any = [];
              data.options.forEach((item: any) => {
                // if(item.selected) return false;
                if (item.selected) {
                  optionList.push(item);
                }
              });
              // console.log("CHECK CHECK >>", optionList)
              if (optionList && !optionList.length) {
                data.hasError = data.title;
                state.item.error.validationList.push(
                  `${data.title} is required.`
                );
              } else {
                data.hasError = "";
              }
            }
          } else if (data.type === "single_select") {
            // alert()
            if (data.options && data.options.length) {
              // console.log("USER's DATA FILL CHECK >>> ", data)
              if (!data.selected) {
                data.hasError = data.title;
                state.item.error.validationList.push(
                  `${data.title} is required.`
                );
              } else {
                data.hasError = "";
              }
            }
          } else if (data.type === "datePicker") {
            // alert()
            // if(data.options && data.options.length) {
            // console.log("USER's DATA FILL CHECK >>> ", data)
            if (!data.date || !data.convertedDate) {
              data.hasError = data.title;
              state.item.error.validationList.push(
                `${data.title} is required.`
              );
            } else {
              data.hasError = "";
            }
            // }
          }
        }
      });
      console.log("VALIDATION CHECK >>> ", state.item.error.validationList);
      if (
        !state.item.error.validationList &&
        !state.item.error.validationList.length
      ) {
        state.item.hasError = false;
      } else if (state.item.error.validationList.length != 0) {
        state.item.hasError = true;
      }
    }
    function submitUserInputForm() {
      userformInputValidationCheck();
      state.data.startValidation = true;

      if (
        (state.item.error.validationList &&
          state.item.error.validationList.length) ||
        state.item.hasError
      )
        return false;
      if (state.formData.edoc_id) {
        generateEdocStatement();
      } else {
        selectedUserAccountFormResponse();
      }
    }
    // function generateEdocStatement() {
    //   if (state.ui.isPostLoading.generateEdocStatement) return false;
    //   // console.log("After", state.formData);
    //   const fileName = common.removeWhiteSpace(state.formData.title)
    //   const metaDataMapped = [] as any
    //   state.formData.elements.forEach((element: any) => {
    //     let obj = {} as any
    //     if (element.type === "textbox" || element.type === "textArea") {
    //       // console.log("Elements check text box", element)
    //       obj = {};
    //       obj[element.metaData] = element.answer;
    //       metaDataMapped.push(obj);
    //     } else {
    //       if(element.options && element.options.length){
    //           console.log("Elements options", element.options)
    //           element.options.forEach((meta: any) => {
    //             obj = {};
    //             obj[meta.metaData] = `${meta.selected}`;
    //             metaDataMapped.push(obj);
    //           })
    //         }
    //       // obj[element.metaData] = "";
    //       // metaDataMapped.push(obj);
    //     }
    //   })
    //   const map = JSON.stringify( metaDataMapped );
    //   const objFromMap = map.replace(/[\[\]']+/g,'');
    //   const obje = `{${objFromMap.replace(/[{}]/g, "")}}`;
    //   // console.log("PAYLOAD CHECK : generateEdocStatement", JSON.parse(obje));
    //   const payLoad = {
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     document_id: state.formData.edoc_id,
    //     formdata: [JSON.parse(obje)],
    //     saveIndividualFileAs :`${fileName}.pdf`
    //   };
    //   console.log("PAYLOAD CHECK : generateEdocStatement", payLoad);
    //   state.ui.isPostLoading.generateEdocStatement = true;
    //   ethitransService
    //     .generateEdocStatement(payLoad)
    //     .then((res: any) => {
    //       console.log("generateEdocStatement Response Check:>>> ", res);
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //     })
    //     // .finally(() => {
    //     //   state.ui.isPostLoading.generateEdocStatement = false;
    //     // });
    // }

    function hideFormView() {
      $("#formview").modal("hide");
    }
    function showWorkFlowLink(data: any) {
      // console.log("LINK CHECK", data.workflowLink);
      window.open(data.workflowLink);
      hideFormView();
    }
    function accountFormRetrieve(formId: any) {
      state.formData = {};
      state.ui.isPostLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          // state.formResponse = res.data
          console.log("HELLO FROM COMMUNICATION Response Check:>>> ", res.data);
          state.formData = res.data;
          if (state.formData.sentToUsers && state.formData.sentToUsers.length) {
            getAccountFormResponseByEmailAndId(
              state.formData.sentToUsers[state.activeUserIndex],
              0
            );
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getAccountFormRetrieveById = false;
        });
    }
    function showPreviewLink(data: any) {
      window.open(data);
    }

    function showFormView(data: any) {
      state.item.selectedUserFormDetails = {};
      state.formData = {};
      state.data.selectedFormData = {};
      const formData = data.form_data;
      state.data.clientDocumentRetrieveWorkflowLink = {};
      state.item.selectedUserFormDetails = {};
      state.data.selectedFormData = {};
      // console.log("CHECK THE FORM ;HEREE:::::", data.user);
      state.data.msgByUser = data.user;
      if (formData.client_document_id) {
        // console.log("WOWOWO ID:>>>");
        // console.log("Get Account form Response:>>>", formData);
        // console.log("Get Account DOC ID:>>>", formData.client_document_id);
        state.item.formId = formData.form_id;
        // $('.nav-tabs a[href="#profile"]').tab('show')
        if (formData.client_document_id) {
          // alert("HELL")
          state.data.selectedFormData = formData;
          // clientDocumentRetrieveWorkflow(state.data.selectedFormData.client_document_id, state.formData._id);
          accountFormRetrieve(formData.form_id);
        }
        // alert('DIGITIZERD');
        // clientDocumentRetrieveWorkflow(formData.client_document_id, channelId);
        $("#formview").modal("show");
        // alert("IF");
      } else if (formData.form_id) {
        // alert("ELSE");
        // alert('NORMAL');
        state.item.formId = formData.form_id;
        accountFormRetrieve(formData.form_id);

        $("#formview").modal("show");
      }
    }
    function hideTemplateModal() {
      $("#exampleModal").modal("hide");
    }
    function hideEditTemplateModal() {
      $("#editExampleModal").modal("hide");
    }
    function showEditTemplateModal() {
      $("#editExampleModal").modal("show");
    }
    function selectEditForm(form: any) {
      state.invite.users = [];
      state.ui.template.msg = "";
      state.newFormData.privacy.code = "channel";
      state.newFormData.privacy.value = "private to this channel";
      $(".modal-backdrop").remove();
      $("#createnewformModal").modal("show");
      router.push({
        name: "admin-channel-create-form",
        query: { formId: form._id, mode: "edit" },
      });
      $(".modal-backdrop").remove();
      // getAccountFormRetrieveById(form._id);
      // console.log("Selected FOR FOR EDIT:>>", state.selectedFormForEdit.id);
    }
    function selectForm(form: any) {
      state.newFormData.privacy.code = "channel";
      state.newFormData.privacy.value = "private to this channel";
      $(".modal-backdrop").remove();
      // $("#exampleModal").modal("show");
      // console.log("Selected Form:>>", form);
      router.push({
        name: "admin-channel-create-form",
        query: { formId: form._id, mode: "send" },
      });
      // getAccountFormRetrieveByIdForSend(form._id);
    }

    function getAccountFormList() {
      const payLoad = {
        search: {
          // project_id: '278241ec-7e7f-468f-a17b-9504773225c0',
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_name: state.searchBy,
        },
        filter: {
          type: state.filter.type,
          email: state.email,
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_by: {
          title: 1,
        },
        paging: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          per_page: 1000,
          // eslint-disable-next-line @typescript-eslint/camelcase
          current_page: 1,
        },
      };
      state.ui.isLoading.getAccountFormList = true;
      ethitransService
        .channelAccountFormListSearch(payLoad)
        // .projectAccountFormListSearch(payLoad)
        .then((res: any) => {
          console.log("sharedFormList Response Check:>>> ", res.data);
          state.sharedFormList = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.getAccountFormList = false;
        });
    }
    function addNewMemberToChannel() {
      console.log("CHECK the daya >>> ", typeof state.approvedMembersCount);
      console.log("CHECK the main >>> ", typeof state.data.subscriptionDetails.total_channel_member);

      if (state.approvedMembersCount >= state.data.subscriptionDetails.total_channel_member) {
        // if (1 === state.data.subscriptionDetails.total_channel_member) {

        state.ui.isActive.upgradeSubscription = true;
      } else {
        state.data.invitationVerifyMsg = "";
        state.data.invitationVerify.newMemberList = [];
        state.data.invitationVerify.registeredMemberList = [];
        state.data.invitationVerify.pendingMemberList = [];
        state.data.invitationVerify.showStatus = false;
        state.data.inviteAsGuest = false;
        getUserList();
        $("#successModal").modal("hide");
        $("#inviteMember").modal("show");
        state.data.invitationVerifyMsg = "";
        state.inviteMember = "";
        state.invite.users = [];
        state.ui.template.msg = "";
        state.invite.users = [];
        state.invite.invites = [];
        state.invite.filteredUsers = [];
        state.invite.filteredInvites = [];
      }
    }
    function hideAddMember() {
      $("#inviteMember").modal("hide");
      state.errorList = [];
      state.data.inviteAsPublicUser = false;

    }
    function changeExistingMember() {
      // // console.log("CHECK MSG", state.existingMember);
      // state.invite.users.push(state.existingMember);
      // // console.log("CHECK MSG AFTER", state.invite.users);
      // state.existingMember = "";
      if (state.invite.users.indexOf(state.existingMember) === -1) {
        state.invite.users.push(state.existingMember);
        // console.log(state.invite.users);
      }
      state.userList = state.userList.filter(
        (e: any) => e != state.existingMember
      );
      state.existingMember = {};
    }
    function changeExistingMemberInForm() {
      if (state.invite.users.indexOf(state.existingMember) === -1) {
        state.invite.users.push(state.existingMember);
        // console.log(state.invite.users);
      }
      state.userList = state.userList.filter(
        (e: any) => e.email != state.existingMember.email
      );
      state.existingMember = {};
    }
    function changeExistingMemberInSendOption() {
      if (state.invite.users.indexOf(state.existingMember) === -1) {
        state.invite.users.push(state.existingMember);
        // console.log(state.invite.users);
      }
      state.userList = state.userList.filter(
        (e: any) => e != state.existingMember
      );
      state.existingMember = {};
    }
    function removeSelectedMember(item: any) {
      // console.log("check the sELCTIOn", item);
      state.invite.users = state.invite.users.filter((e: any) => e != item);
      allUsers.value.push(item);
      state.userList.push(item);
      // console.log("AFTER DELECTION", state.invite.users);
    }

    function changeInviteMember() {
      state.inputError = false;
      state.duplicate = false;
      state.invite.invites.forEach((invite) => {
        if (invite.first_name == '') {
          invite.fNameError = true;
        } else {
          invite.fNameError = false;
        }
        if (invite.last_name == '') {
          invite.lNameError = true;
        } else {
          invite.lNameError = false;
        }
      })
      state.inputError = state.invite.invites.some((item: any) => item.first_name == '' || item.last_name == '');
      state.duplicate = state.invite.invites.some((item: any) => item.email === state.inviteMember);
      if (state.inputError || state.duplicate) return;
      // console.log("CHECK MSG", state.inviteMember);

      if (validationService.isValidEmail(state.inviteMember)) {
        // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (state.invite.invites.length === 0) {
          state.invite.invites.push({
            'email': state.inviteMember,
            'first_name': '',
            'last_name': '',
          });
        } else {
          if (state.invite.invites.filter((item: any) => item.email != state.inviteMember)) {
            state.invite.invites.push({
              'email': state.inviteMember,
              'first_name': '',
              'last_name': '',
            });
          }

        }
        state.invite.invites = state.invite.invites.filter(
          (item, i, ar) => ar.indexOf(item) === i
        );
        state.inviteMember = "";
      }
      // console.log("CHECK MSG AFTER", state.invite.invites);
    }
    function removeSelectedUser(item: any) {
      console.log("check the sELCTIOn", item);
      state.invite.invites = state.invite.invites.filter((e: any) => e != item);
      console.log("AFTER DELECTION", state.invite.invites);
    }
    function downloadFiles() {
      alert("Just only functoin of Download Files");
    }
    function showSuccessModal() {
      $("#successModal").modal("show");
    }
    function removeSelectedImage(image: any) {
      // if (state.postLoading) return false;
      state.imageList = state.imageList.filter((e: any) => e != image);
    }

    function removeSelectedThreadImage(image: any) {
      // if (state.postLoading) return false;
      state.threadImageList = state.threadImageList.filter((e: any) => e != image);
    }
    function validateInviteForm() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (!state.invite.users.length && !state.invite.filteredInvites.length) {
        state.errorList.push("Invite member is required.");
      }
      if (state.inviteMember) {
        if (!validationService.isValidEmail(state.inviteMember)) {
          state.errorList.push("Invalid email.");
        }
      }

      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }

      console.log("CHECK BOOLEAN", state.isErrorStatus);
    }
    function inviteAllUsers() {
      state.errorList = [];
      state.sendChannelId.push(channelId);
      state.isErrorStatus = false;
      state.invite.filteredUsers = [];
      state.invite.filteredInvites = [];
      state.invite.users.forEach((item: any) => {
        state.invite.filteredUsers.push(item.email);
      });
      state.invite.invites.forEach((item: any) => {
        // console.log("CHECK email", item);
        state.invite.filteredInvites.push(item);
      });
      if (state.inviteMember && state.invite.invites.length === 0) {
        if (validationService.isValidEmail(state.inviteMember)) {
          // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
          state.invite.filteredInvites.push(state.inviteMember);
        }
      }
      validateInviteForm();
      if (
        state.errorList.length != 0 ||
        state.isErrorStatus ||
        state.isLoading.inviteAllUsers
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.channel.project.uuid,
        // eslint-disable-next-line @typescript-eslint/camelcase
        invitation_type: "channel_invitation",
        // eslint-disable-next-line @typescript-eslint/camelcase
        channels: state.sendChannelId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_type: state.data.inviteAsGuest ? "guest" : "",
        users: common.removeDuplicate(state.invite.filteredUsers),
        invites: common.removeDuplicate(state.invite.filteredInvites),
      };
      // console.log("CHECK PAYLOAD ON API CALL", payLoad);
      state.isLoading.inviteAllUsers = true;
      ethitransService
        .invitation(payLoad)
        .then((res: any) => {
          console.log("Users Invite Check:>>> ", res.data.message);
          hideAddMember();
          showSuccessModal();
          state.data.invitationVerifyMsg = "";
          state.data.invitationVerify.newMemberList = [];
          state.data.invitationVerify.registeredMemberList = [];
          state.data.invitationVerify.pendingMemberList = [];
          state.invitationMessage = res.data.message ? res.data.message : "";
          getCommunicationList(channelId);
        })
        .catch((error: any) => {
          // console.log(error);
          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.errorList.push(value[0]);
              }
            }
          });
        })
        .finally(() => {
          state.isLoading.inviteAllUsers = false;
        });
    }
    function verifyInvitation() {
      state.invite.filteredUsers = [];
      state.invite.filteredInvites = [];
      state.data.invitationVerifyMsg = "";
      state.data.invitationVerify.newMemberList = [];
      state.data.invitationVerify.registeredMemberList = [];
      state.data.invitationVerify.pendingMemberList = [];
      state.invite.users.forEach((item: any) => {
        // console.log("CHECK email", item);
        state.invite.filteredUsers.push(item.email);
      });
      state.invite.invites.forEach((item: any) => {
        // console.log("CHECK email", item);
        state.invite.filteredInvites.push(item);
      });
      if (state.inviteMember && state.invite.invites.length === 0) {
        if (validationService.isValidEmail(state.inviteMember)) {
          // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
          state.invite.filteredInvites.push(state.inviteMember);
        }
      }
      validateInviteForm();
      if (
        state.errorList.length != 0 ||
        state.isErrorStatus ||
        state.ui.isPostLoading.verifyInvitation
      )
        return false;

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.projectId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        invitation_type: "channel_invitation",
        users: state.invite.filteredUsers,
        invites: state.invite.filteredInvites,
      };
      // if(state.invite.filteredUsers.length === 0 || state.invite.filteredInvites.length === 0) return false;
      // console.log("CHECK PAYLOAD ON API CALL", payLoad);
      // state.postLoading.inviteAllUsers = true;
      state.ui.isPostLoading.verifyInvitation = true;
      ethitransService
        .verifyInvitation(payLoad)
        .then((res: any) => {
          state.data.invitationVerify.showStatus = true;
          // state.data.invitationVerifyMsg = ''
          state.ui.isPostLoading.verifyInvitation = false;
          console.log("verifyInvitation Check:>>> ", res.data.data);
          // console.log("verifyInvitation Check:>>> ", );
          state.data.invitationVerify.newMemberList = res.data.data.new_members
            ? res.data.data.new_members
            : [];
          state.data.invitationVerify.registeredMemberList = res.data.data
            .registered_members
            ? res.data.data.registered_members
            : [];
          state.data.invitationVerify.pendingMemberList = res.data.data
            .pending_members
            ? res.data.data.pending_members
            : [];
          if (res.data.data) {
            state.data.invitationVerifyMsg = res.data.data;
          } else {
            inviteAllUsers();
          }

          // hideAddMember();
          // showSuccessModal();
          // state.imvitationMessage = res.data.message ? res.data.message : "";
          // getProjectDetail();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.verifyInvitation = false;
        });
    }
    function latestEnabledTags(data: any) {
      // alert("HELLO");
      state.data.tags.options = data;
    }

    async function uploadAttachmentByChannel(
      // isuplodedIndex: number,
      // imageName: any,
      // imageLoaded: any,
      // extension: string,
      selectedChannelId: number,
      // attachment: any,
      allFile: any
    ) {
      const fileList = [] as any;
      allFile.forEach((item: any) => {
        console.log("CHECK IMAGE LIST", item.tag);
        const tagIds = [] as any;
        const fileData = {} as any;
        if (item.isvalidToUpload) {
          if (item.tag && item.tag.length) {
            state.data.tags.options.forEach((tagItem: any) => {
              item.tag.forEach((dataItem: any) => {
                if (tagItem.name.toLowerCase() === dataItem.toLowerCase()) {
                  tagIds.push(tagItem.id);
                }
              });
            });
          }
          // isUploadedFile = true;
          console.log("im form if")
          fileData["fileName"] = item.fileName;
          fileData["base64"] = item.base64;
          fileData["extension"] = item.extension;
          fileData["item"] = item.item;
          fileData["master_file_tag_id"] = tagIds;
          fileList.push(fileData);
        } else {
          console.log("im form else")
          state.postLoading = false;
          state.imageList = state.imageList.filter((e: any) => e != item);
        }
      });

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_id: selectedChannelId,
        // filename: imageName,
        // extension: extension,
        // attachment: imageLoaded,
        // eslint-disable-next-line @typescript-eslint/camelcase
        files: fileList
      };
      // console.log("PAYLOAD CHECK >>", state.imageList[isuplodedIndex]);
      state.postLoading = true;

      // state.imageList[isuplodedIndex].isuploded = true;
      return await new Promise((resolve, reject) => {
        ethitransService
          .uploadAttachment(payLoad)
          .then((res: any) => {
            console.log("<<<IMAGE upload API Check:>>> ", res.data.data);

            // state.imageList = state.imageList.filter(
            //   // (e: any) => e.base64 != imageLoaded
            // );
            if (
              res.data.data.messages.data &&
              res.data.data.messages.data.length
            ) {
              // state.communicationList = [];
              res.data.data.messages.data.forEach((data: any) => {
                data.allowEdit = false;
                if (data.attachments && data.attachments.length) {
                  data.attachments.forEach((attachment: any) => {
                    attachment.convertedFileSize = common.formatBytes(
                      parseInt(
                        attachment.file_size
                        // parseInt(data.file_size.split(" ")[0])
                      )
                    );
                  });
                }
              });
            }
            if (
              res.data.data.messages.data &&
              res.data.data.messages.data.length
            ) {
              res.data.data.messages.data.forEach((item: any) => {
                if (item.user && item.user.email) {
                  if (
                    item.user.email ===
                    localStorage.getItem("current_user_email")
                  ) {
                    // console.log("email", item.user.email);
                    // console.log("active email", state.data.activeUserEmail);
                    item.editStatus = true;
                  } else {
                    item.editStatus = false;
                  }
                }
              });
            }
            res.data.data.messages.data.forEach((data: any) => {
              if (data.attachments) {
                data.attachments.forEach((video: any) => {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  if (video.file_type === "mov" || video.file_type === "MOV") {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    video.file_type = "mp4";
                  }
                });

                // console.log("Data Check >>> ", data);
              }
            });

            // state.communicationList = res.data.data.messages.data
            //   ? res.data.data.messages.data
            //   : [];
            // scrollToLast();
            // state.message = "";
            // state.postLoading = false;
            if (state.imageList && state.imageList.length) {
              // state.imageList[isuplodedIndex].isuploded = false;
            }
            // state.imageList = state.imageList.filter(
            //   // (e: any) => e.base64 != attachment.base64
            // );
            resolve(true);
          })
          .catch((error: any) => {
            console.log(error);
            reject(false);
          })
          .finally(() => {
            if (state.imageList && !state.imageList.length) {
              state.postLoading = false;
            }
            // state.postLoading = false;
            //  state.imageList[isuplodedIndex].isuploded = false;
            state.imageList = [];
          });
      });
    }


    async function masterTagList() {
      const payLoad = {
        type: "enabled",
      };
      await ethitransService
        .masterFileTagList(payLoad)
        .then((res: any) => {
          // console.log("masterTagList Check:>>> ", res.data.data);
          res.data.data.forEach((item: any) => {
            item.label = item.name;
            item.value = item.code;
          });
          state.data.tags.options = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          // common.sdCatchErr(error, state.errorList).then((res: any) => {
          //   state.isErrorStatus = true;
          //   // console.log("CHECK for Error >>>", res)
          //   for (const [key, value] of Object.entries(res)) {
          //     if (Array.isArray(value)) {
          //       state.errorList.push(value[0]);
          //     }
          //   }
          //   // if (res) {
          //   //   if (res.error) {
          //   //     console.log("CHECK for Error >>>", res.error[0])
          //   //     // state.errorList.push(res.email[0]);
          //   //     // state.errorList = [...state.errorList]
          //   //   }
          //   // }
          // });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    function readImage(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      // console.log("FILE READER CHECK >>>", reader);
      reader.onload = (e: any) => {
        // console.log("BIT BIT>>", e);
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
        if (file.size > state.data.uploadMaxSize) {
          data.isValidToUpload = false;
        } else {
          data.isvalidToUpload = true;
        }
        const fileName = file.name;
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const dotIndex = fileName.indexOf('.');
        const nameBeforeDot = fileName.substring(0, dotIndex);
        const newFileName = `${nameBeforeDot}_${timestamp}`;
        data.base64 = e.target.result;
        data.fileName = newFileName + '.' + extension

        data.extension = extension;
        data.isuploded = false;
        data.size = common.formatBytes(parseInt(file.size));
        state.imageList.push(data);
        if (state.imageList && state.imageList.length) {
          $("#chat-screen-content").animate(
            { scrollTop: $("#chat-screen-content")[0].scrollHeight },
            1000
          );
        }
        // }
      };
      reader.readAsDataURL(file);
      masterTagList();
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.addEventListener("paste", (e: any) => {
      if (e.clipboardData.files.length > 0) {
        // console.log('listended to PASTE', e.clipboardData.files)
        const fileInput: any = document.querySelector("#sendMsg");

        fileInput.files = e.clipboardData.files;
        console.log("CHECK FILE >>>", e.clipboardData.files[0].type);
        if (
          e.clipboardData.files[0].type.startsWith("image/") ||
          e.clipboardData.files[0].type.startsWith("application/")
        ) {
          readImage(e.clipboardData.files[0]);
        }
      }
    });
    function onDocumentChange(event: any) {
      state.item.error.videoError = false;
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        if (file.type.includes("video")) {
          if (
            extension === "mp4" ||
            extension === "mov" ||
            extension === "MOV" ||
            extension === "wmv" ||
            extension === "ogg" ||
            extension === "webm" ||
            extension === "avi" ||
            extension === "x-ms-wmv" ||
            extension === "ogg"
          ) {
            readImage(file);
            // console.log("Check for Extension >>>", extension);
            state.item.error.videoError = false;
          } else {
            state.item.error.videoError = true;
            $("#chat-screen-content").animate(
              { scrollTop: $("#chat-screen-content")[0].scrollHeight },
              1000
            );
          }
          // if()
        } else {
          readImage(file);
        }
        // console.log("CHECK DATA WHEN UPLOADED>>", file);
      }
      // $("#gallery-photo-add").val("")
      window.scrollTo(0, document.body.scrollHeight);
      state.data.active = false;
      $("#create-document-upload").val('');
    }
    function readImageInThread(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      console.log("FILE READER CHECK >>>", reader);
      reader.onload = (e: any) => {
        // console.log("BIT BIT>>", e);
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
        if (file.size > state.data.uploadMaxSize) {
          data.isValidToUpload = false;
        } else {
          data.isvalidToUpload = true;
        }
        const fileName = file.name;
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const dotIndex = fileName.indexOf('.');
        const nameBeforeDot = fileName.substring(0, dotIndex);
        const newFileName = `${nameBeforeDot}_${timestamp}`;
        data.base64 = e.target.result;
        data.fileName = newFileName + '.' + extension

        data.extension = extension;
        data.isuploded = false;
        data.size = common.formatBytes(parseInt(file.size));
        state.threadImageList.push(data);

      };
      reader.readAsDataURL(file);
      masterTagList();
    }
    function onDocumentUploadThread(event: any) {
      state.item.error.videoError = false;
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();

        if (file.type.includes("video")) {
          if (
            extension === "mp4" ||
            extension === "mov" ||
            extension === "wmv" ||
            extension === "ogg" ||
            extension === "webm" ||
            extension === "avi" ||
            extension === "x-ms-wmv" ||
            extension === "ogg"
          ) {
            readImageInThread(file);
            console.log("Check for FILES >>>", file);
            state.item.error.videoError = false;
          } else {
            state.item.error.videoError = true;

          }
        } else {
          readImageInThread(file);
          console.log("Check for FILES  else>>>", file);
        }
        // console.log("CHECK DATA WHEN UPLOADED>>", file);
      }
      // $("#gallery-photo-add").val("")
    }
    function enableSelectedFileTag(document: any) {
      // console.log("Enable Doc >> ", document.id);
      if (document.enableStatus) return false;
      const payLoad = {
        id: document.id,
      };
      state.ui.isPostLoading.enableSelectedFileTag = true;
      document.inProgress = true;
      ethitransService
        .enableMasterFileTag(payLoad)
        .then((res: any) => {
          console.log("enable File Tag :>>> ", res.data.data);
          state.data.disabledFileTagList =
            state.data.disabledFileTagList.filter(
              (data: any) => data.id != document.id
            );
          masterTagList();
          // state.data.successFulMessage = "File Tag Enabled Successfully.";
          // state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.enableSelectedFileTag = false;
          document.inProgress = false;
        });
    }
    function checkForDisabledTags() {
      if (state.imageList && state.imageList.length) {
        masterTagListForValidation(false);
      } else {
        state.data.showAddFileTag = true;
      }
    }
    function closeReplyCommunication() {
      state.data.replyClassStatus = !state.data.replyClassStatus;
      state.data.showReplyCommunication = false;
      router.push({
        name: "admin-channel",
        params: { channelId: channelId },
      });
    }

    function createCommunicationList() {
      if (routerQuery.query.page) {
        const restPageCheck: any = routerQuery.query.page;
        const currentPage = parseInt(restPageCheck);
        console.log("PAGE >>> ", currentPage);
        console.log("BEFORE ARRAY CHECK >>> ", state.data.scrolledPages);
        state.data.scrolledPages = state.data.scrolledPages.filter(
          (e: any) => e != currentPage
        );
        console.log("Final CHECK >>> ", state.data.scrolledPages);
      }
      const isUploadedFile = false;

      if (state.postLoading) return false;
      state.item.error.erorList = [];
      state.item.hasError = false;
      // console.log("AAYo 2", state.postLoading);
      if (state.imageList.length === 0) {
        if (!state.message) return false;
        // console.log("AAYo", state.postLoading);
      }
      if (state.imageList.length >= 1) {
        state.isFile = true;
      }
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        //chnage using communication reply
        // channel_id: routerQuery.params.channelId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
        message: state.message,
        file: state.isFile,
        type: "text",
      };
      state.postLoading = true;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (routerQuery.query.communication_id) {
        closeReplyCommunication();
      }
      if (routerQuery.query.page && routerQuery.query.communicationId) {
        router.push({
          name: "admin-channel",
          params: { channelId: channelId },
        });
      }
      state.isReplyMessage = false;
      ethitransService
        .createCommunication(payLoad)
        .then((res: any) => {
          state.messageStatus = true;
          state.communicationList = [];
          // scrollToLast();
          // console.log(
          //   "CHECK DATA MESSAGE RESP >>>>>",
          //   res.data.data.messages.data[0]
          // );
          // console.log("CHECK COUNT >>>>>", state.communicationList.length);
          //  state.data.scrolledPages = state.data.scrolledPages.filter((scroll: any) => scroll == state.data.page);

          console.log("state.data.scrolledPages", state.data.scrolledPages);
          if (state.imageList && state.imageList.length) {
            state.fileLoadTime = 300;
            // state.imageList.forEach((item: any) => {
            //   console.log("CHECK IMAGE LIST", item);
            //   if (item.isvalidToUpload) {
            //     isUploadedFile = true;
            //     fileData["fileName"] = item.fileName;
            //     fileData["base64"] = item.base64;
            //     fileData["extension"] = item.extension;
            //     fileData["item"] = item.item;
            //     fileList.push(fileData);
            //   } else {
            //     state.postLoading = false;
            //     state.imageList = state.imageList.filter((e: any) => e != item);
            //   }
            // });
            state.ui.isPostLoading.getCommunicationList = true;
            uploadAttachmentByChannel(
              // common.findIndex(state.imageList, item),
              // item.fileName,
              // item.base64,
              // item.extension,
              res.data.data.communication.id,
              // item,
              state.imageList
            )
              .then((result) => {
                state.ui.isPostLoading.getCommunicationList = false;
                getCommunicationList(channelId);
                // got final result
              })
              .catch((err) => {
                // got error
              });
          } else {
            state.postLoading = false;
          }
          res.data.data.messages.data[0].currentTime = common.localDateAndTime(
            res.data.data.messages.data[0].sent_date
          );
          res.data.data.messages.data[0].active = true;
          console.log("p9");
          if (!isUploadedFile) {
            getCommunicationList(channelId);
          }
          if (state.data.isCommunicationFile) {
            state.data.isCommunicationFile = "";
          }

          // scrollAtButton();
          // scrollToLast();

          setTimeout(resetActive, 2000);
          state.postLoading = false;
          // }
          state.message = "";
          // state.communicationList.push(res.data.data)

          // state.message = "";

          state.channel = res.data.data.channel ? res.data.data.channel : {};
          state.data.projectMember = res.data.data.project_member
            ? res.data.data.project_member
            : {};

          res.data.data.messages.data.forEach((item: any) => {
            if (item.form_data != null) {
              // console.log("check msg :>>", item.form_data);
              if (item.form_data && item.form_data.sent_to_users) {
                // console.log( "Type check", item.form_data.sent_to_users)
                if (typeof item.form_data.sent_to_users === "string") {
                  // alert('String')
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  item.form_data.sent_to_users = JSON.parse(
                    item.form_data.sent_to_users
                  );
                }
                // console.log(
                //   "Final :>>",
                //   JSON.parse(item.form_data.sent_to_users)
                // );
                // eslint-disable-next-line @typescript-eslint/camelcase
              }
            }
          });
          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((data: any) => {
              data.allowEdit = false;
              if (data.attachments && data.attachments.length) {
                data.attachments.forEach((attachment: any) => {
                  attachment.convertedFileSize = common.formatBytes(
                    parseInt(
                      attachment.file_size
                      // parseInt(data.file_size.split(" ")[0])
                    )
                  );
                });
              }
            });
          }
          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((item: any) => {
              if (item.user && item.user.email) {
                if (
                  item.user.email === localStorage.getItem("current_user_email")
                ) {
                  // console.log("email", item.user.email);
                  // console.log("active email", state.data.activeUserEmail);
                  item.editStatus = true;
                } else {
                  item.editStatus = false;
                }
              }
            });
            // state.owner = res.data.data.messages[res.data.data.messages.length -1].user;
          }
          res.data.data.messages.data.forEach((data: any) => {
            if (data.attachments) {
              data.attachments.forEach((video: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                if (video.file_type === "mov" || video.file_type === "MOV") {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  video.file_type = "mp4";
                }
              });

              // console.log("Data Check >>> ", data);
            }
          });
          // state.communicationList = res.data.data.messages.data
          //   ? res.data.data.messages.data
          //   : [];
          // alert("IN");
          // res.data.data.messages.data = res.data.data.messages.data.reverse();
          // state.communicationList = [];
          //Added by Anuj
          // res.data.data.messages.data.forEach((data: any) => {
          //   state.communicationList.push(data);
          //   });
          // // state.communicationList = res.data.data.messages.data? res.data.data.messages.data : [];
          // console.log("CHECK AFTER COUNT >>>>>", state.communicationList);
          //   alert("OOUT");
          let admin: any = {};

          if (
            res.data.data.project_role.level === 1 &&
            res.data.data.project_role.code === "owner"
          ) {
            admin = res.data.data.project_role;
          } else {
            if (
              res.data.data.project_role.level === 2 &&
              res.data.data.project_role.code === "co-owner"
            ) {
              admin = res.data.data.project_role;
            }
          }
          // console.log("ADMIN >>>> ", admin);
          res.data.data.channel.members.forEach((member: any) => {
            member.isChangeRole = false;
            if (admin && admin.level && admin.code) {
              if (member.user && member.user.email) {
                if (member.user.email !== state.email) {
                  member.isChangeRole = true;
                  // console.log("CHECK MEMBER >>> ", member.user);
                }
              }
            }
          });
          state.memberList = res.data.data.channel.members
            ? res.data.data.channel.members
            : [];

          const textarea: any = document.getElementById("sendMsg");

          textarea.setAttribute("style", "");
          textarea.value = "";
          // state.data.typeDisabled = false;
          // $('#chat-body')[0].scrollTop = $('#chat-body')[0].scrollHeight;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.data.typeDisabled = true;
          state.isFile = false;
        });
      // }
    }
    const someEventHander = function (event: any) {
      console.log("do something");
    };
    // document.addEventListener("keydown", function (event: any) {
    //   // alert('hi');
    //   //   // console.log("Global Enter Pressed");
    //   //   if (!enableEnterKey.value) return false;
    //   //   // console.log("ALLOWED");
    //   //   //   // alert("Hello")
    //   // if(!enableEnterKey.value){
    //   if (event.keyCode === 13 && !event.shiftKey && !event.ctrlKey) {
    //     if (state.imageList && state.imageList.length) {
    //       createCommunicationList();
    //     }
    //   }
    //   // }
    // });

    // function onEditorInput(data: any) {
    //   // if (!data) return false;
    //   // data = common.replaceURLs(data);
    //   // console.log("Check >>> ",  data)
    //   document.addEventListener("keydown", function (event: any) {
    //     if (event.keyCode === 13 && !event.shiftKey) {
    //       // console.log("TEST for Enter Before", state.message);
    //       if (data.endsWith("<p>&nbsp;</p>")) {
    //         state.message = state.message.replace(
    //           new RegExp("<p>&nbsp;</p>", "g"),
    //           ""
    //         );
    //         console.log("TEST for Enter  After", state.message);
    //         // state.message = state.message.replace(/<p>&nbsp;/g, "");
    //         // state.message = state.message.replace(\</p>\g, "");
    //       }
    //       state.message = state.message.replace(
    //         /<a/g,
    //         `<a target='_blank' rel='noopener noreferrer'`
    //       );
    //       if (state.message.includes("https://")) {
    //         // state.message = state.message.replace(/href="/g, `href="https://`);
    //       } else if (state.message.includes("http://")) {
    //         console.log("GOOD");
    //       } else {
    //         // alert("WOW")
    //         state.message = state.message.replace(/href="/g, `href="https://`);
    //       }
    //       state.data.typeDisabled = true;
    //       // alert('Enter is pressed!');

    //       createCommunicationList();
    //     }
    //   });
    // }
    function isKeyPressed(event: any) {
      if (event.key === "Enter") {
        if (event.shiftKey) {
          console.log("shift enter was pressed");
        }
      }
    }
    function chatMessageEnterSetting(data: boolean) {
      // if (common.onlySpaces(state.message)) {
      //   console.log("<<Removed >>");
      //   state.message = "";
      // }
      if (state.message.startsWith("<p></p>")) {
        console.log("<<Removed >>");
        state.message = "";
      }
      if (state.message.startsWith("<p><br></p>")) {
        console.log("<<Removed >>");
        state.message = "";
      }

      // if (!state.message || !state.imageList) return false;
      createCommunicationList();
    }

    function getChannelFormList() {
      const payLoad = {
        search: {
          // project_id: '278241ec-7e7f-468f-a17b-9504773225c0',
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_name: state.searchBy,
        },
        filter: {
          type: state.filter.type,
          email: state.email,
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_by: {
          title: 1,
        },
        paging: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          per_page: state.item.paging.getChannelFormList.perPage,
          // eslint-disable-next-line @typescript-eslint/camelcase
          current_page: state.item.paging.getChannelFormList.currentPage,
        },
      };
      state.ui.isLoading.getChannelFormList = true;
      ethitransService
        .channelAccountFormListSearch(payLoad)
        .then((res: any) => {
          console.log("getChannelFormList Response Check HHHH:>>> ", res.data);
          state.formList = res.data ? res.data : {};
          if (res.data.totalPages) {
            state.item.paging.getChannelFormList.totalPages =
              res.data.totalPages;
          }
          if (res.data.totalDocs) {
            state.item.paging.getChannelFormList.totalDocs = res.data.totalDocs;
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.getChannelFormList = false;
        });
    }
    function nextPage(event: any) {
      state.item.paging.getChannelFormList.currentPage = event;
      getChannelFormList();
    }
    function channelCommunicationForm(id: any) {
      const data = state.ui.template.response;
      console.log("CHECK DATA >>>>>>>>>>", data);
      let payLoad: any = {};
      const usersArray = [] as any;
      if (state.data.clientDocumentRetrieve.edoc_id) {
        state.invite.users.forEach((item: any) => {
          usersArray.push(item);
        });
        payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          communication_id: id,
          type: "client_document",
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_id: data._id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_document_id: state.data.clientDocumentRetrieve.uuid,
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_document_name: state.data.clientDocumentRetrieve.name,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sent_to_users: usersArray,
        };
      } else {
        payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          communication_id: id,
          type: "form",
          // eslint-disable-next-line @typescript-eslint/camelcase
          // form_data: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_id: data._id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_name: data.title,
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_desc: data.description,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sent_to_users: data.sentToUsers,
          // },
        };
      }
      // state.owner

      console.log("<< ChannelCommunicationForm CHECK >>", payLoad);
      state.ui.isPostLoading.createFormTemplate = true;
      ethitransService
        .channelCommunicationFormData(payLoad)
        .then((res: any) => {
          console.log("ChannelCommunicationForm Response Check:>>> ", res.data);
          state.message = "";
          // console.log("CommunicationList Response Check:>>> ", res.data.data);
          state.channel = res.data.data.channel ? res.data.data.channel : {};
          state.data.projectMember = res.data.data.project_member
            ? res.data.data.project_member
            : {};
          res.data.data.messages.data.forEach((item: any) => {
            if (item.form_data != null) {
              console.log("check msg :>>", item.form_data);
              if (item.form_data && item.form_data.sent_to_users) {
                console.log(
                  "Final :>>",
                  JSON.parse(item.form_data.sent_to_users)
                );
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.form_data.sent_to_users = JSON.parse(
                  item.form_data.sent_to_users
                );
              }
            }
          });
          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((data: any) => {
              data.allowEdit = false;
              if (data.attachments && data.attachments.length) {
                data.attachments.forEach((attachment: any) => {
                  attachment.convertedFileSize = common.formatBytes(
                    parseInt(
                      attachment.file_size
                      // parseInt(data.file_size.split(" ")[0])
                    )
                  );
                });
              }
            });
          }
          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((item: any) => {
              if (item.user && item.user.email) {
                if (
                  item.user.email === localStorage.getItem("current_user_email")
                ) {
                  // console.log("email", item.user.email);
                  // console.log("active email", state.data.activeUserEmail);
                  item.editStatus = true;
                } else {
                  item.editStatus = false;
                }
              }
            });
          }
          res.data.data.messages.data.forEach((data: any) => {
            if (data.attachments) {
              data.attachments.forEach((video: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                if (video.file_type === "mov" || video.file_type === "MOV") {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  video.file_type = "mp4";
                }
              });

              // console.log("Data Check >>> ", data);
            }
          });
          state.communicationList = res.data.data.messages.data
            ? res.data.data.messages.data
            : [];
          let admin: any = {};

          if (
            res.data.data.project_role.level === 1 &&
            res.data.data.project_role.code === "owner"
          ) {
            admin = res.data.data.project_role;
          } else {
            if (
              res.data.data.project_role.level === 2 &&
              res.data.data.project_role.code === "co-owner"
            ) {
              admin = res.data.data.project_role;
            }
          }
          console.log("ADMIN >>>> ", admin);
          res.data.data.channel.members.forEach((member: any) => {
            member.isChangeRole = false;
            if (admin && admin.level && admin.code) {
              if (member.user && member.user.email) {
                if (member.user.email !== state.email) {
                  member.isChangeRole = true;
                  // console.log("CHECK MEMBER >>> ", member.user);
                }
              }
            }
          });
          state.memberList = res.data.data.channel.members
            ? res.data.data.channel.members
            : [];
          // scrollAtButton();
          scrollToLast();
          $("#templateupload").modal("hide");
          if (state.data.clientDocumentRetrieve.edoc_id) {
            state.data.clientDocumentRetrieve = {};
            state.invite.filteredUsers = [];
            state.invite.users = [];
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.createFormTemplate = false;
        });
    }
    function formMessage(msg: string, users: any) {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
        message: msg,
        usersList: users,
        type: "form",
      };
      state.postLoading = true;
      ethitransService
        .createCommunication(payLoad)
        .then((res: any) => {
          console.log("FORM MSG HERE >>> ", res.data.data);
          // console.log(
          //   "<<<Create Communication Response Check:>>> ",
          //   res.data.data
          // );

          state.message = "";
          state.data.scrollBottom = true;
          state.data.scrollTop = false;

          // console.log("CommunicationList Response Check:>>> ", res.data.data);
          state.channel = res.data.data.channel ? res.data.data.channel : {};
          state.data.projectMember = res.data.data.project_member
            ? res.data.data.project_member
            : {};
          res.data.data.messages.data.forEach((item: any) => {
            if (item.form_data != null) {
              console.log("check msg :>>", item.form_data);
              if (item.form_data && item.form_data.sent_to_users) {
                console.log(
                  "Final :>>",
                  JSON.parse(item.form_data.sent_to_users)
                );
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.form_data.sent_to_users = JSON.parse(
                  item.form_data.sent_to_users
                );
              }
            }
          });
          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((data: any) => {
              data.allowEdit = false;
              if (data.attachments && data.attachments.length) {
                data.attachments.forEach((attachment: any) => {
                  attachment.convertedFileSize = common.formatBytes(
                    parseInt(
                      attachment.file_size
                      // parseInt(data.file_size.split(" ")[0])
                    )
                  );
                });
              }
            });
          }
          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((item: any) => {
              if (item.user && item.user.email) {
                if (
                  item.user.email === localStorage.getItem("current_user_email")
                ) {
                  // console.log("email", item.user.email);
                  // console.log("active email", state.data.activeUserEmail);
                  item.editStatus = true;
                } else {
                  item.editStatus = false;
                }
              }
            });
          }
          res.data.data.messages.data.forEach((data: any) => {
            if (data.attachments) {
              data.attachments.forEach((video: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                if (video.file_type === "mov" || video.file_type === "MOV") {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  video.file_type = "mp4";
                }
              });

              // console.log("Data Check >>> ", data);
            }
          });
          state.communicationList = res.data.data.messages.data
            ? res.data.data.messages.data
            : [];
          let admin: any = {};

          if (
            res.data.data.project_role.level === 1 &&
            res.data.data.project_role.code === "owner"
          ) {
            admin = res.data.data.project_role;
          } else {
            if (
              res.data.data.project_role.level === 2 &&
              res.data.data.project_role.code === "co-owner"
            ) {
              admin = res.data.data.project_role;
            }
          }

          res.data.data.channel.members.forEach((member: any) => {
            member.isChangeRole = false;
            if (admin && admin.level && admin.code) {
              if (member.user && member.user.email) {
                if (member.user.email !== state.email) {
                  member.isChangeRole = true;
                  // console.log("CHECK MEMBER >>> ", member.user);
                }
              }
            }
          });
          state.memberList = res.data.data.channel.members
            ? res.data.data.channel.members
            : [];
          // scrollAtButton();
          // scrollToLast();
          channelCommunicationForm(res.data.data.communication.id);
          // $("#chat-body").animate({ scrollTop: $("#chat-body")[0].scrollHeight}, 1000);

          // $('#chat-body')[0].scrollTop = $('#chat-body')[0].scrollHeight;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.postLoading = false;
        });
    }

    function createNewFormValiation() {
      state.item.error.erorListNewForm = [];
      state.item.hasError = false;
      console.log("data check", state.newFormData.title);
      console.log("data Array List", state.newFormData.elements);

      if (!state.newFormData.title) {
        state.item.error.erorListNewForm.push("Form name is required.");
      }
      if (!state.newFormData.privacy.value) {
        state.item.error.erorListNewForm.push("Please select the privacy.");
      }
      if (
        !state.newFormData.elements ||
        state.newFormData.elements.length === 0
      ) {
        state.item.error.erorListNewForm.push(
          "Please select At least 1 element from the left side Elements."
        );
      }

      if (
        !state.item.error.erorListNewForm &&
        !state.item.error.erorListNewForm.length
      ) {
        state.item.hasError = false;
      } else if (state.item.error.erorListNewForm.length != 0) {
        state.item.hasError = true;
      }
      console.log("Error List", state.item.error.erorListNewForm);
      console.log("Error Has error", state.item.hasError);
    }
    function showUserModal() {
      // console.log("Hello");
      state.invite.users = [];
      state.ui.template.msg = "";
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;
      createNewFormValiation();
      if (
        state.item.error.erorListNewForm &&
        state.item.error.erorListNewForm.length &&
        state.item.hasError &&
        state.newFormData.privacy.value.length
      )
        return false;
      getUserList();
      // if (
      //   (state.item.error.erorListNewForm &&
      //   state.item.error.erorListNewForm.length != 0) &&
      //   state.item.hasError &&
      //   !state.newFormData.privacy
      // )
      //   return false
      $("#senduserdModal").modal("show");
    }
    function closeInvitationModal() {
      $("#successModal").modal("hide");
    }
    function showNewFormConformation() {
      createNewFormValiation();
      if (
        state.item.error.erorListNewForm &&
        state.item.error.erorListNewForm.length &&
        state.item.hasError
      )
        return false;
      $("#confirmNewForm").modal("show");
    }
    function hideNewFormConformation() {
      $("#confirmNewForm").modal("hide");
    }
    function showNewTitleInCreateFormEdit(data: any) {
      // editSelectedElementOfCreateNewForm
      console.log("Data Selected >>", data);
      state.selectedElement.type = data;
      // console.log("Title Data Check: >>", state.selectedElement.type);
      // console.log("edit Title:>>", state.selectedFormForEdit.title);
      // console.log("edit Description:>>", state.selectedFormForEdit.description);
      state.selectedElement.title = state.newFormData.title;
      state.selectedElement.description = state.newFormData.description;
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#editSelectedElementOfCreateNewForm").modal("show");
    }
    function showNewElementEdit(data: any, type: any) {
      console.log("EDIT SECTION CHECK >>> ", data);
      const formIndex = common.findIndex(state.newFormData.elements, data);
      console.log("Selected Form Index", formIndex);
      state.selectedElement.element = data;
      state.selectedElement.type = type;
      state.selectedElement.index = common.findIndex(
        state.newFormData.elements,
        data
      );
      state.selectedElement.title = data.title;
      state.selectedElement.description = data.description;
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#editSelectedElementOfCreateNewForm").modal("show");
    }
    function hideEditCreateNewForm() {
      $("#editSelectedElementOfCreateNewForm").modal("hide");
    }
    function updateEditNewForm() {
      // alert()
      console.log("IN");
      console.log("Check:>>>", state.selectedElement.type);
      if (state.selectedElement.type === "title") {
        updateEditFormElementsValidation("title");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // console.log("<<< Title Change >>>");
        state.newFormData.title = state.selectedElement.title;
        state.newFormData.description = state.selectedElement.description;
      } else if (
        state.selectedElement.type === "textbox" ||
        state.selectedElement.type === "section"
      ) {
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // state.selectedFormForEdit.title = state.selectedElement.title;
        state.newFormData.elements[state.selectedElement.index].title =
          state.selectedElement.title;
        state.newFormData.elements[state.selectedElement.index].description =
          state.selectedElement.description;
        // console.log("<<< Title Change >>>");
      } else if (state.selectedElement.type === "textArea") {
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // state.selectedFormForEdit.title = state.selectedElement.title;
        // console.log("<<< Title Change >>>");
        state.newFormData.elements[state.selectedElement.index].title =
          state.selectedElement.title;
      } else if (
        state.selectedElement.type === "radioButton" ||
        state.selectedElement.type === "single_select" ||
        state.selectedElement.type === "checkBox"
      ) {
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // console.log("<<< Title Change >>>");

        state.newFormData.elements[state.selectedElement.index] =
          state.selectedElement.element;
      }
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#editSelectedElementOfCreateNewForm").modal("hide");
    }
    function showNewElementDelete(data: any, type: any) {
      $("#deleteSelectedElementInCreateNewForm").modal("show");
      state.selectedElement.element = data;
      state.selectedElement.type = type;
    }
    function deleteSelectedElementOfCreateForm() {
      state.newFormData.elements = state.newFormData.elements.filter(
        (item: any) => item != state.selectedElement.element
      );
      $("#deleteSelectedElementInCreateNewForm").modal("hide");
    }
    function hideDeleteSelectedElementInCreateNew() {
      $("#deleteSelectedElementInCreateNewForm").modal("hide");
    }
    function createNewFormEmailValiation() {
      console.log("ALL  IN");
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;

      console.log("EMAIL CHECK", state.invite.users);

      if (state.invite.users && !state.invite.users.length) {
        state.item.error.erorListSendForm.push("Atleast 1 Email is required.");
      }

      if (
        !state.item.error.erorListSendForm &&
        !state.item.error.erorListSendForm.length
      ) {
        state.item.hasError = false;
      } else if (state.item.error.erorListSendForm.length != 0) {
        state.item.hasError = true;
      }
      console.log("Error List", state.item.error.erorListSendForm);
      console.log("Error Has error", state.item.hasError);
    }

    function createNewForm() {
      // erorListSendForm
      // console.log("owner Check check:>>>", state.owner)
      state.item.error.erorListNewForm = [];
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;

      // console.log("Before");
      // createNewFormEmailValiation();

      if (state.ui.isPostLoading.createNewForm) return false;
      console.log("After");
      const usersArray = [] as any;
      state.invite.users.forEach((item: any) => {
        usersArray.push(item);
      });
      const payLoad = {
        title: state.newFormData.title,
        description: state.newFormData.description,
        elements: state.newFormData.elements,
        sentToUsers: usersArray,
        privacy: {
          code: state.newFormData.privacy.code,
          name: state.newFormData.privacy.value,
        },
        owner: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_by: {
            name: state.owner.created_by.full_name,
            email: state.owner.created_by.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
        },
      };
      // console.log("New Form PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.createNewForm = true;
      ethitransService
        .accountformSave(payLoad)
        .then((res: any) => {
          console.log("createNewForm Response Check:>>> ", res.data);
          state.ui.template.response = res.data;
          if (state.formList.docs && state.formList.docs.length) {
            state.formList.docs.unshift(res.data);
          }
          $("#senduserdModal").modal("hide");
          $("#innerchannelModal").modal("hide");
          if (state.ui.template.msg || (usersArray && usersArray.length)) {
            formMessage(state.ui.template.msg, usersArray);
            $("#templateupload").modal("hide");
          }
          hideNewFormConformation();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.createNewForm = false;
        });
    }
    function closeChannel() {
      if (state.data.subscriptionDetails.archive_channel === 0) {
        // if (1 === state.data.subscriptionDetails.total_channel_member) {

        state.ui.isActive.upgradeSubscription = true;
      } else {
        state.item.error.erorList = [];
        state.item.hasError = false;
        if (state.ui.isPostLoading.closeChannel || !channelId) return false;
        const payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
        };
        // console.log("New Form PAYLOAD CHECK ", payLoad);
        state.ui.isPostLoading.closeChannel = true;
        ethitransService
          .closeChannel(payLoad)
          .then((res: any) => {
            console.log("closeChannel Response Check:>>> ", res.data);
            state.channel = res.data.data;
          })
          .catch((error: any) => {
            // console.log(error);
            common
              .sdCatchErr(error, state.item.error.erorList)
              .then((res: any) => {
                state.item.hasError = true;
                for (const [key, value] of Object.entries(res)) {
                  if (Array.isArray(value)) {
                    state.item.error.erorList.push(value[0]);
                  }
                }
              });
          })
          .finally(() => {
            state.ui.isPostLoading.closeChannel = false;
          });
      }
    }
    function downloadProjectFiles() {



      if (state.data.subscriptionDetails.export_channel_pdf === 0) {
        // if (1 === state.data.subscriptionDetails.total_channel_member) {

        state.ui.isActive.upgradeSubscription = true;
      } else {
        state.item.error.erorList = [];
        state.item.hasError = false;
        if (state.ui.isPostLoading.downloadProjectFiles || !channelId)
          return false;
        const payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
        };
        // console.log("New Form PAYLOAD CHECK ", payLoad);
        state.ui.isPostLoading.downloadProjectFiles = true;
        ethitransService
          .projectFileDownload(payLoad)
          .then((res: any) => {
            // console.log("downloadProjectFiles Response Check:>>> ", res.data.data);
            window.open(res.data.data, "_blank");
          })
          .catch((error: any) => {
            // console.log(error);
            common
              .sdCatchErr(error, state.item.error.erorList)
              .then((res: any) => {
                state.item.hasError = true;
                for (const [key, value] of Object.entries(res)) {
                  if (Array.isArray(value)) {
                    state.item.error.erorList.push(value[0]);
                  }
                }
              });
          })
          .finally(() => {
            state.ui.isPostLoading.downloadProjectFiles = false;
          });
      }
    }
    function sendCreateNewForm() {
      createNewFormEmailValiation();
      if (state.item.error.erorListSendForm.length != 0 || state.item.hasError)
        return false;
      createNewForm();
    }
    function sendTemplateFile() {
      // alert("Hello")
      createNewFormEmailValiation();
      if (
        state.ui.isPostLoading.createFormTemplate ||
        state.item.error.erorListSendForm.length != 0 ||
        state.item.hasError
      )
        return false;
      const usersArray = [] as any;
      state.invite.users.forEach((item: any) => {
        usersArray.push(item);
      });

      const payLoad = {
        title: state.formData.title,
        description: state.formData.description,
        message: state.ui.template.msg,
        // eslint-disable-next-line @typescript-eslint/camelcase
        elements: state.formData.elements,
        sentToUsers: usersArray,
        privacy: {
          code: state.newFormData.privacy.code,
          name: state.newFormData.privacy.value,
        },
        owner: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_by: {
            name: state.owner.created_by.full_name,
            email: state.owner.created_by.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
        },
      };

      // const payLoad = {
      //   title: state.formData.title,
      //   description: state.formData.description,
      //   message: state.ui.template.msg,
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   elements: state.formData.elements,
      //   owner: state.owner,
      //   privacy: {
      //     code: "channel",
      //     name: state.newFormData.privacy,
      //   },
      //   sentToUsers: usersArray,
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   master_form_id: state.formData.master_form_id,
      // };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.createFormTemplate = true;
      ethitransService
        .accountformSave(payLoad)
        .then((res: any) => {
          console.log("UserList Response Check:>>> ", res.data);
          state.ui.template.response = res.data;
          if (state.formList.docs && state.formList.docs.length) {
            state.formList.docs.unshift(res.data);
          }
          $("#mentionuser").modal("hide");
          $("#exampleModal").modal("hide");
          $("#editExampleModal").modal("hide");
          // channelCommunicationForm(res.data, state.ui.template.msg)
          // channelCommunicationForm(state.ui.template.msg)
          if (state.ui.template.msg || (usersArray && usersArray.length)) {
            formMessage(state.ui.template.msg, usersArray);
            $("#templateupload").modal("hide");
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.createFormTemplate = false;
        });
    }
    function editNewFormValiation() {
      state.item.error.erorListSaveCustomSelectedForm = [];
      state.item.hasError = false;
      console.log("data check", state.newFormData.title);
      console.log("data Array List", state.selectedFormForEdit.elements);
      if (!state.selectedFormForEdit.title) {
        state.item.error.erorListSaveCustomSelectedForm.push(
          "Form name is required."
        );
      }
      if (!state.newFormData.privacy.value) {
        state.item.error.erorListSaveCustomSelectedForm.push(
          "Please select the privacy."
        );
      }
      if (
        !state.selectedFormForEdit.elements &&
        state.selectedFormForEdit.elements.length === 0
      ) {
        state.item.error.erorListSaveCustomSelectedForm.push(
          "Please select At least 1 element from the left side Elements."
        );
      }

      if (
        !state.item.error.erorListSaveCustomSelectedForm &&
        !state.item.error.erorListSaveCustomSelectedForm.length
      ) {
        state.item.hasError = false;
      } else if (state.item.error.erorListSaveCustomSelectedForm.length != 0) {
        state.item.hasError = true;
      }
      console.log(
        "Error List",
        state.item.error.erorListSaveCustomSelectedForm
      );
      console.log("Error Has error", state.item.hasError);
    }
    function showCustomFormConformation() {
      editNewFormValiation();
      if (
        (state.item.error.erorListSaveCustomSelectedForm &&
          state.item.error.erorListSaveCustomSelectedForm.length) ||
        state.item.hasError ||
        !state.newFormData.privacy.value
      )
        return false;
      $("#confirmEditForm").modal("show");
    }
    function hideConfirmEditForm() {
      $("#confirmEditForm").modal("hide");
    }
    function saveCustomSelectedForm() {
      // console.log("Save the custom Edit form 1", state.selectedFormForEdit);
      // console.log("Save the custom Edit form", state.selectedFormForEdit._id);
      // editNewFormValiation();
      if (state.ui.isPostLoading.createFormTemplate) return false;
      // console.log("saveCustomSelectedForm CHECK");
      const usersArray = [] as any;
      state.invite.users.forEach((item: any) => {
        usersArray.push(item);
      });
      const payLoad = {
        title: state.selectedFormForEdit.title,
        description: state.selectedFormForEdit.description,
        message: state.ui.template.msg,
        // eslint-disable-next-line @typescript-eslint/camelcase
        elements: state.selectedFormForEdit.elements,
        sentToUsers: usersArray,
        privacy: {
          code: state.newFormData.privacy.code,
          name: state.newFormData.privacy.value,
        },
        owner: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_by: {
            name: state.owner.created_by.full_name,
            email: state.owner.created_by.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
        },
      };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.createFormTemplate = true;
      ethitransService
        .accountformSave(payLoad)
        .then((res: any) => {
          console.log("saveCustomSelectedForm Response Check:>>> ", res.data);
          state.ui.template.response = res.data;
          if (state.formList.docs && state.formList.docs.length) {
            state.formList.docs.unshift(res.data);
          }
          // state.ui.template.response = res.data;
          $("#sendformModal").modal("hide");
          $("#createnewformModal").modal("hide");
          if (state.ui.template.msg || (usersArray && usersArray.length)) {
            formMessage(state.ui.template.msg, usersArray);
            $("#templateupload").modal("hide");
          }
          hideConfirmEditForm();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.createFormTemplate = false;
        });
    }
    function emailValidationCustomEdit() {
      createNewFormEmailValiation();
      if (
        state.ui.isPostLoading.createFormTemplate ||
        state.item.error.erorListSendForm.length != 0 ||
        state.item.hasError
      )
        return false;
      saveCustomSelectedForm();
    }
    function showSendTemplate() {
      // console.log("Show send Template");
      $("#templateupload").modal("show");
      getChannelFormList();
    }
    function showSendFormModal() {
      // console.log("Show send Template");
      getUserList();
      state.invite.users = [];
      state.ui.template.msg = "";
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;
      $("#sendformModal").modal("show");
    }
    function editCommunicationData(event: any) {
      // console.log("YOYO DATA CHECK >>>>>", event);
      const data = state.communicationList.filter(
        (item: any) => item.id === event.id
      );
      // eslint-disable-next-line @typescript-eslint/camelcase
      data.is_edited = 1;
      const indexId: number = common.findIndex(
        state.communicationList,
        data[0]
      );
      // console.log("YOYO AFTER >>>>>", data[0]);
      // console.log("YOYO ID >>>>>", indexId);
      // state.communicationList[indexId] = event;
      console.log("p10");
      getCommunicationList(channelId);
      // state.communicationList
    }
    function getSharedFiles() {
      state.item.error.erorList = [];
      state.item.hasError = false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
      };
      state.ui.isLoading.getSharedFiles = true;
      ethitransService
        .sharedFiles(payLoad)
        .then((res: any) => {
          res.data.data.attachments.forEach((data: any) => {
            // eslint-disable-next-line @typescript-eslint/camelcase
            data.created_at = common.localDateAndTime(data.created_at);
          });
          res.data.data.replyAttachments.forEach((data: any) => {
            // eslint-disable-next-line @typescript-eslint/camelcase
            data.created_at = common.localDateAndTime(data.created_at);
          });
          state.data.sharedFiles = res.data.data ? res.data.data : {};
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getSharedFiles = false;
        });
    }
    function detailsModal() {
      getAccountFormList();
      getSharedFiles();
    }
    function downloadEdocPdf() {
      // console.log("DATA CHECK", state.item.selectedUserFormDetails);
      if (state.item.selectedUserFormDetails.edoc_pdf_path) {
        window.open(state.item.selectedUserFormDetails.edoc_pdf_path);
      } else if (state.item.selectedUserFormDetails.client_doc_pdf_path) {
        window.open(state.item.selectedUserFormDetails.client_doc_pdf_path);
      }
    }
    function clearSelectedSignature(form: any) {
      // Clears the canvas
      form.signaturePad.clear();
    }
    // function reloadPage() {
    //   // The last "domLoading" Time //
    //   // eslint-disable-next-line no-var
    //   var currentDocumentTimestamp = new Date(
    //     performance.timing.domLoading
    //   ).getTime();
    //   // Current Time //
    //   // eslint-disable-next-line no-var
    //   var now = Date.now();
    //   // Ten Seconds //
    //   // eslint-disable-next-line no-var
    //   var tenSec = 10 * 1000;
    //   // Plus Ten Seconds //
    //   // eslint-disable-next-line no-var
    //   var plusTenSec = currentDocumentTimestamp + tenSec;
    //   if (now > plusTenSec) {
    //     location.reload();
    //     // eslint-disable-next-line no-empty
    //   } else {
    //   }
    // }
    function getUserListByChannel() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
      };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.isLoading.getUserListByChannel = true;
      ethitransService
        .channelMemberList(payLoad)
        .then((res: any) => {
          console.log("getUserListByChannel Check:00000>>> ", res.data.data);
          state.userList = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.isLoading.getUserListByChannel = false;
        });
    }
    function clientDocumentList() {
      const payLoad = {
        type: "ready",
      };
      state.ui.isLoading.clientDocumentList = true;
      ethitransService
        .clientDocumentList(payLoad)
        .then((res: any) => {
          // console.log("clientDocumentList :>>> ", res.data.data);
          if (res.data.data && res.data.data.length) {
            res.data.data.forEach((item: any) => {
              item.convertedFileSize = common.formatBytes(
                parseInt(item.file_size)
              );
            });
            state.data.clientDocuments = res.data.data;
          } else {
            state.data.clientDocuments = [];
          }
          // alert()
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.clientDocumentList = false;
        });
    }
    // function getUserListByProjectChannel() {
    //   const payLoad = {
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     project_id: state.projectId,
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     channel_id: channelId,
    //     // channel_id: state.data.channel.uuid,
    //   };
    //   // console.log("PAYLOAD CHECK ", payLoad);
    //   state.ui.isPostLoading.getUserList = true;
    //   ethitransService
    //     .userList(payLoad)
    //     .then((res: any) => {
    //       // console.log("UserList Response Check:>>> ", res.data.data);
    //       state.data.userList = res.data.data ? res.data.data : [];
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       state.ui.isPostLoading.getUserList = false;
    //     });
    // }
    function sendOptionBack() {
      $("#sendoption").modal("hide");
    }
    function validateSendDigitizedForm() {
      // console.log("Validate Send Digitize Form");
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;

      // console.log("EMAIL CHECK", state.invite.users);

      if (state.invite.users && !state.invite.users.length) {
        state.item.error.erorListSendForm.push("Atleast 1 Email is required.");
      }

      if (
        !state.item.error.erorListSendForm &&
        !state.item.error.erorListSendForm.length
      ) {
        state.item.hasError = false;
      } else if (state.item.error.erorListSendForm.length != 0) {
        state.item.hasError = true;
      }
    }
    function sendDigitizedForm() {
      validateSendDigitizedForm();
      // alert("HELLO FOR SEND DIGITAL FORM");
      if (
        state.ui.isPostLoading.createFormTemplate ||
        state.item.error.erorListSendForm.length != 0 ||
        state.item.hasError
      )
        return false;
      const usersArray = [] as any;
      state.invite.users.forEach((item: any) => {
        usersArray.push(item);
      });

      const payLoad = {
        title: state.data.clientDocumentRetrieve.name,
        description: state.formData.description,
        // eslint-disable-next-line @typescript-eslint/camelcase
        edoc_id: state.data.clientDocumentRetrieve.edoc_id,
        // message: state.data.additionalMessage,
        elements: state.formData.elements,
        sentToUsers: usersArray,
        privacy: {
          code: state.newFormData.privacy.code,
          name: state.newFormData.privacy.value,
        },
        owner: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_by: {
            // name: state.owner.created_by.full_name,
            name: localStorage.getItem("current_user_details"),
            email: localStorage.getItem("current_user_email"),
            // email: state.owner.created_by.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
          project: {
            //added
            id: state.channel.project.id,
            uuid: state.channel.project.uuid,
            name: state.channel.project.name,
            description: state.channel.project.description,
          },
          channel: {
            //added
            id: state.channel.id,
            uuid: state.channel.uuid,
            name: state.channel.name,
            description: state.channel.description,
          },
        },
      };
      console.log("CHECK THE Request", payLoad);
      state.ui.isPostLoading.sendDigitizedForm = true;
      ethitransService
        .accountformSave(payLoad)
        .then((res: any) => {
          console.log("UserList Response Check:>>> ", res.data);
          state.ui.template.response = res.data;
          // if (state.formList.docs && state.formList.docs.length) {
          //   state.formList.docs.unshift(res.data);
          // }
          // $("#mentionuser").modal("hide");
          // // channelCommunicationForm(res.data, state.ui.template.msg)
          // // channelCommunicationForm(state.ui.template.msg)
          if (
            state.data.additionalMessage ||
            (usersArray && usersArray.length)
          ) {
            formMessage(state.data.additionalMessage, usersArray);
            $("#showdigitizedtemplate").modal("hide");
            $("#sendoption").modal("hide");
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.sendDigitizedForm = false;
        });
    }
    function hideDigitizedtemplate() {
      $("#showdigitizedtemplate").modal("hide");
    }
    function hideTemplateupload() {
      $("#templateupload").modal("hide");
    }

    function clientDocumentRetrieve(uuid: any) {
      // console.log("Get Account form Response:>>>", formId);
      const payLoad = {
        id: uuid,
      };
      state.ui.isPostLoading.clientDocumentRetrieve = true;
      ethitransService
        .clientDocumentRetrieve(payLoad)
        .then((res: any) => {
          console.log(
            "<<<clientDocumentRetrieve Check:>>> ",
            res.data.data

            // sendoption
          );
          if (res.data.data) {
            state.data.clientDocumentRetrieve = res.data.data;
          }
          getUserListByChannel();
          $("#sendoption").modal("show");
          state.invite.users = [];
          state.data.additionalMessage = "";
          state.data.privacy = {
            name: "Private to this channel",
            value: "private to this channel",
            code: "channel",
          };
          // getUserListByProjectChannel()
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.clientDocumentRetrieve = false;
        });
    }
    function selectDigitizeForm(selectedDocument: any) {
      if (!selectedDocument.uuid) return false;
      clientDocumentRetrieve(selectedDocument.uuid);
      // console.log("Selected Doc", selectedDocument)
      // router.push({
      //   name: "admin-channel-create-form",
      //   query: { formId: selectedDocument.uuid, mode: "send", type: 'pdf' },
      // });
    }
    function showDigitizedTemplate() {
      // alert('Digital')
      $("#showdigitizedtemplate").modal("show");
      clientDocumentList();
    }
    function resize(e: any) {
      // console.log(e.data);
      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;

      if (!state.message) {
        e.target.style.height = "auto";
      }
      // $("#chat-screen-content").animate(
      // { scrollTop: $("#chat-screen-content")[0].scrollHeight },
      // 1000
      // );
    }
    function resizeFormTextArea(e: any) {
      // console.log("CHECK INPUT",e.data);
      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;

      if (!e.data) {
        e.target.style.height = "auto";
      }
      // $("#chat-screen-content").animate(
      // { scrollTop: $("#chat-screen-content")[0].scrollHeight },
      // 1000
      // );
    }

    function channelRecentlyVisited(channelId: any) {
      state.item.error.erorList = [];
      state.item.hasError = false;
      // console.log("Get Account form Response:>>>", formId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
      };
      state.ui.isPostLoading.channelRecentlyVisited = true;
      ethitransService
        .channelRecentlyVisited(payLoad)
        .then((res: any) => {
          // console.log(
          //   "<<<channelRecentlyVisited Check:>>> ",
          //   res.data.data
          //   // sendoption
          // );
          // if (res.data.data) {
          //   state.data.channelRecentlyVisited = res.data.data;
          // }
          // getUserListByChannel();
          // $("#sendoption").modal("show");
          // state.invite.users = [];
          // state.data.additionalMessage = "";
          // state.data.privacy = {
          //   name: "Private to this channel",
          //   value: "private to this channel",
          //   code: "channel",
          // };
          // // getUserListByProjectChannel()
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.channelRecentlyVisited = false;
        });
    }
    function hideResendInvitationConfirmation() {
      $("#resendinvitationpendingmember").modal("hide");
    }
    function showResendInvitationConfirmation(memberSelected: any) {
      state.data.selectedPendingMember = {};
      state.data.selectedPendingMember = memberSelected;
      state.ui.errors.errorList.resendInvitation = [];
      state.ui.errors.hasError = false;
      $("#resendinvitationpendingmember").modal("show");
    }
    function hidePendingMemberRemove() {
      $("#removependingmember").modal("hide");
    }
    function selectPendingMemberForDeletes(member: any) {
      state.data.selectedPendingMember = {};
      state.data.selectedPendingMember = member;
      state.ui.errors.errorList.resendInvitation = [];
      state.ui.errors.hasError = false;
      $("#removependingmember").modal("show");
    }
    function deletePendingInvitation() {
      const member: any = state.data.selectedPendingMember;
      state.ui.errors.errorList.resendInvitation = [];
      state.ui.errors.hasError = false;
      // console.log("Delete Check >>> ", member)
      if (!member.uuid) return false;
      const payLoad = {
        uuid: member.uuid,
      };
      state.ui.isPostLoading.deletePendingInvitation = true;
      // member.delete = true;
      ethitransService
        .deletePendingInvitation(payLoad)
        .then((res: any) => {
          hidePendingMemberRemove();
          // console.log(
          //   "WOWO projectAccountFormListSearch Response : >>> ",
          //   res.data
          // );
          // state.templateList = res.data.docs ? res.data.docs : [];
          // member.delete = false;
          state.pendingList = state.pendingList.filter((e: any) => e != member);
          state.pendingMembersCount = state.pendingList.length;
          // channelPendingMemberRemapping(state.channelList, state.data.channelIndex, state.pendingList)
          // eslint-disable-next-line @typescript-eslint/camelcase
          // state.channelList[state.data.channelIndex].pending_members = state.pendingList
          // // eslint-disable-next-line @typescript-eslint/camelcase
          // state.channelList[state.data.channelIndex].pending_members_count = state.pendingList.length
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.resendInvitation)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.resendInvitation.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.deletePendingInvitation = false;
          // member.delete = false;
          // state.ui.postLoading.getAccountFormList = false;
        });
    }

    function reloadPage() {
      // The last "domLoading" Time //
      // eslint-disable-next-line no-var
      var currentDocumentTimestamp = new Date(
        performance.timing.domLoading
      ).getTime();
      console.log('aaaa', currentDocumentTimestamp)
      // Current Time //
      // eslint-disable-next-line no-var
      var now = Date.now();
      // Ten Seconds //
      // eslint-disable-next-line no-var
      var tenSec = 10 * 1000;
      // Plus Ten Seconds //
      // eslint-disable-next-line no-var
      var plusTenSec = currentDocumentTimestamp + tenSec;
      if (now > plusTenSec) {
        location.reload();
        // eslint-disable-next-line no-empty
      } else {
      }
    }



    function signDocument(uuid: any) {
      // state.ui.isLoading.clientDocumentList = true;
      // state.ui.isPostLoading.clickToRedirect=true;
      ethitransService
        .envelopeSign(uuid)
        .then((res: any) => {
          // window.location.href = res.data.data.url;
          store.dispatch("openDocusignDocument", res.data.data.url);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isPostLoading.clickToRedirect=false;
        });
    }

    function resendInvitation() {
      const memberSelected: any = state.data.selectedPendingMember;
      state.ui.errors.errorList.resendInvitation = [];
      state.ui.errors.hasError = false;
      // console.log("CHECK THE SELECTED >>> ", memberSelected);

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        invitation_id: memberSelected.uuid,
      };
      // memberSelected.resend = true;
      state.ui.isPostLoading.resendInvitation = true;
      ethitransService
        .invitationResend(payLoad)
        .then((res: any) => {
          console.log("invitationResend:>>> ", res.data.data);
          hideResendInvitationConfirmation();
          // state.roleList = filteredRoles ? filteredRoles : [];
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.resendInvitation)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.resendInvitation.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // memberSelected.resend = false;
          state.ui.isPostLoading.resendInvitation = false;
          // state.isLoading.getProjectRoleList = false;
        });
    }

    watch(
      () => routerQuery.params,
      (newvalue, oldvalue) => {

        if (newvalue) {
          getCommunicationList(channelId);
        }
      }
    );

    watch(
      () => routerQuery.params.channelId,
      (newvalue, oldvalue) => {

        if (newvalue) {
          state.messageStatus = true;
          getCommunicationList(channelId);
          location.reload();
        }
      }
    );


    function getReadyImageListToUpload(data: any) {
      state.imageList = data;
    }

    function getReadyThreadImageListToUpload(data: any) {
      state.threadImageList = data;
    }

    function onEditChatInput(data: any) {
      if (!data) return false;
      // data = common.replaceURLs(data);
      console.log("Check >>> ", data);
      document.addEventListener("keydown", function (event: any) {
        if (event.keyCode === 13 && !event.shiftKey) {
          if (
            state.communicationList[
              state.data.selectedChatIndex
            ].message.endsWith("<p>&nbsp;</p>")
          ) {
            state.communicationList[state.data.selectedChatIndex].message =
              state.communicationList[
                state.data.selectedChatIndex
              ].message.replace(new RegExp("<p>&nbsp;</p>", "g"), "");
            console.log("TEST for Enter  After", data);
          }

          state.data.typeEditDisabled = true;
          // alert('Enter is pressed!');
          sendEditedCommunication(
            state.communicationList[state.data.selectedChatIndex]
          );
        }
      });
    }
    function onThreadReplyInput(data: any) {
      if (!data) return false;
      // data = common.replaceURLs(data);
      console.log("Check >>> ", data);
      document.addEventListener("keyup", function (event: any) {
        if (event.keyCode === 13 && !event.shiftKey) {
          if (state.data.replyMessage.endsWith("<p>&nbsp;</p>")) {
            state.data.replyMessage = state.data.replyMessage.replace(
              new RegExp("<p>&nbsp;</p>", "g"),
              ""
            );
            console.log("Reply for Enter  After", data);
          }

          state.data.typeEditDisabled = true;
          // alert('Enter is pressed!');
          replyMessage();
        }
      });
    }

    // window.addEventListener('scroll',()=>{
    // // console.log(window.scrollY) //scrolled from top
    // // console.log(window.innerHeight) //visible part of screen
    // if(window.scrollY + window.innerHeight >=
    // document.documentElement.scrollHeight){
    // // alert("SCROLLED");
    // // state.data.page = state.data.page + 1
    // getCommunicationList(channelId);
    // }

    // })
    // window.addEventListener('scroll',()=>{

    //     const myScrollFunc = function () {
    //         const y = window.scrollY;
    //         if (y >= 100) {
    //            console.log("SHOW");
    //         } else {
    //           console.log("HIDE");
    //         }
    //     };

    //     window.addEventListener("scroll", myScrollFunc);
    // });

    function scrollCheck() {
      // const myID: any = document.getElementById("chat-body-content");
      //   const scrollTop = myID.scrollTop;
      //   if(scrollTop + myID.innerHeight >= myID.scrollHeight){
      //     alert("END")
      //   }
      // const element: any = scrollCompoment.value
      // if(element.getBoundingClientRect().bottom < window.innerHeight){
      //   getCommunicationList(channelId);
      // }
      // const objDiv: any = window.document.getElementById("chat-body-content");
      //   objDiv.addEventListener('scroll', function (e: any) {
      //     console.log("CHECK THE >>> ", objDiv.scrollTop)
      //     if(objDiv.scrollTop === objDiv.scrollHeight){
      //       alert("Hello")
      //     }
      //   })
    }

    watch(
      // () => watchTour,
      () => store.state.takAtour,
      (newvalue, oldvalue) => {
        if (newvalue === true) {
          // console.log("check >>>", newvalue)
          toggleTourGuide();
        }
      }
    );
    function handleScroll(event: any) {
      if (state.messageStatus) {
        return;
      }

      console.log("p1");

      if (routerQuery.query.page) {
        console.log("p2");

        const pageNum: any = routerQuery.query.page;
        state.data.page = parseInt(pageNum);
        // payLoad.page = parseInt(pageNum);
      }
      // console.log("CHECK TOP", event.target.scrollTop);
      // scrollHeight
      // if (event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight<=0) {
      //         alert('Bottom');
      //     }

      if (
        event.target.clientHeight + event.target.scrollTop >=
        event.target.scrollHeight && !routerQuery.query.from
      ) {
        console.log("p3");

        state.data.scrollBottom = true;
        state.data.isCommunicationFile = "";
        //   // alert('button');
        if (routerQuery.query.page && routerQuery.query.communicationId) {
          console.log("p4");

          router.push({
            name: "admin-channel",
            params: { channelId: channelId },
          });
        }
        if (state.data.page > 1) {
          //  console.log("<<<< button state before :>>>>",state.data.page);
          // //  state.data.page--;
          //  console.log("<<<< button state after :>>>>",state.data.page);
          //  console.log("<<<< button last page number >>>>>", state.data.lastPage);

          if (state.data.page <= state.data.lastPage) {
            // alert('HAPPY 1')
            // alert("bootom");
            state.data.page--;
            if (state.data.page != 0) {
              if (state.data.scrolledPages.includes(state.data.page))
                return false;
              console.log("p5");
              getCommunicationList(channelId);
              //  state.isReplyMessage=false;
            }
          }
        }
        // alert(event.target.scrollHeight);
        // console.log("BOTTOM SCROLL");
        // state.data.scrollTop = false;
      } else {
        if (routerQuery.query.page && routerQuery.query.communicationId) {
          router.push({
            name: "admin-channel",
            params: { channelId: channelId },
          });
        }
        if (event.target.scrollTop === 0) {
          state.data.isCommunicationFile = "";
          // alert("Hit 1");

          // alert('Top');
          // state.data.scrollTop = false;
          // console.log("<<<< CHECK SCROLL >>>>>", window.scrollY);
          state.data.scrollTop = true;
          state.firstScroll = true;
          console.log("<<<< Page BEFORE number :>>>>", state.data.page);
          console.log("<<<< last page CHeck >>>>>", state.data.lastPage);
          if (state.data.page < state.data.lastPage) {
            state.data.page++;
            console.log(state.data.scrolledPages.includes(state.data.page));
            console.log("state.data.scrolledPages", state.data.scrolledPages);
            console.log("state.data.page", state.data.page);
            if (state.data.scrolledPages.includes(state.data.page))
              return false;
            // state.data.page++;
            console.log("<<<< After check number :>>>>", state.data.page);
            console.log("p6");
            // if(state.data.scrolledPages.includes(state.data.page)) return false;
            getCommunicationList(channelId);
            // state.isReplyMessage=false;
          }
          //  console.log("<<<< PAGE after  >>>>>", state.data.page);

          //  resetheight()
          // scrollToLast()
        }
        // if(state.data.scrolledPages.includes(state.data.page)){
        //   alert("Already")
        // }
      }
      state.data.scrolledPages.push(state.data.page);
      state.data.scrolledPages = state.data.scrolledPages.filter(
        (x: any, i: any, a: any) => a.indexOf(x) == i
      );
      if (pageQuery.value != 0) {
        console.log("TEST ON Value not");
        const pageNum: any = routerQuery.query.page;
        const currentPage = parseInt(pageNum);
        state.data.scrolledPages = state.data.scrolledPages.filter(
          (e: any) => e != currentPage
        );
        // for(let i = 1; i<currentPage; i++){
        //   // alert(i)
        //      state.data.scrolledPages = state.data.scrolledPages.filter((e: any) => e != i);

        // }
      }
      //   const restPageCheck: any= routerQuery.query.page;
      //   const currentPage = parseInt(restPageCheck);
      // console.log("PAGE >>> ", currentPage)
      // console.log("BEFORE ARRAY CHECK >>> ", state.data.scrolledPages)
      //   state.data.scrolledPages = state.data.scrolledPages.filter((e: any) => e != currentPage);
      // console.log("Final CHECK >>> ", state.data.scrolledPages)
    }

    function goToChatChannel(data: any) {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_id: data.communication_id ? data.communication_id : "",
      };
      // state.postLoading = true;
      ethitransService
        .communicationPage(payLoad)
        .then((res: any) => {
          state.data.pageNumber = res.data.data;
        })
        .catch((error: any) => {
          // console.log(error);
        })
        .finally(() => {
          // state.postLoading = false;
          if (data.response_type) {
            router.push({
              name: "admin-channel",
              params: { channelId: channelId },
              // eslint-disable-next-line @typescript-eslint/camelcase
              query: {
                communicationId: data.communication_id,
                page: state.data.pageNumber,
                reply: data.id,
                from: "file"
              },
            });
          } else {
            router.push({
              name: "admin-channel",
              params: { channelId: channelId },
              // eslint-disable-next-line @typescript-eslint/camelcase
              query: {
                communicationId: data.communication_id,
                page: state.data.pageNumber,
                from: "file"
              },
            });
          }
        });
      $(".chat-screen").removeClass("toggled");
    }
    // window.document.getElementById("chat-body-content").addEventListener("scroll", scrollCheck);

    function previous() {
      if (state.currentIndex > 0) {
        state.currentIndex--;
        state.isPreV = true;
      }
      if (state.currentIndex == 0) {
        state.isPreV = false;
      }
      if (state.currentIndex < state.slides.length - 1) {
        state.isNext = true;
      } else {
        state.isNext = false;
      }
    }
    function next() {
      if (state.currentIndex < state.slides.length - 1) {
        state.currentIndex++;
      }
      if (state.currentIndex == state.slides.length - 1) {
        state.isNext = false;
      }
      if (state.currentIndex > 0) {
        state.isPreV = true;
      } else {
        state.isPreV = false;
      }
    }


    function validationPublicUser() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (!state.inviteAsPublic && !state.inviteAsPublic.length) {
        state.errorList.push("At least 1 user is required.");
      } else {
        state.inviteAsPublic.forEach((item: any) => {
          if (item.email && !validationService.isValidEmail(item.email)) {
            state.errorList.push("Invalid email.");
          }
          if (!item.email) {
            state.errorList.push("Email must be required.");
          }
          //  if (!item.name) {
          //   state.errorList.push("Name must be required.");
          // }
          if (item.smsNotification && !item.phone) {
            state.errorList.push("Mobile number must be required.");
          }
          //  if(!item.smsNotification && !item.emailNotification) {
          //   state.errorList.push("Email or SMS one method must be required.");
          // }
        });
      }
      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }

      // console.log("CHECK BOOLEAN", state.isErrorStatus);
    }

    function inviteAllPublicUser() {
      validationPublicUser();
      if (state.errorList.length != 0 || state.isErrorStatus) return false;
      const inviteChannels = [];
      inviteChannels.push(channelId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.channel.project.uuid,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channels: inviteChannels,
        users: common.removeDuplicate(state.invite.filteredUsers),
        invites: state.inviteAsPublic,
      };
      // if(state.invite.filteredUsers.length === 0 || state.invite.filteredInvites.length === 0) return false;
      console.log("CHECK PAYLOAD ON API CALL", payLoad);
      state.isLoading.inviteAllUsers = true;
      ethitransService
        .invitationPublicUser(payLoad)
        .then((res: any) => {
          hideAddMember();
          showSuccessModal();
          state.data.invitationVerifyMsg = "";
          state.data.invitationVerify.newMemberList = [];
          state.data.invitationVerify.registeredMemberList = [];
          state.data.invitationVerify.pendingMemberList = [];
          state.invitationMessage = res.data.message ? res.data.message : "";
          getCommunicationList(channelId);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.isLoading.inviteAllUsers = false;

          state.data.inviteAsPublicUser = false;
        });
    }
    function deletePublicMember(item: any) {
      state.inviteAsPublic = state.inviteAsPublic.filter((e: any) => e != item);
    }

    function changeInvitePublicMember() {
      validationPublicUser();
      if (state.errorList.length != 0 || state.isErrorStatus) return false;
      state.inviteAsPublic.push({
        name: "",
        email: "",
        phone: "",
        smsNotification: false,
        mailNotification: true,
      });

    }
    function toggleZoom() {
      state.isZoomed = !state.isZoomed

    }
    function clickImage(communication: any, imageIndex: any) {

      imgsRef.value = [];
      state.allSliderImage = [];

      communication.forEach((file: any) => {
        state.allSliderImage.push(file.file_link);

        // if (file.file_type == 'png' || file.file_type == 'jpg' || file.file_type == 'jpeg') {
        //   state.allSliderImage.push(file.file_link);
        // }
      });
      imgsRef.value = state.allSliderImage;
      console.log(imgsRef.value)
      setTimeout(() => {
        indexRef.value = imageIndex;
        visibleRef.value = true;
      }, 20);

    }

    function isImgUrl(url: any) {
      return /\.(jpg|jpeg|png|webp|avif|gif)$/.test(url)
    }


    function imageSliderNext(index: any, allSliderImage: any) {
      return new Promise((resolve, reject) => {
        const newIndex = index + 1;
        if (isImgUrl(state.allSliderImage[newIndex])) {
          return resolve(newIndex);
        } else {
          if (state.allSliderImage[newIndex + 1]) {
            imageSliderNext(newIndex + 1, state.allSliderImage);
          } else {
            return resolve(-1);
          }

        }
      });

    }


    function imageSliderPre(index: any, allSliderImage: any) {
      return new Promise((resolve, reject) => {
        const newIndex = index - 1;
        if (isImgUrl(state.allSliderImage[newIndex])) {
          return resolve(newIndex);
        } else {
          if (state.allSliderImage[newIndex - 1]) {
            imageSliderPre(newIndex - 1, state.allSliderImage);
          } else {
            return resolve(-1);
          }
        }
      });

    }



    function handlePrev(oldIndex: any, newIndex: any) {
      if (isImgUrl(state.allSliderImage[newIndex])) {
        indexRef.value = newIndex;
        visibleRef.value = true;
      } else {
        const tempIndex = newIndex - 1;
        if (state.allSliderImage[tempIndex]) {
          if (isImgUrl(state.allSliderImage[tempIndex])) {
            indexRef.value = tempIndex;
            visibleRef.value = true;
          } else {
            imageSliderPre(tempIndex, state.allSliderImage).then((index: any) => {
              if (index != -1) {
                indexRef.value = index;
                visibleRef.value = true;
              } else {
                visibleRef.value = false;
              }
            });
          }
        } else {
          visibleRef.value = false;
        }

      }
    }



    function handleNext(oldIndex: any, newIndex: any) {

      if (isImgUrl(state.allSliderImage[newIndex])) {
        indexRef.value = newIndex;
        visibleRef.value = true;
      } else {
        const tempIndex = newIndex + 1;
        if (state.allSliderImage[tempIndex]) {
          if (isImgUrl(state.allSliderImage[tempIndex])) {
            indexRef.value = newIndex + 1;
            visibleRef.value = true;
          } else {
            imageSliderNext(tempIndex, state.allSliderImage).then((index: any) => {
              if (index != -1) {
                indexRef.value = index;
                visibleRef.value = true;
              } else {
                visibleRef.value = false;
              }
            });
          }
        } else {
          visibleRef.value = false;

        }

      }
    }


    function sendMessageFromEnvelope(envlopeId: any) {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        envelope_id: envlopeId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
        event: "send"
      };
      // state.postLoading = true;
      ethitransService
        .sendMessageFromEnvelope(payLoad)
        .then((res: any) => {
          // state.data.pageNumber = res.data.data;
        })
        .catch((error: any) => {
          // console.log(error);
        })
        .finally(() => {
          router.push({
            name: "admin-channel",
            params: { channelId: channelId },
          });
        });
    }

    function sendMessageSignFromEnvelope(envlopeId: any) {
      const payLoad = {
        id: envlopeId,
        event: "signing_complete"
      };
      // state.postLoading = true;
      ethitransService
        .sendMessageSignFromEnvelope(payLoad)
        .then((res: any) => {
          // state.data.pageNumber = res.data.data;
        })
        .catch((error: any) => {
          // console.log(error);
        })
        .finally(() => {
          router.push({
            name: "admin-channel",
            params: { channelId: channelId },
          });
        });
    }


    function handleHide() {
      visibleRef.value = false;

    }

    function docusignShareDocumentDetails(uuid: any) {
      ethitransService
        .docusignShareDocumentDetails(uuid)
        .then((res: any) => {
          state.data.docusignShareDocumentDetail = res.data.data;
          state.data.docusignShareDocumentData = res.data.data.recipients.find((e: any) => e.email == state.email);

        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isPostLoading.uploadClientDocument = false;
        });
    }

    function showDocusignListModel() {
      state.data.showDocusignDocumentListModel = !state.data.showDocusignDocumentListModel;
      $("#showDocusignListModel").modal("show");
    }
    function closeDocusignListModel() {
      state.data.showDocusignDocumentListModel = !state.data.showDocusignDocumentListModel;
      $("#showDocusignListModel").modal("hide");
    }


    function truncate(data: any, limit: any) {
      if (data.length > limit) {
        return data.substring(0, limit) + "...";
      }
      return data;
    }

    function showDocusignShareDocumentModel(uuid: any, communication: any) {
      // state.data.docusignShareDocumentData = communication;
      docusignShareDocumentDetails(uuid);
      state.data.showShareDocusignDocumentModel = !state.data.showShareDocusignDocumentModel;
      $("#showDocusignShareDocument").modal("show");
    }
    function closeDocusignShareDocumentModel() {
      state.data.showShareDocusignDocumentModel = !state.data.showShareDocusignDocumentModel;
      state.data.docusignShareDocumentDetail = {};
      state.data.docusignShareDocumentData = {};
      $("#showDocusignShareDocument").modal("hide");
    }

    watch(
      () => state.data.inviteAsPublicUser,
      () => {
        if (!state.data.inviteAsPublicUser) return false;
        // console.log("Guest Invitation Selected");
        state.existingMember = "";
        state.invite.users = [];
        state.inviteAsPublic = [];
        state.invite.invites = [];
        state.inviteAsPublic.push({
          name: "",
          email: "",
          phone: "",
          smsNotification: false,
          mailNotification: true,
        });
      }
    );

    const docuSingSignData = computed(() => store.state.saveSignedData ? store.state.saveSignedData : {});

    watch(
      () => store.state.saveSignedData,
      (newvalue, oldvalue) => {
        if (newvalue) {
          state.ui.isLoading.docuSignIframe = true;
          state.data.docuSignSignUrl = store.state.saveSignedData;
        } else {
          state.ui.isLoading.docuSignIframe = false;
        }
      }
    );

    watch(
      () => store.state.openSignDocument,
      (newvalue, oldvalue) => {
        if (newvalue) {
          state.ui.isLoading.docuSignIframeOpen = true;
          state.data.docuSignSignOpenUrl = store.state.openSignDocument;
        } else {
          state.ui.isLoading.docuSignIframeOpen = false;
        }
      }
    );
    function hideUpdateSubscriptionModal() {
      // alert("Hello")
      state.ui.isActive.upgradeSubscription = !state.ui.isActive.upgradeSubscription;
    }

    function createPrivateChannelValidation() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (!state.invite.filteredUsers.length) {
        state.errorList.push("Invite member is required.");
      }
      if (state.inviteMember) {
        if (!validationService.isValidEmail(state.inviteMember)) {
          state.errorList.push("Invalid email.");
        }
      }

      if (state.invite.invites.some((item: any) => item.first_name == '' || item.last_name == '')) {
        state.invite.invites.forEach((invite) => {
          if (invite.first_name == '') {
            invite.fNameError = true;
          } else {
            invite.fNameError = false;
          }
          if (invite.last_name == '') {
            invite.lNameError = true;
          } else {
            invite.lNameError = false;
          }
        })
        state.errorList.push("First Name Last name is requred all invitation.");
      }

      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }

      // console.log("CHECK BOOLEAN", state.isErrorStatus);
    }

    function createPrivateChannel() {
      state.errorList = []
      allUsers.value = [];
      state.invite.users = [];
      state.invite.invites = [];
      state.userList.forEach((memb: any) => {
        if (memb.user.email != localStorage.getItem("current_user_email")) {
          allUsers.value.push({
            'id': memb.user.uuid,
            'label': memb.user.email,
            'email': memb.user.email,
            'full_name': memb.user.full_name,
          })
        }

      })
      $("#createPrivateChannel").modal("show");
    }

    function addMemberInPrivateChannel(data: any) {
      state.errorList = []
      allUsers.value = [];
      state.invite.users = [];
      state.invite.invites = [];
      // state.userList.forEach((memb: any) => {
      //   if (memb.user.email != localStorage.getItem("current_user_email")) {
      //     allUsers.value.push({
      //       'id': memb.user.uuid,
      //       'label': memb.user.email,
      //       'email': memb.user.email,
      //       'full_name': memb.user.full_name,
      //     })
      //   }

      // })
      getUserList();
      $("#createPrivateChannel").modal("show");
    }

    function hidePrivateChannel() {
      allUsers.value = [];
      state.invite.users = [];
      state.invite.invites = [];
      state.errorList = []
      $("#createPrivateChannel").modal("hide");
    }

    function createPrivateChannelGroup() {
      state.invite.filteredUsers = [];

      state.invite.users.forEach((item: any) => {
        // console.log("CHECK email", item);
        state.invite.filteredUsers.push(item);
      });
      state.invite.invites.forEach((item: any) => {
        // console.log("CHECK email", item);
        state.invite.filteredUsers.push(item);
      });
      createPrivateChannelValidation();
      if (
        state.errorList.length != 0 ||
        state.isErrorStatus ||
        state.isLoading.inviteAllUsers
      )
        return false;

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.projectId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.channel.uuid,
        users: common.removeDuplicate(state.invite.filteredUsers),
      };
      // if(state.invite.filteredUsers.length === 0 || state.invite.filteredInvites.length === 0) return false;
      console.log("CHECK PAYLOAD ON API CALL", payLoad);
      state.isLoading.inviteAllUsers = true;
      ethitransService
        .createPrivateChannelGroup(payLoad)
        .then((res: any) => {
          hidePrivateChannel();
          state.data.successFulMessage = "Private Channel Create Successful";
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;

        })
        .catch((error: any) => {
          // console.log(error);

          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            if (res) {
              if (res.name) {
                state.errorList.push(res.name[0]);
              } else if (res.description) {
                state.errorList.push(res.description[0]);
              } else {
                console.log("CHECK for Error >>>", res);
                state.errorList = res.error;
              }
            }
          });
        })
        .finally(() => {
          state.isLoading.inviteAllUsers = false;
          state.invite.filteredUsers = [];
        });
    }

    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
    }
    function exportCommunicationPdfList() {

      ethitransService
        .exportCommunicationPdfList(state.channel.uuid)
        .then((res: any) => {
          console.log('res', res);
          state.data.exportPdfs = res.data.data;
        })
        .catch((error: any) => {
          // console.log(error);

          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            if (res) {
              if (res.name) {
                state.errorList.push(res.name[0]);
              } else if (res.description) {
                state.errorList.push(res.description[0]);
              } else {
                console.log("CHECK for Error >>>", res);
                state.errorList = res.error;
              }
            }
          });
        })
        .finally(() => {
          // state.invite.filteredUsers = [];
        });
    }

    function exportCommunicationPdf() {
      state.data.isExport = true;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.channel.uuid,
        user: localStorage.getItem("current_user_role"),
      };
      ethitransService
        .exportCommunicationPdf(payLoad)
        .then((res: any) => {
          state.data.successFulMessage = "You will be notified once the PDF is generated and ready to use.";
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;

        })
        .catch((error: any) => {
          // console.log(error);

          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            if (res) {
              if (res.name) {
                state.errorList.push(res.name[0]);
              } else if (res.description) {
                state.errorList.push(res.description[0]);
              } else {
                console.log("CHECK for Error >>>", res);
                state.errorList = res.error;
              }
            }
          });
        })
        .finally(() => {
          exportCommunicationPdfList();
          state.data.isExport = false;
        });
    }


    function stopRecording() {
      let timeoutId: number | undefined; // Define the type explicitly
      recognition.stop();
      clearTimeout(timeoutId); // Clear the timeout
      isRecording.value = false;
    }

    function startRecording() {
      isRecording.value = true;
      recognition = new window.webkitSpeechRecognition();
      recognition.lang = 'en-US';
      recognition.continuous = true;
      recognition.interimResults = false;
      recognition.start();

      let timeoutId: number | undefined; // Define the type explicitly

      recognition.onresult = (event: any) => {
        const transcript = event.results[event.results.length - 1][0].transcript;
        transcription.value = transcript;
        state.message = state.message + transcript;

        // Reset the timeout whenever there's a new result
        clearTimeout(timeoutId);
        timeoutId = setTimeout(stopRecording, 30000); // 5 seconds timeout
      };

      recognition.onend = () => {
        isRecording.value = false;
      };

      // Set a timeout to stop recording after 30 seconds of inactivity
      timeoutId = setTimeout(stopRecording, 30000); // 30 seconds timeout
    }


    function stopReplyRecording() {
      let timeoutId: number | undefined; // Define the type explicitly
      recognition.stop();
      clearTimeout(timeoutId); // Clear the timeout
      isReplyRecording.value = false;
    }

    function startReplyRecording() {
      isReplyRecording.value = true;
      recognition = new window.webkitSpeechRecognition();
      recognition.lang = 'en-US';
      recognition.continuous = true;
      recognition.interimResults = false;
      recognition.start();

      let timeoutId: number | undefined; // Define the type explicitly

      recognition.onresult = (event: any) => {
        const transcript = event.results[event.results.length - 1][0].transcript;
        transcription.value = transcript;
        state.data.replyMessage = state.data.replyMessage + transcript;

        // Reset the timeout whenever there's a new result
        clearTimeout(timeoutId);
        timeoutId = setTimeout(stopRecording, 30000); // 5 seconds timeout
      };

      recognition.onend = () => {
        isReplyRecording.value = false;
      };

      // Set a timeout to stop recording after 30 seconds of inactivity
      timeoutId = setTimeout(stopRecording, 30000); // 30 seconds timeout
    }


    onMounted(() => {
      if (routerQuery.query.view) {
        signDocument(routerQuery.query.view);
      }
      channelRecentlyVisited(channelId);
      state.email = localStorage.getItem("current_user_email");
      state.data.imageBaseUrl = config.imageBaseUrl;
      state.ui.isLoading.communicationList = true;
      getCommunicationList(channelId);

      if (pageQuery.value != 0) {
        state.data.page = pageQuery.value;
      }

      watch(
        () => state.data.search,
        () => {
          if (state.data.search.length >= 1) {
            state.data.clearBtn = true;
          } else {
            state.data.clearBtn = false;
          }
        }
      );

      window.addEventListener('message', event => {
        // debugger
        if (event.data.event === 'taskDone') {
          debugger
          if (event.data.data.envelopeId != undefined) {
            $("#showDocusignShareDocument").modal("hide");
            sendMessageFromEnvelope(event.data.data.envelopeId)
            state.ui.isLoading.docuSignIframe = false;
            state.ui.isLoading.docuSignIframeOpen = false;
            // getCommunicationList(channelId);
          }
          if (event.data.data.sign_id != undefined) {
            $("#showDocusignShareDocument").modal("hide");
            sendMessageSignFromEnvelope(event.data.data.sign_id)
            state.ui.isLoading.docuSignIframeOpen = false;
            state.ui.isLoading.docuSignIframe = false;
            // getCommunicationList(channelId);

          }

        }
      });
      // setTimeout(reloadPage, 1000);
      const userAgent = navigator.userAgent;
      state.isFirefox = /Firefox/.test(userAgent);
    });
    return {
      state,
      pageQuery,
      hideUpdateSubscriptionModal,
      // setRouteQueryPage,
      resetActive,
      handleScroll,
      scrollCheckChat,
      scrollCheck,
      loadData,
      setMentionMembers,
      isKeyPressed,
      relySelectedMessage,
      clearEditor,
      replyChatCommunicationEnterSetting,
      editChatCommunicationEnterSetting,
      enableSendMsg,
      enterSetting,
      enableEnterKey,
      chatMessageEnterSetting,
      onEditorReady,
      dragOverHandler,
      someEventHander,
      onThreadReplyInput,
      onEditChatInput,
      // onFocus,
      hideRenameAttachmentModal,
      // onEditorInput,
      // preventDefaults,
      toggleActive,
      toggleTourGuide,
      latestEnabledTags,
      getReadyImageListToUpload,
      readyToUploadImage,
      checkForDisabledTags,
      masterTagListForValidation,
      enableSelectedFileTag,
      addTag,
      masterTags,
      masterTagList,
      hideDigitizedtemplate,
      hideTemplateupload,
      pusherConnectionCheck,
      addMember,
      removeMember,
      renameDoc,
      communicationTyping,
      renameSelectedAttachment,
      resendInvitation,
      showResendInvitationConfirmation,
      hideResendInvitationConfirmation,
      deletePendingInvitation,
      hidePendingMemberRemove,
      selectPendingMemberForDeletes,
      downloadFile,
      deleteCommunication,
      hideDeleteCommunicationModal,
      selctedCommunicationForDelete,
      channelCommunicationTyping,
      // setPreviewImage,
      backInvitation,
      openPdf,
      verifyInvitation,
      channelStatus,
      downloadProjectFiles,
      // connect,
      // chatApp,
      // chatCommunication,
      resizeFormTextArea,
      showPreviewLink,
      readImageInThread,
      replyMessage,
      scrollToLastThread,
      scrollToTop,
      onDocumentUploadThread,
      replyCommunication,
      closeReplyCommunication,
      reloadPage,
      accountFormRetrieve,
      resize,
      resetheight,
      showWorkFlowLink,
      clientDocumentRetrieveWorkflow,
      changeExistingMemberInSendOption,
      getUserListByChannel,
      validateSendDigitizedForm,
      sendDigitizedForm,
      // getUserListByProjectChannel,
      sendOptionBack,
      clientDocumentRetrieve,
      selectDigitizeForm,
      showDigitizedTemplate,
      clearSelectedSignature,
      viewFormRetrieveByIdForSend,
      viewSelectForm,
      hideViewTemplateModal,
      detailsModal,
      editCommunicationData,
      communicationList,
      scrollAtButton,
      redirectToDynamicForm,
      formPrivacyMessage,
      emailValidationCustomEdit,
      updateEditFormElementsValidation,
      sendCreateNewForm,
      radioChange,
      createNewFormEmailValiation,
      addElementsCreateNewValiation,
      showSendFormModal,
      deleteSelectedElementOfCreateForm,
      hideDeleteSelectedElementInCreateNew,
      showNewElementEdit,
      showNewElementDelete,
      hideConfirmEditForm,
      hideNewFormConformation,
      showNewFormConformation,
      showCustomFormConformation,
      selectedForm,
      showDeleteElement,
      cancelDeleteSelectedForm,
      confirmDeleteElement,
      getAccountFormRetrieveByIdForSend,
      editNewFormValiation,
      cancelEditSelectedForm,
      editSelectedForm,
      saveCustomSelectedForm,
      addTextFieldInCustomEdit,
      addOptionsinCustomEdit,
      addSelectOptionsinCustomEdit,
      addCheckBoxinCustomEdit,
      removeCheckBoxinCustomEdit,
      removeSelectOptioninCustomEdit,
      removeOptionsinCustomEdit,
      showEditFormName,
      showEditElement,
      showUserModal,
      showSendTemplate,
      sendTemplateFile,
      getAccountFormRetrieveById,
      selectForm,
      getCommunicationList,
      getChannelFormList,
      createCommunicationList,
      uploadAttachmentByChannel,
      uploadReplyAttachmentByChannel,
      onDocumentChange,
      addNewMemberToChannel,
      downloadFiles,
      closeChannel,
      readImage,
      tempImageList,
      removeSelectedImage,
      hideAddMember,
      removeSelectedMember,
      changeExistingMember,
      changeInviteMember,
      removeSelectedUser,
      inviteAllUsers,
      showSuccessModal,
      validateInviteForm,
      scrollToLast,
      isUserStatus,
      templateList,
      hideEditTemplateModal,
      hideTemplateModal,
      selectEditForm,
      channelCommunicationForm,
      changeExistingMemberInForm,
      showFormView,
      hideFormView,
      showTextFieldAdd,
      addTextField,
      addSelectOptions,
      removeCheckBox,
      removeSelectOption,
      removeOptions,
      addOptions,
      addCheckBox,
      createNewForm,
      showEditTemplateModal,
      showMentionUser,
      titleSectionValiation,
      createNewFormValiation,
      selectedElement,
      showCustomEdit,
      showCreateNewForm,
      showNewTitleInCreateFormEdit,
      hideEditCreateNewForm,
      updateEditNewForm,

      // communicaion edit,
      sendEditedCommunication,
      cancelSelectedEdit,
      selectedCommunicationImageToDelete,
      selctedCommunication,
      viewPdf,
      hideDeleteSelectedImage,
      removeSelectedCommunicationImage,
      getAccountFormResponseByEmailAndId,
      sentToUsers,
      userFormClick,
      selectedUserAccountFormResponse,
      generateEdocStatement,
      submitUserInputForm,
      downloadEdocPdf,
      userFormData,
      canvasSet,
      setCanvas,
      showHideCalender,
      hidecalender,
      userformInputValidationCheck,
      nextPage,
      viewSelectSharedDocuments,
      selectedDocumentToDelete,
      deleteSharedDocument,
      selectedDocumentToEdit,
      renameSelectedSharedAttachment,
      renameAttachment,
      removeMemberFromMemberList,
      closeInvitationModal,
      removeActiveMemberCount,
      mideHeight,
      getInstanceMessageList,
      goToChatChannel,
      searchAllMessage,
      searchClearMessage,
      replyCounter,
      shareFileCounter,
      showImageModel,
      showImageModelHide,
      previous,
      next,
      validationPublicUser,
      deletePublicMember,
      changeInvitePublicMember,
      inviteAllPublicUser,
      showReplyImageModel,
      showReplyImageModelHide,
      hideForwardMessage,
      forwardMessage,
      forWardCommunication,
      communicationDetails,
      removeForwardFile,
      toggleZoom,
      visibleRef,
      indexRef,
      imgsRef,
      show,
      onHide,
      clickImage,
      handlePrev,
      handleNext,
      handleHide,
      hideDeleteReplyMessage,
      deleteReplyMessage,
      deleteReplyCommunication,
      selctedReplyCommunication,
      sendEditedReplyCommunication,
      isImgUrl,
      imageSliderNext,
      imageSliderPre,
      closeDocusignListModel,
      showDocusignListModel,
      sendMessageFromEnvelope,
      showDocusignShareDocumentModel,
      closeDocusignShareDocumentModel,
      docusignShareDocumentDetails,
      sendMessageSignFromEnvelope,
      docuSingSignData,
      signDocument,
      truncate,
      showFullText,
      allUsers,
      selectedUsers,
      selectToChangeUsers,
      getReadyThreadImageListToUpload,
      removeSelectedThreadImage,
      removeSelectedReplyCommunicationImageAttached,
      deleteReplyImage,
      hideDeleteReplySelectedImage,
      createPrivateChannel,
      hidePrivateChannel,
      createPrivateChannelGroup,
      createPrivateChannelValidation,
      addMemberInPrivateChannel,
      isRecording,
      transcription,
      startRecording,
      stopRecording,
      exportCommunicationPdf,
      closeSuccessCustomModal,
      exportCommunicationPdfList,
      stopReplyRecording,
      startReplyRecording,
      isReplyRecording
    };
  },
});
