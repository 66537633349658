
import { defineComponent, reactive, onMounted, onBeforeMount, watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ethitransService } from "@/services/EthitransService";
import AppointmentCreateModal from "@/components/modal/AppointmentCreateModal.vue";
import AppointmentDetailModal from "@/components/modal/AppointmentDetailModal.vue";
import AppointmentImportModal from "@/components/modal/AppointmentImportModal.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import { common } from "@/common/services/Common";

import UpgradeSubscriptionModal from '@/common/UpgradeSubscriptionModal.vue'


import TostCalender from "@/components/todo/TostCalender.vue";
// import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import moment from "moment";
import { useStore } from "vuex";
import introJs from "intro.js";

export default defineComponent({
  name: "Appointment",
  components: {
    AppointmentCreateModal,
    AppointmentDetailModal,
    AppointmentImportModal,
    TostCalender,
    // VueCal,
    SuccessfulModalCustom,
    UpgradeSubscriptionModal,
  },
  setup() {
    const childComponentKey = ref(0);
    // 1/5/6/3 ==>5
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const state: any = reactive({
      data: {
        timeslotEvents: [] as any,
        subscriptionDetails: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          appointment_numbers: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          archive_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_file_size: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_message_history: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          estimation_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          export_channel_pdf: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          files_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          folder_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          guests_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          import_option: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          invoice_payment: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          member_in_smart_contract: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          member_per_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          members_per_appointment: 0,
          name: "Silver",
          // eslint-disable-next-line @typescript-eslint/camelcase
          pdf_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          phase_per_smart_contract: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          private_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sync_option: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          task_per_phase: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          template_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          total_channel_member: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          total_estimation: 0,
        } as any,
        appointmentLoad: false,
        showAppointCreateModel: false,
        showAppointDetailModel: false,
        showAppointImportModel: false,
        appointmentList: [] as any,
        appointmet: [] as any,
        showSuccesCustomModal: false,
        SuccessFulMessage: "",
        eventId: 0,
      },
      ui: {
        isActive: {
          upgradeSubscription: false,
        },
        isLoading: {
          getAccountFormList: false,
          packageDetailsByUser: false,
        },
        isPostLoading: {
          createUserIssue: false,
        },
      },
      error: {
        hasError: false,
        erorList: {
          uploadClientDocument: [] as any,
          errorsList: [] as any,
        },
      },
    });
    function toggleTourGuide() {
      if (store.state.showTourGuide) {
        introJs()
          .setOptions({
            steps: [
              {
                title: "Welcome ",
                intro:
                  "Welcome to Appointment! 👋 <iframe width='426' height='240' src='https://www.youtube.com/embed/G8vkPCn2bLs' title='Slice Text in Photoshop' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
              },
              {
                title: "Create Appointment",
                element: document.getElementById(
                  "createNewAppointment"
                ) as HTMLInputElement,
                intro:
                  "Create New Appoint with Physical Location, Google, Zoom etc.",
              },
              {
                title: "Appointments",
                element: document.getElementById(
                  "vueCalenderAppointmentList"
                ) as HTMLInputElement,
                intro: "Appointment Lists.",
              },
            ],
          })
          .start();
      }
    }
    watch(
      // () => watchTour,
      () => store.state.takAtour,
      (newvalue, oldvalue) => {
        if (newvalue === true) {
          console.log("check >>>", newvalue)
          toggleTourGuide();
        }
      }
    );
    function getaddAppointment(data: any) {
      console.log("emit data:::", data);
      state.data.showAppointCreateModel = !state.data.showAppointCreateModel;
      location.reload();
    }
    function getDetailAppointment(data: any) {
      state.data.showAppointDetailModel = !state.data.showAppointDetailModel;
    }
    function showAppointmentModal() {
      if (state.data.timeslotEvents.length >= state.data.subscriptionDetails.appointment_numbers) {
        // if (state.data.subscriptionDetails.total_estimation >= 20000) {
        state.ui.isActive.upgradeSubscription = true;
      } else {
        state.data.showAppointCreateModel = !state.data.showAppointCreateModel;
        console.log(state.data.showAppointCreateModel);
      }
    }
    function showAppointmentImportModal() {
      state.data.showAppointImportModel = !state.data.showAppointImportModel;
    }
    function getImportAppointment(data: any) {
      state.data.showAppointImportModel = !state.data.showAppointImportModel;
    }
    if (route.query.zoomMeeting === "true") {
      setTimeout(showAppointmentModal, 1000);
    }
    function renderEvent(data: any) {
      const dateNow = moment(new Date()).format("Y-MM-DD HH:MM:SS");
      // data.forEach((appointmentData: any) => {
      //   const item = state.data.appointmentList.filter((appointment: any) => appointment.id === appointmentData.id);
      //   if (item.length == 0) {
      //     const data = {
      //       id: appointmentData.id,
      //       start: appointmentData.start_time,
      //       end: appointmentData.end_time,
      //       title: appointmentData.title,
      //       class:
      //         appointmentData.end_time > dateNow ? "blue-event" : "red-event",
      //       content: "Meeting Details:" + appointmentData.note,
      //     };
      //     state.data.appointmentList.push(data);
      //   }

      // });
      // toggleTourGuide();
      state.data.appointmentLoad = true;
    }

    function getUserAppointment() {
      ethitransService
        .getUserAppointment()
        .then((res: any) => {
          console.log("get user appointment", res.data.data);
          // eslint-disable-next-line @typescript-eslint/camelcase
          const start_at = res.data.data['0']['start_time'];
          // eslint-disable-next-line @typescript-eslint/camelcase
          const start_np = common.localDateAndTime(start_at);
          
          console.log("start At:",res.data.data['0']['start_time']);
          const newData = res.data.data.map((i: any) =>{
            // eslint-disable-next-line @typescript-eslint/camelcase
              i.start_time = common.localDateAndTime(i.start_time);
              // eslint-disable-next-line @typescript-eslint/camelcase
              i.end_time = common.localDateAndTime(i.end_time);
              return i;
          });
          state.data.timeslotEvents = newData;
          renderEvent(newData);

        })
        .catch((error: any) => {
          //consoloe.log(error);
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }

    function syncGoogleEvents(code: string) {
      const payLoad = { type: 'google', code: code }
      ethitransService
        .syncGoogleEvents(payLoad)
        .then((res: any) => {

          if (res.data.data.type == 'completed') {
            renderEvent(res.data.data.data);
            state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
            state.data.SuccessFulMessage = "Appointment Synced Successfully.";

            router.push({
              name: "admin-appointment",
            });


          }
          // console.log("get user appointment", res.data.data);
          //  renderEvent(res.data.data); 

        })
        .catch((error: any) => {
          //consoloe.log(error);
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }

    function closeModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
    }


    function appointData(data: any) {
      console.log("emit data to here", data);
      // renderEvent(data);
      location.reload();
      // state.data.appointmentList.push(data);
    }

    function openEventDetails(data: any) {
      state.data.eventId = data.id;
      state.data.showAppointDetailModel = !state.data.showAppointDetailModel;

      // alert('hi');
    }
    function packageDetailsByUser() {
      // state.ui.error.erorList.errorsList = [];
      // state.ui.error.hasError = false;
      state.ui.isLoading.packageDetailsByUser = true;
      ethitransService
        .packageDetailsByUser()
        .then((res: any) => {
          console.log("Check response >>> ", res.data.data.package_features)
          state.data.subscriptionDetails = res.data.data.package_features;
          state.ui.isLoading.packageDetailsByUser = false;

        })
        .catch((error: any) => {
          // common
          //   .sdCatchErr(error, state.ui.error.erorList.errorsList)
          //   .then((res: any) => {
          //     state.ui.error.hasError = true;
          //     // console.log("CHECK for Error >>>", res)
          //     for (const [key, value] of Object.entries(res)) {
          //       if (Array.isArray(value)) {
          //         state.ui.error.erorList.errorsList.push(value[0]);
          //       }
          //     }

          //   });
        })
    }
    function hideUpdateSubscriptionModal() {
      state.ui.isActive.upgradeSubscription = !state.ui.isActive.upgradeSubscription;
    }
    const reloadChildComponent = () => {
      setTimeout(() => {
        childComponentKey.value += 1; // Change the key to force re-render
      }, 0); // Adjust the timeout duration as needed
    };

    onBeforeMount(() => {
      reloadChildComponent();
    });

    onMounted(() => {

      getUserAppointment();
      packageDetailsByUser();

      if (route.query.gcode) {
        const gcode: any = route.query.gcode;
        syncGoogleEvents(gcode);
      }
    });

    function closeAppointmentModal() {
      state.data.title = "";
      state.data.description = "";
      state.data.file = "";
      state.error.erorList.errorsList = [];
      state.error.hasError = false;
      $("#addAppointment").modal("hide");
    }

    return {
      state,
      reloadChildComponent,
      hideUpdateSubscriptionModal,
      packageDetailsByUser,
      toggleTourGuide,
      getaddAppointment,
      getDetailAppointment,
      showAppointmentModal,
      closeAppointmentModal,
      getUserAppointment,
      appointData,
      openEventDetails,
      showAppointmentImportModal,
      getImportAppointment,
      renderEvent,
      closeModal,
    };
  },
});
