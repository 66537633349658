<template>
    <div>
      <input class="form-control" :disabled="disabled" v-model="emailInput" @input="validateEmail" placeholder="Enter your email" />
      <p v-if="!isValid && emailInput">Invalid Email</p>
    </div>
  </template>
  
  <script>
  import { ref, watch } from 'vue';
  
  export default {
    props: {
      email: {
        type: String,
        required: true,
      },
      disabled: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['validEmail'],
    setup(props, { emit }) {
      const emailInput = ref(props.email); // Local email input state
      const isValid = ref(true);
  
      // Validation method
      const validateEmail = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        isValid.value = emailPattern.test(emailInput.value);
        
        // Emit the result to the parent
        emit('validEmail', emailInput.value);
      };
  
      // Watch for changes in props (email) to sync with the child component input
      watch(() => props.email, (newVal) => {
        emailInput.value = newVal;
      });
  
      return {
        emailInput,
        isValid,
        validateEmail,
      };
    },
  };
  </script>
  