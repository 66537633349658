
import { defineComponent, onMounted, reactive, ref} from "vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import Multiselect from "@vueform/multiselect";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import { config } from "@/Config";
import { useRoute } from "vue-router";
import AddNotification from "@/components/modal/AddNotification.vue";
import GoogleMapAppointment from "../commonDesign/GoogleMapAppointment.vue";


// import Axios from "axios";
// @ts-ignore-start
import handleClientLoad = require("../../../public/js/calendarAuth.js");
// @ts-ignore-end
// const handleClientLoad =  import("../../../public/js/calendarAuth");

export default defineComponent({
  name: "AppointmentCreateModal",
  props: {
    showModal: Boolean,
    startDateTime: String,
    endDateTime: String,
  },
  components: {
    ErrorList,
    Multiselect,
    SuccessfulModalCustom,
    AddNotification,
    GoogleMapAppointment,
  },
  setup(props, { emit }) {
    const gapi = window.gapi;
    const router = useRoute();
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const moment = require("moment");
    const latitude = ref<number>(36.0755227);  // Default latitude
    const longitude = ref<number>(-79.8203066);  // Default longitude
    const showMap = ref<boolean>(false);
    const location = ref<string>('');

    

    const updateLatitude = (newLatitude: number) => {
      latitude.value = newLatitude;
    };

    const updateLongitude = (newLongitude: number) => {
      longitude.value = newLongitude;
    };

    

    const state = reactive({
      data: {
        notifications: [],
        showSuccesCustomModal: false,
        SuccessFulMessage: "",

        range: {
          start: props.startDateTime
            ? new Date(props.startDateTime)
            : new Date(),
          end: props.endDateTime ? new Date(props.endDateTime) : new Date(),
        },
        tags: {
          mode: "tags",
          value: [] as any,
          options: [] as any,
          searchable: true,
          createTag: true,
        },
        masks: {
          input: "YYYY-MM-DD h:mm A",
        },
        meetingVia: [
          // {
          //   name: "Skype",
          //   value: "skype",
          // },
          {
            name: "Google",
            value: "google",
          },
          // {
          //   name: "Zoom",
          //   value: "zoom",
          // },
          {
            name: "Physical",
            value: "physical",
          },
        ],
      },

      ui: {
        error: {
          hasError: false,
          errorList: {
            delete: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          deleteSelected: false,
        },
      },
      routeValue: router.query.showModel,
      errorList: [] as any,
      colorList: ["#fdc689", "a3d39c"],
      isErrorStatus: false,
      postLoadingCreateAppointmen: false,
      meetingType: "" as any,
      title: "" as string,
      allDay: false,
      bgColor: "#00c489",
      location: "" as string,
      note: "" as string,
      inviteUesr: [] as any,
      meetingUrl: "" as string,
      meetingCode: "" as string,
      googleMeetURL: "" as any,
      auth2: {},
      googleUser: {} as any,
      googleSignInParams: {
        /* eslint-disable @typescript-eslint/class-name-casing */
        /* eslint-disable @typescript-eslint/camelcase */
        client_id:
          "268712205523-dpbrhqpqu6cpbjdmp83ia9a7nhk2vu5k.apps.googleusercontent.com",
      },
    });

    const updateLocation = (newLocation: string) => {
      state.location = newLocation;
    };

    if (router.query.zoomMeeting === "true") {
      state.meetingType = "zoom";
    }
    //  function checkGoolgeLogin(){
    //    if(gapi.auth2.getAuthInstance().isSignedIn.get()){
    //      console.log("Google Logedin ");
    //      state.data.meetingVia.unshift({
    //           name: "Google",
    //           value: "google",

    //         },)
    //    }
    //   }
    function updateNotificationList(data: any) {
      state.data.notifications = data;
    }
    function slotcolorSelector(color: string) {
      state.bgColor = color;
      console.log("COLOR SELECTOR >>> ", color);
    }
    function getProjectMmber() {
      ethitransService
        .getProjectUser()
        .then((res: any) => {
          console.log("invite users:>>> ", res.data.data);
          state.data.tags.options = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          //consoloe.log(error);
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }

    function saveEventGoogleCalendar() {
      // state.postLoadingCreateAppointmen = true;

      return new Promise((resolve, reject) => {
        const bolVal = true as any;
        const starTime = state.data.range.start as any;
        const endTime = state.data.range.end as any;
        const emailLists = [] as any;
        state.data.tags.value.length > 0 &&
          state.data.tags.value.forEach((element: any) => {
            emailLists.push({ email: element });
          });
        const event = {
          summary: state.title,
          location: state.location,
          description: state.note,
          conferenceData: {
            conferenceSolution: bolVal,
          },
          start: {
            dateTime: moment(starTime).format(),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          end: {
            dateTime: moment(endTime).format(),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
          attendees: emailLists,
          reminders: {
            useDefault: false,
            overrides: [
              {
                method: "email",
                minutes: 24 * 60,
              },
              {
                method: "popup",
                minutes: 10,
              },
            ],
          },
        };
        const request = gapi.client.calendar.events.insert({
          calendarId: "primary",
          resource: event,
        });
        request.execute(function (event: any) {
          const eventPatch = {
            conferenceData: {
              createRequest: {
                requestId: "7qxalsvy90",
                conferenceSolutionKey: {
                  type: "hangoutsMeet",
                },
              },
            },
          };

          gapi.client.calendar.events
            .patch({
              calendarId: "primary",
              eventId: event.id,
              resource: eventPatch,
              sendNotifications: true,
              conferenceDataVersion: 1,
            })
            .execute((data: any) => {
              console.log("%%%%%%%%%", data);
              console.log("hangout link", data.hangoutLink);
              state.googleMeetURL = data.hangoutLink;
              resolve(state.googleMeetURL);
              // state.postLoadingCreateAppointmen = false;
            });
        });
      });
    }

    //add new meeting using zoom

    function addZoomMeeting() {
      // state.postLoadingCreateAppointmen = true;

      return new Promise((resolve, reject) => {
        const payLoad = {
          title: state.title,
          note: state.note,
          startTime: state.data.range.start,
          endTime: state.data.range.end,
          token: localStorage.getItem("zoom_access_token"),
        };
        ethitransService
          .createZoomMeeting(payLoad)
          .then((res: any) => {
            console.log("zoom res", res);
            console.log("zoom res", res.data.join_url);
            state.googleMeetURL = res.data.join_url;
            resolve(state.googleMeetURL);
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            // state.postLoadingCreateAppointmen = false;
          });
      });
    }

    function closeAppointmentModal() {
      emit("closeModal");
      $("#appointmentCreateModel").modal("hide");
    }

    function addAppointment() {
      emit("addAppointment", "addAppointment success");
      $("#appointmentCreateModel").modal("hide");
    }

    function validateForm() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (!state.title) {
        state.errorList.push("Appointment title is required.");
      }
      if (!state.meetingType) {
        state.errorList.push("Meeting via is required.");
      }

      if (state.data.tags.value.length == 0) {
        state.errorList.push("Invite user is required.");
      }

      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }
    }
    function scrollToTop() {
      $("#appointmentModelScroll").animate({
        scrollTop: $("#appointmentModelScroll")[0],
      });
    }

    function googleLogin() {
      const myParams: any = {
        clientid:
          "268712205523-dpbrhqpqu6cpbjdmp83ia9a7nhk2vu5k.apps.googleusercontent.com", //You need to set client id
        cookiepolicy: "single_host_origin",
        // callback: "loginCallback", //callback function
        approvalprompt: "force",
        scope: "https://www.googleapis.com/auth/calendar",
      };
      // gapi.auth.signIn(myParams);
      handleClientLoad();
      gapi.auth2.getAuthInstance().signIn(myParams);
    }

    // zoom
    function checkLogin(event: any) {
      console.log(
        "login status",
        gapi.auth2.getAuthInstance().isSignedIn.get()
      );
      if (event === "zoom" && !localStorage.getItem("zoom_access_token")) {
        const zoomUrl = `${config.zoomAuth}?response_type=${config.response_type}&client_id=${config.client_id}&redirect_uri=${config.redirect_uri}`;
        // const zoomUrl = "https://zoom.us/oauth/authorize?response_type=code&client_id=ervA7HgFRAeJpdnqtVJoJg&redirect_uri=https://localhost:8080"
        window.open(zoomUrl, "_self");
      }
      // if (
      //   event === "google" &&
      //   !gapi.auth2.getAuthInstance().isSignedIn.get()
      // ) {
      //   console.log("im heree");
      //   googleLogin();
      // }
      if (event === "google") {
        console.log("im heree");
        googleLogin();
      }
    }

    function addNewAppointment() {
      if (
        state.errorList.length != 0 ||
        state.isErrorStatus ||
        state.postLoadingCreateAppointmen
      )
        return false;

      //   state.googleAddress.streetAddress = (
      //   document.getElementById("street_number") as HTMLInputElement
      // ).value;
      // state.googleAddress.route = (
      //   document.getElementById("route") as HTMLInputElement
      // ).value;
      // state.googleAddress.city = (
      //   document.getElementById("locality") as HTMLInputElement
      // ).value;
      // state.googleAddress.state = (
      //   document.getElementById(
      //     "administrative_area_level_1"
      //   ) as HTMLInputElement
      // ).value;
      // state.googleAddress.postalCode = (
      //   document.getElementById("postal_code") as HTMLInputElement
      // ).value;
      // state.googleAddress.country = (
      //   document.getElementById("country") as HTMLInputElement
      // ).value;
      const payLoad = {} as any;
      payLoad.title = state.title;
      payLoad.note = state.note;
      payLoad.location = state.location;
      payLoad.latitude = latitude.value;  
      payLoad.longitude = longitude.value; 
      payLoad.meetingType = state.meetingType;
      payLoad.inviteUser = state.data.tags.value;
      payLoad.meetingUrl = state.meetingUrl
        ? state.meetingUrl
        : state.googleMeetURL;
      payLoad.meetingCode = state.meetingCode;
      payLoad.startTime = state.data.range.start;
      payLoad.endTime = state.data.range.end;
      payLoad.bgColor = state.bgColor;
      payLoad.isAllday = false;
      payLoad.notifications = state.data.notifications;
      if (state.allDay) {
        payLoad.isAllday = true;
        payLoad.category = "allday";
      }
      state.postLoadingCreateAppointmen = true;
      console.log("Payload Check by CM >>> ", payLoad);
      ethitransService
        .createAppointment(payLoad)
        .then((res: any) => {
          $("#appointmentCreateModel").modal("hide");
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          const data = {
            start: res.data.data.start_time,
            end: res.data.data.end_time,
            title: res.data.data.title,
            class: "blue-event",
            content: "Meeting Details:" + res.data.data.note,
          };
          emit("calendarData", data);

          state.data.SuccessFulMessage = "Created Project Successfully.";
        })
        .catch((error: any) => {
          console.log(error);
          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.errorList.push(value[0]);
              }
            }
          });
        })
        .finally(() => {
          state.postLoadingCreateAppointmen = false;
        });
    }

    async function saveAppointment() {
      scrollToTop();
      validateForm();
      if (state.meetingType === "google" && !state.errorList.length) {
        state.postLoadingCreateAppointmen = true;
        const a = await saveEventGoogleCalendar();
        state.postLoadingCreateAppointmen = false;

        console.log("await here", a);
      }
      if (state.meetingType === "zoom" && !state.errorList.length) {
        state.postLoadingCreateAppointmen = true;
        await addZoomMeeting();
        state.postLoadingCreateAppointmen = false;
      }
      addNewAppointment();
    }

    onMounted(() => {
      console.log("CHECK >> ", typeof props.showModal);

      //  console.log('hello test',moment(state.data.range.start).format());

      if (props.showModal) {
        $("#appointmentCreateModel").modal("show");
        console.log("route here", router.query.code);
        // getZoomAccessToken();
        getProjectMmber();
        // checkGoolgeLogin();
        // saveEventGoogleCalendar();
      }
      //   getFolderList();
    });

    return {
      state,
      updateNotificationList,
      slotcolorSelector,
      addAppointment,
      closeAppointmentModal,
      getProjectMmber,
      validateForm,
      addNewAppointment,
      scrollToTop,
      // checkGoolgeLogin,
      saveEventGoogleCalendar,
      saveAppointment,
      checkLogin,
      addZoomMeeting,
      googleLogin,
      latitude,
      longitude,
      showMap,
      updateLatitude,
      updateLongitude,
      updateLocation,
      location
    };
  },
});
