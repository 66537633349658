
import { defineComponent, reactive, onMounted, computed, watch, ref, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { ethitransService } from "@/services/EthitransService";
import ProjectCard from "@/components/cards/ProjectCard.vue";
import ActivityCard from "@/components/cards/ActivityCard.vue";
import RecentChannels from "@/components/cards/RecentChannels.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import DiscussionChannels from "@/components/cards/DiscussionChannels.vue";
// import DragAndDropZone from "@/common/DragAndDropZone.vue";
// import AppImageCarousel from "@/common/AppImageCarousel.vue"

// import SingleError from "@/components/commonDesign/SingleError.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import { common } from "@/common/services/Common";
import { useRouter } from "vue-router";
import { config } from "@/Config";
import Pusher from "pusher-js";
import { signOut } from "@/common/google";


// import Multiselect from "@vueform/multiselect";
import Multiselect from 'vue-multiselect';
import UpgradeSubscriptionModal from '@/common/UpgradeSubscriptionModal.vue'

import introJs from "intro.js";
import { useCookie } from 'vue-cookie-next'


// import VueGoogleAutocomplete from "vue-google-autocomplete";
import GoogleMapView from "@/components/commonDesign/GoogleMapView.vue";

export default defineComponent({
  name: "Dashboard",
  components: {
    ProjectCard,
    UpgradeSubscriptionModal,
    // AppImageCarousel,
    // DragAndDropZone,
    // SingleError,
    ErrorList,
    EmptyData,
    ActivityCard,
    RecentChannels,
    DiscussionChannels,
    Multiselect,
    SuccessfulModalCustom,
    // VueGoogleAutocomplete,
    GoogleMapView
    // vSelect,
    // Multiselect,
    // GoogleAddress,
    // CreateProjectModal,
  },
  setup() {
    const cookie = useCookie()
    const scrollPosition = ref({ top: 0, left: 0 })
    const router = useRouter();
    // const address = ref<string>("");
    const store = useStore();
    const options = ref([]) as any;
    const selectedOptions = ref([]) as any;
    const allProjectLists = ref([]) as any;
    const latitude = ref<number>(36.0755227);  // Default latitude
    const longitude = ref<number>(-79.8203066);  // Default longitude
    const address = ref<string>('');
    const showMap = ref<boolean>(false);
    const resetMapData = () => {
      latitude.value = 36.0755227;
      longitude.value = -79.8203066;
      address.value = '';
      showMap.value = false;
    };

    const updateLatitude = (newLatitude: number) => {
      latitude.value = newLatitude;
    };

    const updateLongitude = (newLongitude: number) => {
      longitude.value = newLongitude;
    };

    const updateAddress = (newAddress: string) => {
      address.value = newAddress;
    };
    const projectSelected = ref({
      'label': 'Search and select ',
      'name': 'Search and select',
    }) as any;

    const state = reactive({
      data: {
        activeProjectId: {} as any,
        subscriptionDetails: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_per_project: 0,
        } as any,
        project: {
          status: "active",
          sort: "def",
          sortActive: false,
          selectProject: "selected" as any,
        },
        createProjectUuid: {} as any,
        channel: "channel",
        projectLoad: false,
        showSuccesCustomModal: false,
        showSuccesModal: false,
        hideLoadMoreProjectList: false,
        projectOffSet: 0,
        pageLimit: 6,
        SuccessFulMessage: "",
        subTitleMessage: "",
        isProjectCreated: false,
        imageBaseUrl: "",
        payerChannels: [] as any,
        activities: [] as any,
        recentChannels: [] as any,
        activeUser: localStorage.getItem("current_user_details"),
        activeUserEmail: localStorage.getItem("current_user_email"),
        activeUserId: localStorage.getItem("current_user_id"),
        allProjectList: [] as any,
        filterChannelExit: "" as any,
        createProject: "" as any,
        createProjectId: "" as any,
        tags: {
          mode: "tags",
          value: [] as any,
          options: [] as any,
          closeOnSelect: true,
          searchable: true,
          createTag: true,
        },
      },
      ui: {
        error:{
          isErrorStatus: false,
          errorsList: {
            projectArchive: [] as any
          }
        }
        ,
        isActive: {
          upgradeSubscription: false,
          customModal: false,
        },
        isLoading: {
          userStatus: false,
          projectListAPICalled: false,
          projectListActivities: false,
        },
        isPostLoading: {
          closeChannel: false,
          projectArchive: false,
        },
      },
      googleAddress: {
        streetAddress: "",
        route: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
        latitude: 0,
        longitude: 0,
      },

      errorList: [] as any,
      isErrorStatus: false,
      isCreateProjectModalShow: false,
      postLoading: false,
      allProjectLoading: false,
      postLoadingCreateProject: false,
      projectName: "",
      projectDescription: "",
      projectAddress: "",
      projectList: [] as any,
      title: "projects",
      heading: "Get started by creating a new project.",
      totalChannels: 0,
      totalArchiveProject: 0,
      totalProjects: 0,
      totalUsers: 0,
      projectCount: 0,
      projectCountName: "Total Active Projects",

    });
    const preventScroll = (event: any) => {
      event.preventDefault(); // Stop the default scroll behavior
    }

    // Prevent arrow key or spacebar scroll
    const preventKeyScroll = (event: any) => {
      const keys = [32, 37, 38, 39, 40]; // Spacebar and arrow keys
      if (keys.includes(event.keyCode)) {
        event.preventDefault(); // Prevent scroll
      }
    }
    function setActiveProjectId(activeProjectId: string) {
      console.log("SHOW THE ACTIVE ID >>>", activeProjectId)
      state.data.activeProjectId = activeProjectId;
    }
    function showCustomModal() {
      // Capture the current scroll position
      scrollPosition.value.top = window.scrollY || document.documentElement.scrollTop;
      scrollPosition.value.left = window.scrollX || document.documentElement.scrollLeft;

      // Set styles to lock the scroll
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPosition.value.top}px`; 
      document.body.style.left = `-${scrollPosition.value.left}px`; 
      document.body.style.width = '100%'; 

      window.addEventListener('wheel', preventScroll, { passive: false });
      window.addEventListener('keydown', preventKeyScroll, { passive: false });

      state.ui.isActive.customModal = !state.ui.isActive.customModal;
    }

    function hideCustomModal() {
      // Remove the scroll lock
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.left = '';
      document.body.style.width = '';

      // Restore the scroll position
      window.scrollTo(scrollPosition.value.left, scrollPosition.value.top);

      // Remove event listeners to allow scrolling
      window.removeEventListener('wheel', preventScroll);
      window.removeEventListener('keydown', preventKeyScroll);

      // Restore the scroll position
      // window.scrollTo(0, scrollTop.value);
      state.ui.isActive.customModal = !state.ui.isActive.customModal;
    }

    // const tourGuideStatus = computed(() => store.state.showTourGuide);
    function toggleTourGuide() {
      if (store.state.showTourGuide) {
        introJs()
          .setOptions({
            steps: [
              {
                tooltipClass: "Tooltipwithvideo",
                title: "Welcome to Convorally!",
                // intro: "Welcome to Convorally! 👋 ",
                intro:
                  "<div> <iframe width='100%' height='300' src='https://www.youtube.com/embed/G8vkPCn2bLs' title='Slice Text in Photoshop' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe></div>",
              },
              {
                tooltipClass: "Tooltipnotification",
                title: "Notification",
                element: document.getElementById(
                  "navbarDropdown"
                ) as HTMLElement,
                intro: "See the Notifications.",
              },
              {
                tooltipClass: "Tooltiplogout",
                title: "LogOut",
                element: document.getElementById("navbarLogout") as HTMLElement,
                intro: "Can Logout from the Application",
              },
              {
                tooltipClass: "Tooltipusericon",
                title: "User Icon",
                element: document.getElementById(
                  "userAvatarIcon"
                ) as HTMLElement,
                intro: "Toogle Tour, Notification Settings and Logout.",
              },
              {
                tooltipClass: "Tooltipdashboard",
                title: "Dashboard",
                element: document.getElementById(
                  "redirectDashboard"
                ) as HTMLElement,
                intro: "Review every activity on a macro-level.",
                position: "right",
              },
              {
                tooltipClass: "Tooltipinvitation",
                title: "Invitation",
                element: document.getElementById(
                  "redirectInvitation"
                ) as HTMLElement,
                intro: "Invite members for collaboration.",
                position: "right",
              },
              {
                tooltipClass: "Tooltiptemplate",
                title: "My Templates",
                element: document.getElementById(
                  "redirectMyTemplate"
                ) as HTMLElement,
                intro: "Access your personal project templates.",
                position: "right",
              },
              {
                tooltipClass: "Tooltiplibrary",
                title: "Convorally Library",
                element: document.getElementById(
                  "redirectLibrary"
                ) as HTMLElement,
                intro:
                  "Access templates, images, forms, and e-docs from the library.",
                position: "right",
              },
              {
                tooltipClass: "Tooltipuploadpdf",
                title: "Upload Pdf",
                element: document.getElementById(
                  "redirectUploadPdf"
                ) as HTMLElement,
                intro: "Upload a PDF template.",
                position: "right",
              },
              {
                tooltipClass: "Tooltipmydrive",
                title: "My Drive",
                element: document.getElementById(
                  "redirectMyDrive"
                ) as HTMLElement,
                intro: "Create and manage your files and folders.",
                position: "right",
              },
              {
                tooltipClass: "Tooltipguest",
                title: "Manage Guest Users",
                element: document.getElementById(
                  "redirectManageGuest"
                ) as HTMLElement,
                intro: "Manage guest users' information and roles.",
                position: "right",
              },
              {
                tooltipClass: "Tooltiptutorial",
                title: "Tutorial",
                element: document.getElementById(
                  "redirectTutorial"
                ) as HTMLElement,
                intro:
                  "Watch the tutorial to start a project for collaboration.",
                position: "right",
              },
              {
                tooltipClass: "Tooltipappointment",
                title: "Appointment",
                element: document.getElementById(
                  "redirectAppointment"
                ) as HTMLElement,
                intro: "Access Visual Calendar to add appointments.",
                position: "right",
              },
              // dashboard User Guides
              {
                tooltipClass: "Tooltipproject",
                title: "Create Project",
                element: document.getElementById(
                  "createNewProject"
                ) as HTMLElement,
                intro: "Create a project to start collaborating.",
              },
              {
                tooltipClass: "Tooltipusetemplate",
                title: "Use Template",
                element: document.getElementById("useTemplate") as HTMLElement,
                intro: "Use an existing project template from the library.",
              },
              {
                tooltipClass: "Tooltipprojectstatus",
                title: "Active",
                element: document.getElementById(
                  "projectStatus"
                ) as HTMLElement,
                intro: "Sort projects according to their 'Status'.",
              },
              {
                tooltipClass: "Tooltipatoz",
                title: "A to Z",
                element: document.getElementById(
                  "projectSortBy"
                ) as HTMLElement,
                intro: "Sort files in chronological order.",
              },
              {
                tooltipClass: "Tooltipprojectdetails",
                title: "Project Details",
                element: document.getElementById(
                  "projectDetailsDashboard"
                ) as HTMLElement,
                intro:
                  "Here you can see the overview of the projects, members etc.",
              },
              {
                tooltipClass: "Tooltiprecentchannels",
                title: "Recent Channels",
                element: document.getElementById(
                  "recentChannel"
                ) as HTMLElement,
                intro:
                  "Here you can see recently used Channel and redirect to it.",
              },
              {
                tooltipClass: "Tooltipactivityfeed",
                title: "Activity Feed",
                element: document.getElementById(
                  "activityFeeds"
                ) as HTMLElement,
                intro: "Assess individual activity feed.",
              },
            ],
          })
          .start();
      }
    }

    function showTour(dataId: any, title: any, message: any) {
      introJs()
        .setOptions({
          steps: [
            // dashboard User Guides
            {
              tooltipClass: "Tooltipproject",
              title: title,
              element: document.getElementById(
                dataId
              ) as HTMLElement,
              intro: message,

            }
          ],
        })
        .start();
    }


    watch(
      // () => watchTour,
      () => store.state.takAtour,
      (newvalue, oldvalue) => {
        if (newvalue === true) {
          // console.log("check >>>", newvalue);
          toggleTourGuide();
        }
      }
    );

    function setOffset() {
      const page: any = state.projectList.length / state.data.pageLimit;
      if (page <= 1) {
        state.data.projectOffSet = 1;
      } else {
        state.data.projectOffSet = parseInt(page + 0.5);
      }
    }

    function getProjectInfo() {
      state.errorList = [];
      state.isErrorStatus = false;
      const payLoad = {
        offset: 1,
        status: state.data.project.status,
        sort: state.data.project.sort,
      };      // state.postLoading = true;
      ethitransService
        .projectInfo(payLoad)
        .then((res: any) => {
          // state.channelList
          // state.data.subscriptionDetails.channel_per_project
          state.data.subscriptionDetails = res.data.data.package_features;
          if (state.data.project.status === 'active') {
            state.projectCountName = 'Total Active Projects'
            state.projectCount = res.data.data.total_active_project
              ? res.data.data.total_active_project
              : 0;
          }
          if (state.data.project.status === 'archive') {
            state.projectCountName = 'Total Archive Projects'
            state.projectCount = res.data.data.total_archive_project
              ? res.data.data.total_archive_project
              : 0;
          }
          if (state.data.project.status === 'hidden') {
            state.projectCountName = 'Total Hidden Projects'
            state.projectCount = res.data.data.total_hidden_project
              ? res.data.data.total_hidden_project
              : 0;
          }

          state.data.payerChannels = res.data.data.payer_channels
            ? res.data.data.payer_channels
            : [];
          state.data.recentChannels = res.data.data.recent_channels
            ? res.data.data.recent_channels
            : [];
          state.data.activities = res.data.data.activities
            ? res.data.data.activities
            : [];
          state.totalChannels = res.data.data.total_channels
            ? res.data.data.total_channels
            : 0;
          // state.totalArchiveProject = res.data.data.total_archive_project
          //   ? res.data.data.total_archive_project
          //   : 0;
          state.totalUsers = res.data.data.total_members
            ? res.data.data.total_members
            : 0;

          state.totalProjects = res.data.data.total_projects
            ? res.data.data.total_projects
            : 0;
          // state.ui.isLoading.projectListAPICalled = true;
          // state.data.projectLoad = true;
        })
        .catch((error: any) => {
          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.errorList.push(value[0]);
              }
            }
          });
        })
        .finally(() => {
          // state.postLoading = false;
          // state.data.projectLoad = false;
        });
    }

    function selectedProject(data: any) {
      getProjectInfo();
      state.projectList = state.projectList.filter((e: any) => e.uuid != data);


    }

    function updateProjectList(data: any) {
      getProjectInfo();
      state.projectList = data.projects;
      if (data.fav_projects && data.fav_projects.length) {
        data.fav_projects.forEach((data: any) => {
          state.projectList.unshift(data);
        });
      }
      setOffset();
    }


    state.ui.isLoading.projectListAPICalled = false;
    function addTag(tag: any) {
      // console.log("Check the New Tag >>>", tag);
      const payLoad = {
        name: tag,
      };
      // state.postLoading = true;
      ethitransService
        .createMasterTag(payLoad)
        .then((res: any) => {
          console.log("createMasterTag:>>> ", res.data.data);
          options.value.push(res.data.data);
          selectedOptions.value.push(res.data.data);
        })
        .catch((error: any) => {
          // common.sdCatchErr(error, state.errorList).then((res: any) => {
          //   state.isErrorStatus = true;
          //   // console.log("CHECK for Error >>>", res)
          //   for (const [key, value] of Object.entries(res)) {
          //     if (Array.isArray(value)) {
          //       state.errorList.push(value[0]);
          //     }
          //   }
          //   // if (res) {
          //   //   if (res.error) {
          //   //     console.log("CHECK for Error >>>", res.error[0])
          //   //     // state.errorList.push(res.email[0]);
          //   //     // state.errorList = [...state.errorList]
          //   //   }
          //   // }
          // });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    function updateProjectDetails(data: any) {
      // alert("UPDATE")
      state.projectList.forEach((project: any) => {
        if (project.uuid.toUpperCase() === data.uuid.toUpperCase()) {
          // console.log("Data of Project >>> ", project)
          // console.log("Data of Project Archived  >>> ", data)
          project.description = data.description;
          project.name = data.name;
          project.tags = data.tags;
          project.city = data.city;
          project.country = data.country;
          project.postalCode = data.postalCode;
          project.state = data.state;
          project.route = data.route;
          project.latitude = data.latitude;
          project.longitude = data.longitude;
          // eslint-disable-next-line @typescript-eslint/camelcase
          project.full_address = data.full_address;
          // eslint-disable-next-line @typescript-eslint/camelcase
          project.street_address = data.street_address;
          // project = data;
        }
      });
    }
    function channelInsert(event: any) {
      // console.log("data EMIT >>> ", event);
      state.projectList.forEach((item: any) => {
        // console.log("PROJECT FOUND >>>", item)
        if (event.project_id === item.id) {
          // console.log("PROJECT FOUND >>>", item);
          item.channels.unshift(event);
        }
      });
    }
    const projectList = computed(() => {
      return state.projectList;
    });
    function masterTagList() {
      state.errorList = [];
      state.isErrorStatus = false;
      ethitransService
        .masterTagList()
        .then((res: any) => {
          // console.log("masterTagList Check:>>> ", res.data.data);
          res.data.data.forEach((item: any) => {
            item.label = item.name;
            item.value = item.code;
          });
          state.data.tags.options = res.data.data ? res.data.data : [];
          options.value = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            // console.log("CHECK for Error >>>", res)
            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.errorList.push(value[0]);
              }
            }
            // if (res) {
            //   if (res.error) {
            //     console.log("CHECK for Error >>>", res.error[0])
            //     // state.errorList.push(res.email[0]);
            //     // state.errorList = [...state.errorList]
            //   }
            // }
          });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    function hideUpdateSubscriptionModal() {
      // alert("Hello")
      state.ui.isActive.upgradeSubscription = !state.ui.isActive.upgradeSubscription;
    }

    function getProjectList() {
      // console.log("load more")
      state.errorList = [];
      state.isErrorStatus = false;
      if (state.postLoading) return false;
      if (!state.data.project.sortActive) {
        state.data.projectOffSet = state.data.projectOffSet + 1;
      }
      const payLoad = {
        offset: state.data.projectOffSet,
        status: state.data.project.status,
        sort: state.data.project.sort,
      };
      state.postLoading = true;
      ethitransService
        .projectList(payLoad)
        .then((res: any) => {
          // console.log("Project List Response Check:>>> ", res.data.data);
          // if (res.data.data.fav_projects && res.data.data.fav_projects.length) {
          //     res.data.data.fav_projects.forEach((data: any) => {
          //       state.projectList.unshift(data);
          //     });
          //   }
          if (state.data.project.sortActive) {
            state.projectList = res.data.data.projects;
            if (
              res.data.data.fav_projects &&
              res.data.data.fav_projects.length
            ) {
              res.data.data.fav_projects.forEach((data: any) => {
                state.projectList.unshift(data);
              });
            }
          } else {
            if (res.data.data.projects && res.data.data.projects.length) {
              res.data.data.projects.forEach((data: any) => {
                state.projectList.push(data);
              });
            }
            if (
              res.data.data.fav_projects &&
              res.data.data.fav_projects.length
            ) {
              res.data.data.fav_projects.forEach((data: any) => {
                state.projectList.unshift(data);
              });
            }
          }

          if (res.data.data.total_projects > state.projectList.length) {
            state.data.hideLoadMoreProjectList = true;
          } else {
            state.data.hideLoadMoreProjectList = false;
          }
          setOffset();
          state.data.createProject = res.data.data.projects.filter((item: any) => item.created_by_id == state.data.activeUserId)
          state.data.filterChannelExit = res.data.data.projects.filter((project: any) => project.created_by_id == state.data.activeUserId && project.channels && project.channels.length > 0);
          if (state.data.createProject.length == 0) {
            showTour('createNewProject', 'Create Project', 'Create a new project to start planning the operation!');
          }
          else if (state.data.filterChannelExit.length == 0) {
            setTimeout(() => {
              showTour(state.data.createProject[0].id + 'newChannel', 'Create Channel', 'Welcome Onboard! Proceed with creating a Channel!');
              console.log('created', state.data.createProject[0].id + 'channel');
            }, 2000);
          }
        })
        .catch((error: any) => {
          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.errorList.push(value[0]);
              }
            }
          });
        })
        .finally(() => {
          state.postLoading = false;
          state.ui.isLoading.projectListAPICalled = true;
          state.data.projectLoad = true;
        });
    }

    function allProject() {
      state.errorList = [];
      state.isErrorStatus = false;
      state.allProjectLoading = true;
      ethitransService
        .allProjectList(state.data.project.status)
        .then((res: any) => {
          allProjectLists.value = [];
          res.data.data.projects.forEach((project: any) => {
            allProjectLists.value.push({
              'id': project.project.uuid,
              'label': project.project.name,
              'name': project.project.name,
            })
          });
          state.data.allProjectList = res.data.data.projects
            ? res.data.data.projects
            : [];

        })
        .catch((error: any) => {
          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.errorList.push(value[0]);
              }
            }
          });
        })
        .finally(() => {
          state.allProjectLoading = false;
        });
    }


    function changeProjectByStatus() {
      state.data.project.sortActive = true;
      state.data.projectOffSet = 1;
      getProjectList();
      getProjectInfo();
      allProject();
    }
    function loadMoreProjectList() {
      if (state.data.project.sortActive) {
        state.data.project.sortActive = !state.data.project.sortActive;
      }
      getProjectList();
      getProjectInfo();
    }
    function projectListActivities() {
      state.ui.isLoading.projectListActivities = true;
      ethitransService
        .projectListActivities()
        .then((res: any) => {
          // console.log("projectListActivities Check:>>> ", res.data.data);
          state.data.activities = res.data.data ? res.data.data : [];
          // state.projectList = res.data.data.projects
          //   ? res.data.data.projects
          //   : [];
        })
        .catch((error: any) => {
          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            // console.log("CHECK for Error >>>", res)
            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.errorList.push(value[0]);
              }
            }
            // if (res) {
            //   if (res.error) {
            //     console.log("CHECK for Error >>>", res.error[0])
            //     // state.errorList.push(res.email[0]);
            //     // state.errorList = [...state.errorList]
            //   }
            // }
          });
        })
        .finally(() => {
          state.ui.isLoading.projectListActivities = false;
        });
    }
    function reloadPage() {
      // The last "domLoading" Time //
      // eslint-disable-next-line no-var
      var currentDocumentTimestamp = new Date(
        performance.timing.domLoading
      ).getTime();
      // Current Time //
      // eslint-disable-next-line no-var
      var now = Date.now();
      // Ten Seconds //
      // eslint-disable-next-line no-var
      var tenSec = 10 * 1000;
      // Plus Ten Seconds //
      // eslint-disable-next-line no-var
      var plusTenSec = currentDocumentTimestamp + tenSec;
      if (now > plusTenSec) {
        location.reload();
        // eslint-disable-next-line no-empty
      } else {
      }
    }
    // Pusher.logToConsole = true;
    const pusher = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, {
      cluster: "ap2",
    });

    const channel = pusher.subscribe(`channelNotification`);
    channel.bind("channelNotification.created", function (data: any) {
      // console.log('PUSHER CHECK', data);

      if (JSON.stringify(data)) {
        const url: any = window.location.href;
        // const url: any = window.location.href.split("#/").pop();
        const page: any = url.split("#/").pop().split("/")[0];
        // const channelId: any = url.split("/").pop();
        // state.data.channelId = "";
        if (page === "dashboard") {
          // state.data.channelId = channelId;
          getProjectList();
          getProjectInfo();
        }
      }
    });
    // setInterval(function(){ getProjectList(); }, 1000);
    // setInterval(function(){ getProjectList(); }, 180000);
    function userStatus() {
      state.errorList = [];
      state.isErrorStatus = false;
      const payLoad = {
        email: state.data.activeUserEmail,
      };
      state.ui.isLoading.userStatus = true;
      ethitransService
        .userStatus(payLoad)
        .then((res: any) => {
          if (res.data.data.online_status == "offline") {
            // console.log("userStatus Check:>>> ", res.data.data.online_status);
            router.push({
              name: "admin-login",
            });
            signOut();
          } else {
            getProjectList();
            masterTagList();
          }
        })
        .catch((error: any) => {
          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.errorList.push(value[0]);
              }
            }
          });
        })
        .finally(() => {
          state.ui.isLoading.userStatus = true;
        });
    }

    onMounted(() => {
      const theme = cookie.getCookie('theme');
      console.log("HI HELLO", theme); // Outputs the value of the 'theme' cookie
      state.data.imageBaseUrl = config.imageBaseUrl;
      allProject();
      userStatus();
      getProjectList();
      getProjectInfo();
    });
    // onBeforeUnmount(() => {
    //   document.body.style.overflow = '';
    // });

    function clearAddressData() {
      // (address as any).value.clear();
      // (document.getElementById("autocomplete") as HTMLInputElement).value = "";
      // (document.getElementById("street_number") as HTMLInputElement).value = "";
      // (document.getElementById("route") as HTMLInputElement).value = "";
      // (document.getElementById("locality") as HTMLInputElement).value = "";
      // (
      //   document.getElementById(
      //     "administrative_area_level_1"
      //   ) as HTMLInputElement
      // ).value = "";
      // (document.getElementById("postal_code") as HTMLInputElement).value = "";
      // (document.getElementById("country") as HTMLInputElement).value = "";
    }
    function redirectToCreateProject() {
      state.projectName = "";
      state.projectDescription = "";
      state.projectAddress = "";
      state.errorList = [];
      state.data.tags.value = [];
      state.isErrorStatus = false;

      state.googleAddress.streetAddress = "";
      state.googleAddress.route = "";
      state.googleAddress.city = "";
      state.googleAddress.state = "";
      state.googleAddress.postalCode = "";
      state.googleAddress.country = "";
      state.googleAddress.latitude = 0;
      state.googleAddress.longitude = 0;
      // clearAddressData();
      // event.target.reset()
      if (state.data.subscriptionDetails.project_number === 0) {
        // if( state.projectList.length === 2){
        state.ui.isActive.upgradeSubscription = true;
      } else {
        masterTagList();

        $("#createProject").modal("show");
      }

      // console.log("Create Project");
      // state.isCreateProjectModalShow = true;
    }
    function redirectToInvite() {
      state.data.tags.value = [];
      $("#inviteMember").modal("show");
      $("#createProject").modal("hide");
    }
    function hideInvite() {
      $("#inviteMember").modal("hide");
    }
    function redirectToUseTemplate() {
      // alert("Use Tempalte Modal here");
      router.push({
        name: "admin-template-search",
      });
    }
    function validateForm() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (!state.projectName) {
        state.errorList.push("Project name is required.");
      }
      // if (!state.projectDescription) {
      //   state.errorList.push("Project description is required.");
      // }
      // state.googleAddress.streetAddress,
      //   state.googleAddress.city,
      //   state.googleAddress.state,
      //   state.googleAddress.postalCode,
      //   state.googleAddress.country,
      // if (state.googleAddress.country || state.googleAddress.route || state.googleAddress.state|| state.googleAddress.city|| state.googleAddress.streetAddress) {
      //   console.log("hello")
      // } else if(!state.googleAddress.country || !state.googleAddress.route || !state.googleAddress.state|| !state.googleAddress.city|| !state.googleAddress.streetAddress) {
      //   state.errorList.push("Project address is required.");
      // }

      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }
    }
    function hideCreateProjectModal() {
      state.data.tags.value = [];
      selectedOptions.value = [];
      $("#createProject").modal("hide");
      resetMapData();
      // (address as any).value.clear();
      // event.preventDefault(),
      // event.target.reset()
    }
    function getAddressData() {
      const streetAddress = (
        document.getElementById("street_number") as HTMLInputElement
      ).value;
      // console.log("St Add", streetAddress);
      const route = (document.getElementById("route") as HTMLInputElement)
        .value;
      // console.log("route", route);
      const city = (document.getElementById("locality") as HTMLInputElement)
        .value;
      // console.log("city", city);
      const state = (
        document.getElementById(
          "administrative_area_level_1"
        ) as HTMLInputElement
      ).value;
      // console.log("State", state);
      const postalCode = (
        document.getElementById("postal_code") as HTMLInputElement
      ).value;
      // console.log("postalCode", postalCode);
      const country = (document.getElementById("country") as HTMLInputElement)
        .value;
      // console.log("country", country);
      const latitude = (document.getElementById("latitude") as HTMLInputElement)
        .value;
      // console.log("latitude", latitude);
      const longitude = (document.getElementById("longitude") as HTMLInputElement)
        .value;
      // console.log("longitude", longitude);
    }
    // function closeSuccessModal() {
    //   state.data.showSuccesModal = !state.data.showSuccesModal;
    //   $("#successfulModal").modal("hide");
    //   if (state.data.createProject.length == 0) {
    //     setTimeout(() => {
    //       showTour(state.data.createProjectId + 'newChannel', 'Create Channel', 'Welcome Onboard! Proceed with creating a Channel!');
    //     }, 1000);
    //   }
    //   else if (state.data.filterChannelExit.length == 0) {
    //     setTimeout(() => {
    //       showTour(state.data.createProjectId + 'newChannel', 'Create Channel', 'Welcome Onboard! Proceed with creating a Channel!');
    //       console.log('created', state.data.createProject[0].id + 'channel');
    //     }, 1000);
    //   }
    // }
    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      state.data.isProjectCreated = false;
      // $("#successfulModal").modal("hide");
      // if (state.data.createProject.length == 0) {
      //   setTimeout(() => {
      //     showTour(state.data.createProjectId + 'newChannel', 'Create Channel', 'Welcome Onboard! Proceed with creating a Channel!');
      //   }, 1000);
      // }
      // else if (state.data.filterChannelExit.length == 0) {
      //   setTimeout(() => {
      //     showTour(state.data.createProjectId + 'newChannel', 'Create Channel', 'Welcome Onboard! Proceed with creating a Channel!');
      //     console.log('created', state.data.createProject[0].id + 'channel');
      //   }, 1000);
      // }
    }
    function createProject() {
      selectedOptions.value.forEach((tag: any) => {
        state.data.tags.value.push(tag.code)
      });
      // getAddressData()
      // state.googleAddress.streetAddress = (
      //   document.getElementById("street_number") as HTMLInputElement
      // ).value;
      // state.googleAddress.route = (
      //   document.getElementById("route") as HTMLInputElement
      // ).value;
      // state.googleAddress.city = (
      //   document.getElementById("locality") as HTMLInputElement
      // ).value;
      // state.googleAddress.state = (
      //   document.getElementById(
      //     "administrative_area_level_1"
      //   ) as HTMLInputElement
      // ).value;
      // state.googleAddress.postalCode = (
      //   document.getElementById("postal_code") as HTMLInputElement
      // ).value;
      // state.googleAddress.country = (
      //   document.getElementById("country") as HTMLInputElement
      // ).value;
      // console.log(
      //   "CHECK THE TOKEN:>>",
      //   localStorage.getItem("current_user_token")
      // );
      // console.log("call")
      validateForm();
      if (
        state.errorList.length != 0 ||
        state.isErrorStatus ||
        state.postLoadingCreateProject
      )
        return false;
      // if (!state.projectName && !state.projectDescription) return false;
      const payLoad = {
        name: state.projectName,
        description: state.projectDescription,
        // address: state.projectAddress,
        // streetAddress: state.googleAddress.streetAddress,
        // city: state.googleAddress.city,
        // route: state.googleAddress.route,
        // state: state.googleAddress.state,
        // postalCode: state.googleAddress.postalCode,
        // country: state.googleAddress.country,
        tags: state.data.tags.value,
        latitude: latitude.value,
        longitude: longitude.value,
        // eslint-disable-next-line @typescript-eslint/camelcase
        full_address: address.value,
      };
      state.postLoadingCreateProject = true;
      // console.log("CHECK the Payload:>>", payLoad);
      ethitransService
        .createProject(payLoad)
        .then((res: any) => {
          if (state.data.subscriptionDetails.project_number != 0) {
            state.data.subscriptionDetails.project_number--
          }
          console.log("CreateProject Response Check:>>> ", res.data.data);
          // getProjectList();
          // state.projectList[0] = res.data.data;
          state.data.createProjectId = res.data.data.id;
          state.data.createProjectUuid = res.data.data.uuid;
          if (state.projectList && state.projectList.length != 0) {
            // alert("LENGTH");
            if (state.projectList.length === 6) {
              state.projectList.unshift(res.data.data);
              // state.projectList.splice(-1, 1);
            } else {
              state.projectList.unshift(res.data.data);
            }
          } else {
            state.projectList.push(res.data.data);
          }
          state.data.isProjectCreated = true;
          $("#createProject").modal("hide");
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.SuccessFulMessage = "Project Created Successfully.";
          state.data.subTitleMessage = "Now create a channel so members can communicate."; 
          // $("#createProject").modal("hide");
          // router.push({
          //   name: 'admin-project-details',
          //   params: { projectId: res.data.data.id },
          // });
          // redirectToInvite();
          state.data.tags.value = [];
          resetMapData();
        })
        .catch((error: any) => {
          // console.log(error);
          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.errorList.push(value[0]);
              }
            }
          });
        })
        .finally(() => {
          state.data.tags.value = [];
          selectedOptions.value = [];
          state.postLoadingCreateProject = false;
          resetMapData();
        });
    }

    function getAddressDataAll(addressData: any, placeResultData: any, id: any) {
      console.log("Address data", addressData);
      state.googleAddress.streetAddress = addressData.street_number;
      state.googleAddress.city = addressData.locality + ' ' + addressData.administrative_area_level_2;
      state.googleAddress.route = addressData.route;
      state.googleAddress.state = addressData.administrative_area_level_1;
      state.googleAddress.postalCode = addressData.postal_code;
      state.googleAddress.country = addressData.country;
      state.googleAddress.latitude = addressData.latitude;
      state.googleAddress.longitude = addressData.longitude
        ;
    }
    function redirectToProjectDetails() {
      router.push({
        name: "admin-project-details",
        params: { projectId: state.data.createProjectUuid },
        query: {channel: 'true' },
      });
    }
    function projectRestore() {
      state.ui.error.errorsList.projectArchive = [];
      state.ui.error.isErrorStatus = false;

      // validateForm();
      if (
        state.ui.error.errorsList.projectArchive.length != 0 ||
        state.ui.error.isErrorStatus ||
        state.ui.isPostLoading.projectArchive
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.data.activeProjectId,
      };
      state.ui.isPostLoading.projectArchive = true;

      console.log("CHECK THE ID >>> ", payLoad)
      ethitransService
        .projectDelete(payLoad)
        .then((res: any) => {
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.SuccessFulMessage = "Project Successfully Archived.";
          hideCustomModal();
          // emit("selectedProject", res.data.data);
          selectedProject(state.data.activeProjectId) 
          // getProjectDetail();
          // if (res) {
          //   // state.channelList.unshift(res.data.data)
          // }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.error.errorsList.projectArchive)
            .then((res: any) => {
              state.ui.error.isErrorStatus = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.projectArchive.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.projectArchive = false;
        });
    }
    return {
      state,
      closeSuccessCustomModal,
      projectRestore,
      preventKeyScroll,
      preventScroll,
      setActiveProjectId,
      showCustomModal,
      hideCustomModal,
      hideUpdateSubscriptionModal,
      toggleTourGuide,
      loadMoreProjectList,
      changeProjectByStatus,
      updateProjectDetails,
      selectedProject,
      projectList,
      getProjectList,
      userStatus,
      addTag,
      masterTagList,
      channelInsert,
      projectListActivities,
      hideCreateProjectModal,
      getAddressData,
      clearAddressData,
      redirectToCreateProject,
      redirectToInvite,
      createProject,
      redirectToUseTemplate,
      hideInvite,
      validateForm,
      reloadPage,
      getProjectInfo,
      allProject,
      redirectToProjectDetails,
      updateProjectList,
      options,
      selectedOptions,
      allProjectLists,
      projectSelected,
      getAddressDataAll,
      address,
      showTour,
      latitude,
      longitude,
      showMap,
      updateLatitude,
      updateLongitude,
      updateAddress,
      resetMapData
    };
  },
});
