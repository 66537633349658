import { config } from "@/Config";

class EthitransApi {
  // OTP registration and login
  public sendOtpCode() {
    return `${config.apiBaseUrl}send/otp/code`;
  }
  public verifyOtpCode() {
    return `${config.apiBaseUrl}verify/otp/code`;
  }
  public registration() {
    return `${config.apiBaseUrl}register`;
  }
  // for Login
  public sentOtpForLogin() {
    return `${config.apiBaseUrl}login/otp`;
  }
  public verifyOtpCodeForLogin() {
    return `${config.apiBaseUrl}password-less`;
  }


  public memberPhaseDetails() {
    return `${config.apiBaseUrl}todo/phase/member_detail`;
  }
  // subscription APIs
  public registrationsDetailList() {
    return `${config.apiBaseUrl}register/index`;
  }
  public subscriptionList() {
    return `${config.apiBaseUrl}subscription/all`;
  }
  public subscriptionLabelList() {
    return `${config.apiBaseUrl}subscription/create`;
  }
  public createSubscription() {
    return `${config.apiBaseUrl}subscription`;
  }
  public packageDetailsByUser() {
    return `${config.apiBaseUrl}subscription`;
  }
  public subscriptionDetails(subscriptionId: string) {
    return `${config.apiBaseUrl}subscription/detail?id=${subscriptionId}`;
  }
  public deleteSubscription(subscriptionId: string) {
    return `${config.apiBaseUrl}subscription/delete?id=${subscriptionId}`;
  }
  public updateSubscription() {
    return `${config.apiBaseUrl}subscription/update`;
  }

  public deleteScheduleById(scheduleId: any) {
    return `${config.apiBaseUrl}appointment/delete?id=${scheduleId}`;
  }
 
  // add member for phase get api
  public eventsByCalender(uuid: any, startDate: any, endDate: any) {
    return `${config.apiBaseUrl}todo/calendar/events?id=${uuid}&start_date=${startDate}&end_date=${endDate}`;
  }
  public addMemberInPhase(phaseId: any) {
    return `${config.apiBaseUrl}phase/add_member?phase_id=${phaseId}`;
  }
  public detailsByemail(emailId: any) {
    return `${config.apiBaseUrl}user/detail?email=${emailId}`;
  }
  public updateAppointment() {
    return `${config.apiBaseUrl}appointment/update`;
  }
  public invoiceSentList() {
    return `${config.apiBaseUrl}todo/invoice/sent`;
  }
  public calenderGroupList() {
    return `${config.apiBaseUrl}todo/calendar/groups`;
  }
  public invoiceReceiverList() {
    return `${config.apiBaseUrl}todo/create_invoice`;
  }
  public invoiceList() {
    return `${config.apiBaseUrl}todo/invoice_new`;
  }
  // to do
  public receivedInvocieByPhaseId() {
    return `${config.apiBaseUrl}todo/invoice/received/list`;
  }
  // to do
  public updateTask() {
    return `${config.apiBaseUrl}phase/task`;
  }
  public createNewRole() {
    return `${config.apiBaseUrl}phase/role`;
  }
  public availabilityCheckByUser() {
    return `${config.apiBaseUrl}todo/availability`;
  }
  public createNewAmountType() {
    return `${config.apiBaseUrl}phase/amounts`;
  }
  public updateNewAmountType() {
    return `${config.apiBaseUrl}phase/amounts/update`;
  }
  public addMembersInPhases() {
    return `${config.apiBaseUrl}phase/add_member`;
  }
  public taskUpdate() {
    return `${config.apiBaseUrl}phase/update
    `;
  }
  public projectSetToDo() {
    return `${config.apiBaseUrl}todo`;
  }
  public memberListByProject() {
    return `${config.apiBaseUrl}project/member/user`;
  }
  public getPhaseDetail() {
    return `${config.apiBaseUrl}phase/create`;
  }
  public payInvoiceByStripe() {
    return `${config.apiBaseUrl}todo/invoice/pay_stripe`;
  }
  public todoInvoiceDraftById() {
    return `${config.apiBaseUrl}todo/invoice/draft/list`;
  }
  public stripeAccountCheck(invoiceId: string) {
    return `${config.apiBaseUrl}todo/stripeaccount?invoice_id=${invoiceId}`;
  }
  public invoiceCompletedById(invoiceId: string) {
    return `${config.apiBaseUrl}todo/invoice/completed?id=${invoiceId}`;
  }
  public acceptSelectedInvitation() {
    return `${config.apiBaseUrl}phase/accept_invitation`;
  }
  public declineSelectedInvitation() {
    return `${config.apiBaseUrl}phase/declined`;
  }
  public setPhaselog() {
    return `${config.apiBaseUrl}phase/log`;
  }
  public shareProjectPhaseList(id: string) {
    return `${config.apiBaseUrl}project_share?id=${id}`;
  }

  public getPhaseByTask(id: string) {
    
    return `${config.apiBaseUrl}project_share/phase?phase_id=${id}`;
  }

  public removeProjectShareInvitation(id: string) {
    
    return `${config.apiBaseUrl}project_share/delete?id=${id}`;
  }

  public communicationDetails(id: string) {
    
    return `${config.apiBaseUrl}channel/communication/detail?communication_id=${id}`;
  }

  public phaseLogList(phaseId: string, name: string, fromDate: string, toDate: string) {
    return `${config.apiBaseUrl}phase/log?phase_id=${phaseId}&name=${name}&from_date=${fromDate}&to_date=${toDate}`;
    // return `${config.apiBaseUrl}phase/discussion?phase_id=${phaseId}&channel_id=${channelId}`;
  }
  public phaseDiscussionChanelByPhaseAndChannelId(phaseId: string, name: string, channelId: string) {
    return `${config.apiBaseUrl}phase/discussion?phase_id=${phaseId}&name=${name}&channel_id=${channelId}`;
    // return `${config.apiBaseUrl}phase/discussion?phase_id=${phaseId}&channel_id=${channelId}`;
  }
  // payment section
  public phaseTaskInvoice() {
    return `${config.apiBaseUrl}todo/task_invoice`;
  }
  public phaseInvoiceListById() {
    return `${config.apiBaseUrl}todo/invoice/list`;
  }
  public phaseInvoiceReceivedByPhaseId() {
    return `${config.apiBaseUrl}todo/invoice/received/list`;
  }
  // payment section
  public phaseGenerateInvoice() {
    return `${config.apiBaseUrl}todo/invoice`;
  }
  public phaseCompleted() {
    return `${config.apiBaseUrl}phase/complete`;
  }
  public paymentRequestByPhase() {
    return `${config.apiBaseUrl}phase/payment_request`;
  }
  public paymentRequestAcceptByPhase() {
    return `${config.apiBaseUrl}phase/payment_accept`;
  }
  public phasePrivateChannelByPhaseIdUserId(phaseId: number, userId: string, projectId: string) {
    return `${config.apiBaseUrl}phase/channel_private?phase_id=${phaseId}&user_id=${userId}&project_id=${projectId}`;
  }
  public paymentHistoryByPhase(phaseId: string, type: number) {
    return `${config.apiBaseUrl}phase/payment_history?id=${phaseId}&type=${type}`;
  }
  public phaseApprovalChannel(invitationId: string) {
    return `${config.apiBaseUrl}phase/approval_channel?id=${invitationId}`;
  }
  public invoiceByPhaseOrTask(invoiceId: string, mode: number) {
    return `${config.apiBaseUrl}todo/invoice?id=${invoiceId}&mode=${mode}`;
  }
  // public invoiceByPhaseOrTask(invoiceId: string) {
  //   return `${config.apiBaseUrl}todo/invoice?id=${invoiceId}`;
  // }
  public phaseInvitationChannel(invitationId: string) {
    return `${config.apiBaseUrl}phase/invitation_channel?id=${invitationId}`;
  }
  public phaseInvitation(phaseId: string) {
    return `${config.apiBaseUrl}phase/invitation_phase?id=${phaseId}`;
  }

  public projectShareMemberInvitation(invitaionId: string) {
    return `${config.apiBaseUrl}project_share/invitation?id=${invitaionId}`;
  }

  public projectShareMemberInvitationDetails(invitaionId: string) {
    return `${config.apiBaseUrl}project_share/detail?id=${invitaionId}`;
  }

  public projectShareMemberInvitationEdit(invitaionId: string) {
    return `${config.apiBaseUrl}project_share/get?id=${invitaionId}`;
  }

  public channelsByPhaseId(phaseId: string) {
    return `${config.apiBaseUrl}phase/channels?phase_id=${phaseId}`;
  }
  public phaseDiscussionChannel(uuid: string) {
    return `${config.apiBaseUrl}phase/discussion?phase_id=${uuid}`;
  }
  public payerDiscussionChannel(uuid: string) {
    return `${config.apiBaseUrl}payer/discussion?id=${uuid}`;
  }
  public estimationDiscussionChannel(uuid: string) {
    return `${config.apiBaseUrl}estimation/discussion?estimation_id=${uuid}`;
  }
  public acceptProjectApprovalInvitation(uuid: string) {
    return `${config.apiBaseUrl}payer/accept?id=${uuid}`;
  }
  public declineProjectApprovalInvitation() {
    return `${config.apiBaseUrl}payer/decline`;
  }
  public acceptEstimation() {
    return `${config.apiBaseUrl}payer/estimate/accept`;
  }
  public projectAcknowledgementApprovalDetail(projectId: string) {
    return `${config.apiBaseUrl}payer/approval?project_id=${projectId}`;
  }
  public projectAcknowledgementRequestDetail(invitationId: string) {
    return `${config.apiBaseUrl}payer?payer_request_id=${invitationId}`;
  }
  public projectDocumentDelete(uuid: string) {
    return `${config.apiBaseUrl}payer/delete_document?id=${uuid}`;
  }
  public projectAcknowledgement() {
    return `${config.apiBaseUrl}payer`;
  }
  public createPhaseForToDo() {
    return `${config.apiBaseUrl}phase`;
  }
  public updatePhaseForToDo() {
    return `${config.apiBaseUrl}phase/update`;
  }
  public todoDetailsByProjectId(projectId: string, searchString: string, startDate: string, endDate: string, status: string) {
    return `${config.apiBaseUrl}todo/detail?smart_contract_id=${projectId}&search=${searchString}&start_at=${startDate}&end_at=${endDate}&status=${status}`;
    // return `${config.apiBaseUrl}todo/detail?project_id=${projectId}&search=${searchString}&start_at=${startDate}&end_at=${endDate}&status=${status}`;
  }
  public phaseDetailsById(phaseId: string) {
    return `${config.apiBaseUrl}phase/detail?phase_id=${phaseId}`;
  }
  public phaseDetailsByTaskUpdateId(phaseId: string) {
    return `${config.apiBaseUrl}phase/notify_detail?id=${phaseId}`;
  }
  public taskDetails(phaseId: string) {
    return `${config.apiBaseUrl}phase/edit?phase_id=${phaseId}`;
  }
  public acceptToDoInvitation(uuid: string) {
    return `${config.apiBaseUrl}todo/accept_invitation?id=${uuid}`;
  }
  public declineToDoInvitation(uuid: string) {
    return `${config.apiBaseUrl}todo/declined?id=${uuid}`;
  }
  public acceptPhaseInvitation(uuid: string) {
    return `${config.apiBaseUrl}phase/accept_invitation?id=${uuid}`;
  }
  public channelExistCheckForPhase(uuid: string) {
    return `${config.apiBaseUrl}phase/channel_exist?phase_id=${uuid}`;
  }
  // public declinePhaseInvitation(uuid: string) {
  //   return `${config.apiBaseUrl}phase/declined?id=${uuid}`;
  // }
  public declinePhaseInvitation() {
    return `${config.apiBaseUrl}phase/declined`;
  }

  public declineProjectShareInvitation() {
    return `${config.apiBaseUrl}project_share/decline`;
  }

  public userEnterSettingUpdate() {
    return `${config.apiBaseUrl}user/enter_key/update`;
  }
  public tourGuideUpdate() {
    return `${config.apiBaseUrl}user/tour/update`;
  }
  public channelUpdate() {
    return `${config.apiBaseUrl}channel/update`;
  }
  public projectUpdate() {
    return `${config.apiBaseUrl}project/update`;
  }
  public projectDelete() {
    return `${config.apiBaseUrl}project/delete`;
  }
  public projectRestore() {
    return `${config.apiBaseUrl}project/recover`;
  }
  public projectFav() {
    return `${config.apiBaseUrl}project/make/fav`;
  }
  public deletePhaseRole() {
    return `${config.apiBaseUrl}phase/role/delete`;
  }
  public deleteAmountType() {
    return `${config.apiBaseUrl}phase/amounts/delete`;
  }
  public projectFavRemove() {
    return `${config.apiBaseUrl}project/remove/fav`;
  }
  public projectHide() {
    return `${config.apiBaseUrl}project/hide`;
  }
  public projectImageDelete() {
    return `${config.apiBaseUrl}project/image/delete`;
  }
  public projectImageEdit() {
    return `${config.apiBaseUrl}project/image/update`;
  }
  public projectShow() {
    return `${config.apiBaseUrl}project/show`;
  }
  public channelHide() {
    return `${config.apiBaseUrl}channel/hide`;
  }
  public channelShow() {
    return `${config.apiBaseUrl}channel/show`;
  }
  public registerReplace() {
    return `${config.apiBaseUrl}register/replace`;
  }
  public replaceInvitation() {
    return `${config.apiBaseUrl}invitation/replace`;
  }
  public userStatus() {
    return `${config.apiBaseUrl}user/status`;
  }
  public userSignature() {
    return `${config.apiBaseUrl}user/signature`;
  }
  public searchFileTags() {
    return `${config.apiBaseUrl}search/file/tag-wise`;
  }
  public recentlyUsedTags() {
    return `${config.apiBaseUrl}recent/uses/tag`;
  }
  public createFileTags() {
    return `${config.apiBaseUrl}file-tag/create`;
  }
  public createMasterFileTag() {
    return `${config.apiBaseUrl}master-file-tag/create`;
  }
  public enableMasterFileTag() {
    return `${config.apiBaseUrl}master-file-tag/enable`;
  }
  public disableMasterFileTag() {
    return `${config.apiBaseUrl}master-file-tag/disable`;
  }
  public deleteMasterFileTag() {
    return `${config.apiBaseUrl}master-file-tag/delete`;
  }
  public masterFileTagList() {
    return `${config.apiBaseUrl}master-file-tag/list`;
  }
  public selectedSharedFileToDelete() {
    return `${config.apiBaseUrl}shared-file/delete`;
  }
  public updateValidityDate() {
    return `${config.apiBaseUrl}user/validity_date/update`;
  }
  public guestUserList() {
    return `${config.apiBaseUrl}guest_user/list`;
  }
  public selectedFileRename() {
    return `${config.apiBaseUrl}shared-file/rename`;
  }
  public sharedFiles() {
    return `${config.apiBaseUrl}shared-file/list`;
  }
  public fileShare() {
    return `${config.apiBaseUrl}file/share`;
  }
  public fileUpload() {
    return `${config.apiBaseUrl}file/upload`;
  }
  public projectImageUpload() {
    return `${config.apiBaseUrl}project/image/upload`;
  }
  public getProjectImage(projectId: any) {
    return `${config.apiBaseUrl}project/image/list?project_id=${projectId}`;
  }
  public profilePicUpload() {
    return `${config.apiBaseUrl}user/profile/image/upload`;
  }
  public uploadUSerSignature() {
    return `${config.apiBaseUrl}user/profile/signature/upload`;
  }
  public fileDownload() {
    return `${config.apiBaseUrl}file/download`;
  }
  public fileRename() {
    return `${config.apiBaseUrl}file/rename`;
  }
  public fileDelete() {
    return `${config.apiBaseUrl}file/delete`;
  }
  public fileDetail() {
    return `${config.apiBaseUrl}file/detail`;
  }
  public folderDownload() {
    return `${config.apiBaseUrl}folder/download`;
  }
  public folderDelete() {
    return `${config.apiBaseUrl}folder/delete`;
  }
  public folderRename() {
    return `${config.apiBaseUrl}folder/rename`;
  }
  public folderList(status: any) {
    return `${config.apiBaseUrl}folder/list?status=${status}`;
  }
  public folderDetails() {
    return `${config.apiBaseUrl}folder/detail`;
  }
  public createFolder() {
    return `${config.apiBaseUrl}folder/create`;
  }
  public renameCommunicationAttachment() {
    return `${config.apiBaseUrl}channel/communication/attachment/rename`;
  }
  public deleteCommunication() {
    return `${config.apiBaseUrl}channel/communication/delete`;
  }
  public deleteReplyCommunication() {
    return `${config.apiBaseUrl}channel/communication/response/replydelete`;
  }
  public deleteReplyAttachementCommunication() {
    return `${config.apiBaseUrl}channel/communication/response/attachment/delete`;
  }
  public forwardCommunication() {
    return `${config.apiBaseUrl}channel/communication/forward`;
  }
  public channelCommunicationTyping() {
    return `${config.apiBaseUrl}channel/communication/type-indicator`;
  }
  public deletePendingInvitation() {
    return `${config.apiBaseUrl}invitation/pending/delete`;
  }
  public verifyInvitation() {
    return `${config.apiBaseUrl}invitation/verify`;
  }

  public verifyReferalInvitation() {
    return `${config.apiBaseUrl}referral/verify`;
  }
  public sendReferalInvitation() {
    return `${config.apiBaseUrl}referral`;
  }
  public sendReferalInvitationList(referralId: any) {
    return `${config.apiBaseUrl}referral/${referralId}`;
  }
  public listReferralLink() {
    return `${config.apiBaseUrl}referral_link/list`;
  }
  public createReferralLink() {
    return `${config.apiBaseUrl}referral_link/create`;
  }
  public updateReferralLink() {
    return `${config.apiBaseUrl}referral_link/update`;
  }
  public verifyProjectInvitation() {
    return `${config.apiBaseUrl}project_share/verify`;
  }

  public masterOccupationList() {
    return `${config.apiBaseUrl}master-occupation/list`;
  }
  public invitationResend() {
    return `${config.apiBaseUrl}invitation/resend`;
  }
  public createIssue() {
    return `${config.apiBaseUrl}issue/create`;
  }
  public masterTagList() {
    return `${config.apiBaseUrl}master-tag/list`;
  }

  public allProjectList(status: any) {
    return `${config.apiBaseUrl}project/all?status=${status}`;
  }

  public createMasterTag() {
    return `${config.apiBaseUrl}master-tag/create`;
  }
  public projectFileDownload() {
    return `${config.apiBaseUrl}channel/file/download`;
    // return `${config.apiBaseUrl}project/file/download`;
  }
  public logOut() {
    return `${config.apiBaseUrl}logout`;
  }
  public channelRecentlyVisited() {
    return `${config.apiBaseUrl}channel/recently-visited/create`;
  }
  public createResponseChannelCommunication() {
    return `${config.apiBaseUrl}channel/communication/response/create`;
  }
  public updateUserNotification() {
    return `${config.apiBaseUrl}user/notification/update`;
  }
  public userProfileDetails() {
    return `${config.apiBaseUrl}user/profile/retrieve`;
  }
  public updateUserProfileDetails() {
    return `${config.apiBaseUrl}user/profile/update`;
  }
  public updateUserProfileContact() {
    return `${config.apiBaseUrl}user/profile/update/contact`;
  }
  public clientDocumentRetrieveWorkflowLink() {
    return `${config.apiBaseUrl}client_document/workflowlink/retrieve`;
  }
  public deleteClientDocument() {
    return `${config.apiBaseUrl}client_document/delete`;
  }
  public clientDocumentStatusUpdate() {
    return `${config.apiBaseUrl}client_document/status/update`;
  }
  public disableClientDocument() {
    return `${config.apiBaseUrl}client_document/disable`;
  }
  public clientDocumentRetrieve() {
    return `${config.apiBaseUrl}client_document/retrieve`;
  }
  public projectMemberListByChannel() {
    return `${config.apiBaseUrl}project/member/list`;
  }
  public uploadClientDocument() {
    return `${config.apiBaseUrl}client_document/upload`;
  }
  public clientDocumentList() {
    return `${config.apiBaseUrl}client_document/list`;
  }
  public enableClientDocument() {
    return `${config.apiBaseUrl}client_document/enable`;
  }
  public edocsList() {
    return `${config.apiBaseUrl}edocs/list`;
  }
  public getEdocsMetadata() {
    return `${config.apiBaseUrl}edocs/metadata/get`;
  }
  public generateEdocStatement() {
    return `${config.apiBaseUrl}edocs/statement/generate`;
  }
  public validateRegistration() {
    return `${config.apiBaseUrl}register/validate`;
  }
  public createProject() {
    return `${config.apiBaseUrl}project/create`;
  }
  public projectList() {
    return `${config.apiBaseUrl}project/list`;
  }

  public projectInfo() {
    return `${config.apiBaseUrl}project/info`;
  }

  public channelMemberList() {
    return `${config.apiBaseUrl}channel/member/list`;
  }
  public channelNotificationList() {
    return `${config.apiBaseUrl}channel/notifications/list`;
  }
  public communicationPage() {
    return `${config.apiBaseUrl}channel/communication/page`;
  }
  public userList() {
    return `${config.apiBaseUrl}user/list`;
  }
  public getProjectChannel(projectId: any, name: any , status: any) {
    return `${config.apiBaseUrl}project/channel?project_id=${projectId}&name=${name}&status=${status}`;
  }
  public projectDetails() {
    return `${config.apiBaseUrl}project/detail`;
  }
  public projectChannelDetails() {
    return `${config.apiBaseUrl}project/channel/detail`;
  }
  public createChannel() {
    return `${config.apiBaseUrl}channel/create`;
  }
  public closeChannel() {
    return `${config.apiBaseUrl}channel/close`;
  }
  public createCommunicationList() {
    // return `${config.apiBaseUrl}channel/communication/list`;
    // return `${config.apiBaseUrl}channel/chat/list`;
    return `${config.apiBaseUrl}channel/communication/list`;
    // return `${config.apiBaseUrl}channel/chat/list`;
  }
  public createCommunication() {
    // return `${config.apiBaseUrl}channel/chat/create`;
    return `${config.apiBaseUrl}channel/communication/create`;
  }

  public getInstanceMessage() {
    return `${config.apiBaseUrl}channel/communication/instance/message`;
  }

  public editCommunication() {
    return `${config.apiBaseUrl}channel/communication/edit`;
  }

  public editReplyCommunication() {
    return `${config.apiBaseUrl}channel/communication/response/edit`;
  }

  public uploadAttachment() {
    return `${config.apiBaseUrl}channel/communication/attachment/upload`;
  }
  public uploadReplyAttachment() {
    return `${config.apiBaseUrl}channel/communication/response/upload`;
  }
  public deleteAttachment() {
    return `${config.apiBaseUrl}channel/communication/attachment/delete`;
  }
  public userSearch() {
    return `${config.apiBaseUrl}user/search`;
  }
  public invitation() {
    return `${config.apiBaseUrl}invitation/create`;
  }

  public invitationPublicUser() {
    return `${config.apiBaseUrl}public/user/invite`;
  }

  public inviteProjectShare() {
    return `${config.apiBaseUrl}project_share`;
  }

  public inviteProjectShareUpdate() {
    return `${config.apiBaseUrl}project_share/update`;
  }

  public projectRoleList() {
    return `${config.apiBaseUrl}project/role/list`;
  }

  public changeProjectRole() {
    return `${config.apiBaseUrl}project/role/change`;
  }
  public invitationPendingList() {
    return `${config.apiBaseUrl}invitation/pending/list`;
  }
  public joinInvitation() {
    return `${config.apiBaseUrl}invitation/pending/join`;
  }
  public joinProjectShareInvitation() {
    return `${config.apiBaseUrl}project_share/accept`;
  }
  public declineInvitation() {
    return `${config.apiBaseUrl}invitation/pending/decline`;
  }
  public removeChannelMember() {
    return `${config.apiBaseUrl}channel/member/remove`;
  }
  public muteChannelMember() {
    return `${config.apiBaseUrl}channel/member/mute`;
  }
  public removeProjectMember() {
    return `${config.apiBaseUrl}project/member/remove`;
  }
  public channelCommunicationFormData() {
    return `${config.apiBaseUrl}channel/communication/form_data/save`;
  }
  public accountFormList() {
    // return `${config.apiMangoBaseUrl}users`;
    return `${config.apiMangoBaseUrl}account_form/list`;
  }
  public accountformSave() {
    return `${config.apiMangoBaseUrl}account_form/save`;
  }
  public accountformResponseSave() {
    return `${config.apiMangoBaseUrl}account_form/response/save`;
  }
  public accountFormRetrieveById(id: any) {
    return `${config.apiMangoBaseUrl}account_form/retrieve/${id}`;
  }
  public accountFormEditById(id: any) {
    return `${config.apiMangoBaseUrl}account_form/edit/${id}`;
  }
  public accountFormResponseByEmailAndId(email: any,id: any) {
    return `${config.apiMangoBaseUrl}account_form/response/by_email/${email}/${id}`;
  }
  public accountFormListSearchList() {
    // return `${config.apiMangoBaseUrl}account_form/list/search`;
    return `${config.apiMangoBaseUrl}account_form/my-list/search`;
  }
  public projectAccountFormListSearch() {
    return `${config.apiMangoBaseUrl}project/account_form/search`;
  }
  public channelAccountFormListSearch() {
    return `${config.apiMangoBaseUrl}channel/account_form/search`;
  }
  public templateSearch() {
    return `${config.apiMangoBaseUrl}templates/search`;
  }

  public formCategorySave() {
    return `${config.apiMangoBaseUrl}form_category/save`;
  }

  public formCategoryList() {
    return `${config.apiMangoBaseUrl}form_category/list`;
  }

  public getprojectList() {
    return `${config.apiBaseUrl}project/list`;
  }
  public projectListActivities() {
    return `${config.apiBaseUrl}project/activities/list`;
  }
  public getChannelListByProject() {
    return `${config.apiBaseUrl}project/channel/list`;
    // return `${config.apiBaseUrl}channel/list`;
  }
  public getChannelList() {
    return `${config.apiBaseUrl}channel/list`;
  }
  public notificationReadInChannel() {
    return `${config.apiBaseUrl}channel/notifications/read`;
  }

  public notificationAllReadInChannel() {
    return `${config.apiBaseUrl}channel/notifications/read/all`;
  }

  //chat bot
  public chateBotApiCall() {
    return `${config.ChatBotUrl}api/v3/bot`;
    // return `${config.ChatBotUrl}api/v2/bot`;
  }
 /*********************************  APPOINTMENT START HERE  *****************************/
  public getProjectUser() {
    return `${config.apiBaseUrl}get/project/user`;
  }
  public getAuthUrl() {
    return `${config.apiBaseUrl}appointment/auth_url`;
  }

  public syncGoogleEvents(){
    return `${config.apiBaseUrl}appointment/sync`;
  }

  public getUserAppointment() {
    return `${config.apiBaseUrl}appointment/list`;
  }

  public getAppointmentDetail(eventId: number) {
    return `${config.apiBaseUrl}appointment/detail?id=`+eventId;
  }

  public importAppointment() {
    return `${config.apiBaseUrl}appointment/import`;
  }

  public createAppointment() {
    return `${config.apiBaseUrl}appointment/create`;
  }

  //zoom 
  public createZoomMeeting(){
    return `${config.apiBaseUrl}zoom/meeting/create`
  }
  /*********************************  APPOINTMENT END HERE  *****************************/

  public getallActiveUser() {
    return `${config.apiBaseUrl}user/active/list`;
  }
  public userLog() {
    return `${config.apiBaseUrl}user/log`;
  }

  public userExport() {
    return `${config.apiBaseUrl}user/export`;
  }

  public userLogExport() {
    return `${config.apiBaseUrl}user/log/export`;
  }

  public getCountryCodeList() {
    return `${config.apiBaseUrl}country/code`;
  }

  public sendOtp() {
    return `${config.apiBaseUrl}send/otp/code`;
  }

  public verifyMobile() {
    return `${config.apiBaseUrl}verify/mobile`;
  }
  public checkMobile() {
    return `${config.apiBaseUrl}user/check/mobile`;
  }
  public sendBusinessMail() {
    return `${config.apiBaseUrl}business/mail/sent`;
  }

  public resendBusinessMail() {
    return `${config.apiBaseUrl}business/mail/resent`;
  }

  public businessMailTemplateList() {
    return `${config.apiBaseUrl}business/mail/template`;
  }

  public createBusinessMailTemplate() {
    return `${config.apiBaseUrl}business/mail/template/create`;
  }

  public editBusinessMailTemplate() {
    return `${config.apiBaseUrl}business/mail/template/edit`;
  }

  public updateBusinessMailTemplate() {
    return `${config.apiBaseUrl}business/mail/template/update`;
  }

  public businessMailLogList() {
    return `${config.apiBaseUrl}business/mail/log`;
  }

  public projectTagList() {
    return `${config.apiBaseUrl}master-tag/user/wise`;
  }

  public allProjectTagList() {
    return `${config.apiBaseUrl}master-tag/all`;
  }

  public updateTagStatus() {
    return `${config.apiBaseUrl}master-tag/update/status`;
  }

  public createDocusignTemplate() {
    return `${config.apiBaseUrl}client_document/docu/templates/create`;
  }

  public getDocusignTemplate() {
    return `${config.apiBaseUrl}client_document/docu/templates/list`;
  }
  
  public docusignTempleteDetails(uuid: string) {
    return `${config.apiBaseUrl}client_document/docu/templates/show?id=${uuid}`;
  }

  public docusignDocumentStatus(uuid: string) {
    return `${config.apiBaseUrl}client_document/docu/templates/ready?id=${uuid}`;
  }

  public docusignShareDocumentDetails(uuid: string) {
    return `${config.apiBaseUrl}client_document/docu/envelopes/show?id=${uuid}`;
  }

  public envelopeSign(uuid: string) {
    return `${config.apiBaseUrl}client_document/docu/envelopes/sign?id=${uuid}`;
  }

  public envelopeEdit(uuid: string) {
    return `${config.apiBaseUrl}client_document/docu/templates/edit?id=${uuid}`;
  }
  public envelopeDelete(uuid: string) {
    return `${config.apiBaseUrl}client_document/docu/templates/delete?id=${uuid}`;
  }
  public envelopeCreate() {
    return `${config.apiBaseUrl}client_document/docu/envelopes/create`;
  }

  public getSendEnvelopeDocumentList() {
    return `${config.apiBaseUrl}client_document/docu/envelopes/list
    `;
  }

  public getReceivesEnvelopeDocumentList() {
    return `${config.apiBaseUrl}client_document/docu/envelopes/waiting`;
  }

  public sendMessageFromEnvelope() {
    return `${config.apiBaseUrl}client_document/docu/envelopes/send`;
  }

  public sendMessageSignFromEnvelope() {
    return `${config.apiBaseUrl}client_document/docu/envelopes/sendsign`;
  }

  public craeteTutorial() {
    return `${config.apiBaseUrl}tutorial/create`;
  }

  public updateTutorial() {
    return `${config.apiBaseUrl}tutorial/update`;
  }

  public deleteTutorial() {
    return `${config.apiBaseUrl}tutorial/delete`;
  }

  public updateStatusTutorial() {
    return `${config.apiBaseUrl}tutorial/status`;
  }

  public getAllTutorial() {
    return `${config.apiBaseUrl}tutorial/list`;
  }

  public getTutorial(type: string) {
    return `${config.apiBaseUrl}tutorial/get?type=${type}`;
  }

  public editTutorial(uuid: string) {
    return `${config.apiBaseUrl}tutorial/edit?id=${uuid}`;
  }

  public uploadEditorImage() {
    return `${config.apiBaseUrl}editor/upload`;
  }

  public userProjectCalendar(userUUId: any) {
    return `${config.apiBaseUrl}todo/calendar?id=${userUUId}`;
  }

  public getProjectCalendarMonthlyData(userUUId: any) {
    return `${config.apiBaseUrl}todo/calendar?id=${userUUId}`;
  }

  public getProejctWiseUser(userUUId: any) {
    return `${config.apiBaseUrl}todo/calendar/users?id=${userUUId}`;
  }

  public pendingChannelInvitation(UUId: any) {
    return `${config.apiBaseUrl}invitation/pending/channel/list/${UUId}`;
  }

  public channelReorder() {
    return `${config.apiBaseUrl}channel/reorder`;
  }

  public createPrivateChannelGroup() {
    return `${config.apiBaseUrl}channel/create/private`;
  }

  public exportCommunicationPdf() {
    return `${config.apiBaseUrl}channel/communication/export`;
  }

  public exportCommunicationPdfList(id: any) {
    return `${config.apiBaseUrl}channel/communication/export/${id}`;
  }
  public getEstimationCreateDetails(projectId: any) {
    return `${config.apiBaseUrl}payer/estimate?project_id=${projectId}`;
  }

  public createProjectEstimation() {
    return `${config.apiBaseUrl}payer/estimate`;
  }

  public getEstimationEditDetails(projectId: any) {
    return `${config.apiBaseUrl}payer/estimate/show?project_id=${projectId}`;
  }

  public getJobEstimationDetails(id: any) {
    return `${config.apiBaseUrl}estimation/smartcontract?estimation_id=${id}`;
  }

  public getSendEstimationDetails() {
    return `${config.apiBaseUrl}payer/estimate/verify`;
  }
  public estimationSend() {
    return `${config.apiBaseUrl}payer/estimate/send`;
  }

  public createLineItem() {
    return `${config.apiBaseUrl}mainitem`;
  }

  public updateLineItem(uuid: any) {
    return `${config.apiBaseUrl}mainitem/${uuid}`;
  }

  public getItemList(name: any) {
    return `${config.apiBaseUrl}mainitem?name=${name}`;
  }

  public getActiveItemList(name: any) {
    return `${config.apiBaseUrl}mainitem/active?name=${name}`;
  }
  public getUnitList() {
    return `${config.apiBaseUrl}unit`;
  }

  public createUnit() {
    return `${config.apiBaseUrl}unit`;
  }

  public updateUnit(unitId: any) {
    return `${config.apiBaseUrl}unit/${unitId}`;
  }

  public updatePhaseRole() {
    return `${config.apiBaseUrl}phase/role/update`;
  }

  public deleteUnit(unitId: any) {
    return `${config.apiBaseUrl}unit/delete/${unitId}`;
  }

  public updateSubItem(subItemId: any) {
    return `${config.apiBaseUrl}mainitem/subitem/${subItemId}`;
  }

  public updateMarkupType() {
    return `${config.apiBaseUrl}update/markup/type`;
  }

  public deleteSubItem(id: any) {
    return `${config.apiBaseUrl}mainitem/subitem/delete/${id}`;
  }

  public lineItemDetails(itemId: any) {
    return `${config.apiBaseUrl}mainitem/${itemId}`;
  }

  public deleteItem(id: any) {
    return `${config.apiBaseUrl}mainitem/delete/${id}`;
  }

  public updateItemPostion() {
    return `${config.apiBaseUrl}mainitem/reorder`;
  }

  public createJobEstimation() {
    return `${config.apiBaseUrl}estimation`;
  }

  public updateJobEstimation() {
    return `${config.apiBaseUrl}estimation/update`;
  }

  public geJobEstimationList(projectId: any) {
    return `${config.apiBaseUrl}estimation?project_id=${projectId}`;
  }

  public getSmartContractList(projectId: any) {
    return `${config.apiBaseUrl}payer/smartcontract?project_id=${projectId}`;
  }
  
  public getSmartContractDetails(contractId: any) {
    return `${config.apiBaseUrl}estimation/smartcontract/detail?id=${contractId}`;
  }

  public geJobEstimationDetails(projectId: any) {
    return `${config.apiBaseUrl}estimation/detail?estimation_id=${projectId}`;
  }

  public geJobEstimationDetailsPreview(projectId: any) {
    return `${config.apiBaseUrl}estimation/preview?estimation_id=${projectId}`;
  }

  public deleteJobEstimation(id: any) {
    return `${config.apiBaseUrl}estimation/delete?estimation_id=${id}`;
  }

  public deleteJobEstimationItem(id: any) {
    return `${config.apiBaseUrl}estimation/deleteitem?id=${id}`;
  }

  public getJobestimationDetail(uuid: any) {
    return `${config.apiBaseUrl}estimation/detail?estimation_id=${uuid}`;
  }

  public sendJobEstimation() {
    return `${config.apiBaseUrl}estimation/send`;
  }

  public getJobestimationInviteDetail(uuid: any) {
    return `${config.apiBaseUrl}estimation/invitation?estimation_id=${uuid}`;
  }

  public declineJobEstimationInvitation() {
    return `${config.apiBaseUrl}estimation/decline`;
  }

  public acceptJobEstimationInvitation() {
    return `${config.apiBaseUrl}estimation/accept`;
  }

  public updateCompanyInfo() {
    return `${config.apiBaseUrl}user/profile/company`;
  }

  public changeItemStatus(uuid: any, status: any) {
    return `${config.apiBaseUrl}mainitem/archive?id=${uuid}&status=${status}`;
  }
  // public deleteCustomer() {
  //   // return `${config.apiBaseUrl}estimation/delete?customer_email=${id}`;
  //   return `${config.apiBaseUrl}estimation/deleteCustomer`;

  // }

}

export const ethitransApi = new EthitransApi();
