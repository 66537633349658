<template>
  <div>
    <input
      type="tel"
      class="form-control"
      v-model="formattedNumber"
      @input="onInput"
      @keydown="onKeydown"
      placeholder="+1 (XXX) XXX-XXXX"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    modelValue: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const formattedNumber = ref('');

    // Function to format the number in (xxx) xxx-xxxx format with +1
    function formatPhoneNumber(value) {
      const cleaned = value.replace(/\D/g, '');
      if (cleaned.length <= 3) {
        return `+1 (${cleaned}`;
      } else if (cleaned.length <= 6) {
        return `+1 (${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
      } else {
        return `+1 (${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
      }
    }

    // Handle input changes and format the phone number
    function onInput(event) {
      const input = event.target.value;
      let digitsOnly = input.replace(/\D/g, ''); // Remove all non-digit characters

      // Check if the user enters +1 manually and remove it
      if (input.startsWith('+1')) {
        digitsOnly = digitsOnly.slice(1); // Ignore +1 added by the user
      }

      // Limit to 10 digits (we'll add +1 automatically)
      if (digitsOnly.length > 10) {
        digitsOnly = digitsOnly.slice(0, 10);
      }

      // Format the input number with our +1 prefix
      formattedNumber.value = formatPhoneNumber(digitsOnly);

      // Emit the correctly formatted number with +1
      const finalNumber = digitsOnly ? `+1${digitsOnly}` : ''; // Emit an empty string if cleared
      emit('update:modelValue', finalNumber);
    }

    // Handle keydown to allow full backspacing, including +1
    function onKeydown(event) {
      if (event.key === 'Backspace' && formattedNumber.value.length === 0) {
        // Fully clear the input including +1
        formattedNumber.value = '';
        emit('update:modelValue', '');
      }
    }

    // Watch the prop to set the initial value of the input
    watch(() => props.modelValue, (newValue) => {
      if (newValue) {
        formattedNumber.value = formatPhoneNumber(newValue.replace('+1', '')); // Remove +1 for formatting
      } else {
        formattedNumber.value = ''; // Clear if empty
      }
    });

    return {
      formattedNumber,
      onInput,
      onKeydown
    };
  }
};
</script>
