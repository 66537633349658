<template>
  <div>
    <input class="form-control" id="email" name="email" v-model="inputValue" @input="validateInput"
      @keydown="limitInput" @blur="validateInput" placeholder="Enter email or mobile number" type="text"
      :disabled="disabled" />
    <!-- <p v-if="error" role="alert" aria-live="assertive">{{ error }}</p> -->
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false, // Default is false, meaning no clearing initially
    }
  },
  emits: ['update-input', 'emit-error', 'clear-input'], // Added clear-input event
  setup(props, { emit }) {
    const inputValue = ref('');
    const error = ref('');

    const formatMobileNumber = (number) => {
      return `+1 (${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;
    };

    // Watch the clear prop to clear the input value when it's set to true
    watch(() => props.clear, (newValue) => {
      if (newValue) {
        inputValue.value = ''; // Clear the input value
      }
    });

    const validateInput = () => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const mobilePattern = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;

      // Reset state
      error.value = '';

      // Check for valid email
      if (emailPattern.test(inputValue.value)) {
        emit('update-input', inputValue.value); // Emit input value to parent
      }
      // Check for exactly 10 digits for mobile
      else if (/^\d{10}$/.test(inputValue.value)) {
        inputValue.value = formatMobileNumber(inputValue.value);
        emit('update-input', inputValue.value); // Emit formatted mobile number
      }
      // Check if formatted mobile matches the expected pattern
      else if (mobilePattern.test(inputValue.value)) {
        emit('update-input', inputValue.value); // Emit formatted mobile number
      }
      // Invalid input
      else {
        error.value = 'Invalid input. Please enter a valid email or a mobile number with exactly 10 digits.';
        emit('emit-error', error.value); // Emit error message to parent
      }
    };

    const limitInput = (event) => {
      const allowedKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'];

      // Allow input for email characters
      const isNumberKey = /^[0-9]$/.test(event.key);

      // Emit clear-input event if input is empty after backspace or delete
      if (event.key === 'Backspace' || event.key === 'Delete') {
        // if (inputValue.value.length === 0) {
        emit('clear-input'); // Emit clear-input event to parent
        // }
      }

      // If the input is mobile number format and has reached 10 digits, prevent further input
      if (inputValue.value.includes('+1') && isNumberKey) {
        if (inputValue.value.replace(/[^\d]/g, '').length >= 10) {
          event.preventDefault(); // Prevent further number input
        }
      }
    };

    return {
      inputValue,
      error,
      validateInput,
      limitInput,
    };
  },
};
</script>
